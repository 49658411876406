import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../../Common/Skeleton";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import useGetBlockNo from "../../Common/ReuseAPI/useGetBlockNo";
import useGetZoneName from "../../Common/ReuseAPI/useGetZoneName";
import useGetOfficerList from "../../Common/ReuseAPI/useGetOfficerList";
import { useSelector } from "react-redux";

const TSOBillBajawaliList = () => {
  const userState = useSelector((state) => state.surveylogin.userData.data);
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  // States for Mobile search filter ----------------
  const [searchMobile, setSearchMobile] = useState("");
  const [searchMobileErr, setSearchMobileErr] = useState(false);

  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");

  const [blockNo, setBlockNo] = useState("");
  const [zoneName, setZoneName] = useState("");

  // filter error
  const [blockErr, setBlockErr] = useState(false);
  const [zoneErr, setZoneErr] = useState(false);

  // Reuse API
  const { blockNoList } = useGetBlockNo();
  const { zoneNameList } = useGetZoneName();

  const [officer, setOfficer] = useState("");
  const { officerList } = useGetOfficerList();
  const [officerErr, setOfficerErr] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState("");
  const [noticeDropDown, setNoticeDropdown] = useState([]);
  const [noticeErr, setNoticeErr] = useState(false);


  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getNoticeNos();
  }, []);

  useEffect(() => {
    getAllBillBajawaliList();
  }, [pageNo, docPerPage]);

  const getAllBillBajawaliList = async () => {
    setLoader(true);

    let payload = {
      noticeSentPrsnId: "",
      surveyZoneKey: userState?.fireZoneKey,
      newBlock: blockNo,
      blockNo: userState?.blockNo,
      userInputNoticeNo: selectedNotice,
      documentsPerPage: docPerPage,
      page: pageNo,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + `/subOfficer/getSentNoticeDashboard`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setNoOfPages(response.noOfPages);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting surveys", err);
        setLoader(false);
      });
  };

  // Search by block and zone
  const searchByBlockZoneValidate = () => {
    let isValid = true;
    if (!blockNo) {
      isValid = false;
      setBlockErr(true);
    }
    return isValid;
  };

  const searchByBlockZone = async () => {
    if (searchByBlockZoneValidate()) {
      await getAllBillBajawaliList();
    }
  };

  // Search by officer
  const searchByOfficerValidate = () => {
    let isValid = true;
    if (!officer) {
      isValid = false;
      setOfficerErr(true);
    }
    return isValid;
  };

  const searchByOfficer = async () => {
    if (searchByOfficerValidate()) {
      await getAllBillBajawaliList();
    }
  };

  const searchByNotice = async () => {
    if (selectedNotice) {
      setNoticeErr(false)
      await getAllBillBajawaliList()
    } else {
      setNoticeErr(true)
    }
  }

  const getNoticeNos = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/newNoticeRoute/noticeNos", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        setNoticeDropdown(response.data);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
      });
  };

  return (
    <div className="outletPadding">
      <ToastContainer />
      <Container>
        <Row className="justify-content-center">
          <Col md={4} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel"> Search by Block</Form.Label>{" "}
              <div className="d-flex align-items-start">
                <Col className="m-2 me-3 d-flex flex-column">
                  <Form.Select
                    value={blockNo}
                    onChange={(e) => {
                      setBlockNo(e.target.value);
                      e.target.value.length > 0 && setBlockErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Block
                    </option>
                    {blockNoList !== null &&
                      blockNoList !== undefined &&
                      blockNoList.length > 0 ? (
                      blockNoList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.blockNo}>
                            {itm?.blockNo}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {blockErr ? (
                    <p className="errMsg">Please select block</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Button
                  className="primaryBtn p-2 mt-2"
                  onClick={searchByBlockZone}
                >
                  <Icon.Search size={18} className="me-2" />
                  Search
                </Button>
              </div>
            </Card>
          </Col>

          <Col md={4} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Notice</Form.Label>
              <div className="d-flex">
                <div >
                  <Form.Select
                    value={selectedNotice}
                    onChange={(e) => {
                      setSelectedNotice(e.target.value);
                      e.target.value.length > 0 && setNoticeErr(false)
                    }}
                  >
                    <option value="" selected disabled>
                      Select Notice Number
                    </option>
                    {noticeDropDown !== null &&
                      noticeDropDown !== undefined &&
                      noticeDropDown.length > 0 ? (
                      noticeDropDown?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.value}>
                            {itm?.label}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {noticeErr ? (
                    <p className="errMsg">Please select Notice Type</p>
                  ) : (
                    ""
                  )}
                </div>
                <Button
                  className="primaryBtn mx-auto "
                  onClick={searchByNotice}
                >
                  <Icon.Search className="me-2" size={15} />
                  Search
                </Button>
              </div>
            </Card>
          </Col>

          {/* <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Officer</Form.Label>
              <Form.Select
                value={officer}
                onChange={(e) => {
                  setOfficer(e.target.value);
                  e.target.value.length > 0 && setOfficerErr(false);
                }}
              >
                <option value="" selected disabled>
                  Select Officer
                </option>
                {officerList !== null &&
                officerList !== undefined &&
                officerList.length > 0 ? (
                  officerList?.map((itm, index) => {
                    return (
                      <option key={index} value={itm?.user_id}>
                        {itm?.name + " - " + itm?.mobile}
                      </option>
                    );
                  })
                ) : (
                  <option value="">No Data Found</option>
                )}
              </Form.Select>
              {officerErr ? (
                <p className="errMsg">Please select officer</p>
              ) : (
                ""
              )}
              <Button
                className="primaryBtn mx-auto mt-2"
                onClick={searchByOfficer}
              >
                <Icon.Search className="me-2" size={15} />
                Search
              </Button>
            </Card>
          </Col> */}
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <div className="table-wrapper mt-2" style={{ maxHeight: "58vh" }}>
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    <th>Zone Name</th>
                    <th>Block</th>
                    <th>Final Business Address</th>
                    <th>Survey By Name</th>
                    <th>Survey By Mobile</th>
                    <th>Generate Person Name</th>
                    <th>Generate Person Mobile</th>
                    <th>Notice Generete Date</th>
                    <th>Action</th>
                  </thead>
                  {loader ? (
                    <Skeleton rows={10} cols={9} />
                  ) : data?.data !== null &&
                    data?.data !== undefined &&
                    data?.data?.length > 0 ? (
                    data?.data?.map((itm, index) => {
                      return (
                        <tr key={Math.random() * 999999999}>
                          <td>{index + 1 + docPerPage * (pageNo - 1)}</td>
                          <td>
                            {itm?.surveyZoneName ? itm?.surveyZoneName : "-"}
                          </td>
                          <td>{itm?.newBlock ? itm?.newBlock : "-"}</td>
                          <td style={{ maxWidth: "20vw" }}>
                            {itm?.finalBusinessAddress
                              ? itm?.finalBusinessAddress
                              : "-"}
                          </td>
                          <td>{itm?.surveyByName ? itm?.surveyByName : "-"}</td>
                          <td>
                            {itm?.surveyByMobile ? itm?.surveyByMobile : "-"}
                          </td>
                          <td>
                            {itm?.noticeGnrtPrsnName
                              ? itm?.noticeGnrtPrsnName
                              : "-"}
                          </td>
                          <td>
                            {itm?.noticeGnrtPrsnMobile
                              ? itm?.noticeGnrtPrsnMobile
                              : "-"}
                          </td>
                          <td>
                            {itm?.noticeGenerateDate
                              ? moment(itm?.noticeGenerateDate).format(
                                "DD-MM-YYYY"
                              )
                              : "-"}
                          </td>
                          <td>
                            <Icon.Eye
                              className="ms-2 icon"
                              size={24}
                              onClick={() =>
                                navigate("/dashboard/tso-view-survey", {
                                  state: itm.surveyId,
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container>
    </div>
  );
};

export default TSOBillBajawaliList;
