import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Spinner , Row} from "react-bootstrap";
import ReactS3 from "../../Utils/ReactS3";
import { useNavigate } from "react-router-dom";
window.Buffer = window.Buffer || require("buffer").Buffer;
export default function Formtwo() {
  const navigation = useNavigate();
  const [label, setlabel] = useState("");
  const [dropdown, setdropdown] = useState("");
  const [loader, setloader] = useState(false);
  const [isSatha, setisSatha] = useState(false);
  const [isVyavsay, setisVyavsay] = useState(false);
  const [isSathaParvana, setisSathaParvana] = useState(false);
  const [sathaParvanaDate, setsathaParvanaDate] = useState();
  const [sathaParvanaImage, setsathaParvanaImage] = useState([]);
  const [sathaParvanaNumber, setsathaParvanaNumber] = useState();
  const [isSathaShopActLicense, setisSathaShopActLicense] = useState(false);
  const [sathaShopActLicenseImage, setsathaShopActLicenseImage] = useState([]);
  const [sathaShopActNumber, setsathaShopActNumber] = useState();
  const [isBusinessParvana, setisBusinessParvana] = useState(false);
  const [businessParvanaDate, setbusinessParvanaDate] = useState();
  const [businessParvanaImage, setbusinessParvanaImage] = useState([]);
  const [businessParvanaNumber, setbusinessParvanaNumber] = useState();
  const [isFoodLicense, setisFoodLicense] = useState(false);
  const [isFoodLicenseDate, setisFoodLicenseDate] = useState();
  const [isFoodLicenseNumber, setisFoodLicenseNumber] = useState();
  const [isBusinessShopActLicense, setisBusinessShopActLicense] =
    useState(false);
  const [isFoodLicenseImage, setisFoodLicenseImage] = useState([]);
  const [businessShopActLicenseImage, setbusinessShopActLicenseImage] =
    useState([]);
  const [surveyById, setsurveyById] = useState();
  const [surveyByName, setsurveyByName] = useState();
  const [surveyByMobile, setsurveyByMobile] = useState();
  const [s3Data1, sets3Data1] = useState("");
  const getsW3credential = async () => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/credentials/getCredentials", {
        projectName: "PCMC_SURVEY",
      })
      .then((res) => {
        sets3Data1(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getIntialValue = async () => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/getSurveyBySurveyId", {
        surveyId: "SURVEY_1110100008_042023032024_9359330702",
      })
      .then((res) => {
        setloader(false);
        setdropdown(res.data.dropdownValues);
        if (res.data.page2) {
          const e = res.data.page2;
          setisSatha(e.isSatha);
          setisVyavsay(e.isVyavsay);
          setisSathaParvana(e.isSathaParvana);
          setsathaParvanaDate(e.sathaParvanaDate);
          setsathaParvanaImage(e.sathaParvanaImage);
          setsathaParvanaNumber(e.sathaParvanaNumber);
          setisSathaShopActLicense(e.isSathaShopActLicense);
          setsathaShopActLicenseImage(e.sathaShopActLicenseImage);
          setsathaShopActNumber(e.sathaShopActNumber);
          setisBusinessParvana(e.isBusinessParvana);
          setbusinessParvanaDate(e.businessParvanaDate);
          setbusinessParvanaImage(e.businessParvanaImage);
          setbusinessParvanaNumber(e.businessParvanaNumber);
          setisFoodLicense(e.isFoodLicense);
          setisFoodLicenseDate(e.isFoodLicenseDate);
          setisFoodLicenseNumber(e.isFoodLicenseNumber);
          setisBusinessShopActLicense(e.isBusinessShopActLicense);
          setisFoodLicenseImage(e.isFoodLicenseImage);
          setbusinessShopActLicenseImage(e.businessShopActLicenseImage);
          setsurveyById(e.surveyId);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getLebal = async () => {
    setloader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/labels/getLabels")
      .then((res) => {
        if (res.data.data) {
          setlabel(res.data.data);
          getIntialValue();
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getdropdownvalue = async () => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/dropdownValues/getAllDropdownValues",
        {
          propertyCode: "1020100097.00",
          user_id: "",
        }
      )
      .then((res) => {
        if (res.data.dropdownValues) {
          setloader(false);
          setdropdown(res.data.dropdownValues);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getsW3credential();
    getLebal();
  }, []);

  const s3Data = {
    projectName: "itgoa",
    bucketName: "it-goa",
    region: "ap-south-1",
    accessKeyId: "AKIA4GWLDQ2LL4DLFWEF",
    secretAccessKey: "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",
    s3Url: "https://it-goa.s3.ap-south-1.amazonaws.com",
  };

  const handleProfileImg = async (e) => {
    let result = await ReactS3(e.target.files[0], s3Data, "citizen");

    return result;
  };
  const UpdatePage2 = async (e) => {
    e.preventDefault();
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/updateSurveyPage2", {
        isSatha: isSatha,
        isVyavsay: isVyavsay,
        isSathaParvana: isSathaParvana,
        sathaParvanaDate: sathaParvanaDate,
        sathaParvanaImage: sathaParvanaImage,
        sathaParvanaNumber: sathaParvanaNumber,
        isSathaShopActLicense: isSathaShopActLicense,
        sathaShopActLicenseImage: sathaShopActLicenseImage,
        sathaShopActNumber: sathaShopActNumber,
        isBusinessParvana: isBusinessParvana,
        businessParvanaDate: businessParvanaDate,
        businessParvanaImage: businessParvanaImage,
        businessParvanaNumber: businessParvanaNumber,
        isBusinessShopActLicense: isBusinessShopActLicense,
        businessShopActLicenseImage: businessShopActLicenseImage,
        //businessShopActNumber: businessShopActNumber,
        isFoodLicense: isFoodLicense,
        isFoodLicenseDate: isFoodLicenseDate,
        isFoodLicenseNumber: isFoodLicenseNumber,
        isFoodLicenseImage: isFoodLicenseImage,
        surveyById: surveyById,
        surveyByName: surveyByName,
        surveyByMobile: surveyByMobile,
      })
      .then((res) => {
        console.log(res.data);
        navigation("/CitizenLayout/Formthree");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (

    <>
    <Row>
     <h1 className="text-center">Form 2</h1>
    </Row>
    <div style={{ padding: 20, display: "flex", justifyContent: "center" }}>
      {loader ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Form className="form-layout">
          <Form.Label className="Label-muted">
            {label?.page1?.businessCategory}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisSatha(e.target.id == "true" ? true : false);
              setisVyavsay(e.target.id == "false" ? true : false);
            }}
          >
            <Form.Check
              checked={isVyavsay}
              inline
              label={"साठा"}
              name="group1"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={isVyavsay}
              inline
              label={"व्यवसाय"}
              name="group1"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <Form.Group className="mb-4 " controlId="formBasicEmail">
            <Form.Label>{label?.page1?.sathaParvana}</Form.Label>
          </Form.Group>

          <Form.Label className="Label-muted">
            {label?.page1?.isSathaParvana}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisSathaParvana(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isSathaParvana}
              inline
              label={"होय"}
              name="group2"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isSathaParvana}
              inline
              label={"नाही"}
              name="group2"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <div
            className="mb-4"
            style={{ display: isSathaParvana ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setsathaParvanaImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {sathaParvanaImage && (
              <img
                src={sathaParvanaImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>

          <Form.Group
            style={{ display: isSathaParvana ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setsathaParvanaDate(e.target.value);
            }}
          >
            <Form.Label>{label?.page1?.sathaParvanaDate}</Form.Label>
            <Form.Control
              type="date"
              placeholder={label?.page1?.sathaParvanaDate}
            />
          </Form.Group>
          <Form.Group
            style={{ display: isSathaParvana ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setsathaParvanaNumber(e.target.value);
            }}
          >
            <Form.Label>{label?.page1?.sathaParvanaNumber}</Form.Label>
            <Form.Control
              type="email"
              placeholder={label?.page1?.sathaParvanaNumber}
            />
          </Form.Group>

          <Form.Label className="Label-muted">
            {label?.page1?.isSathaShopAct}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisSathaShopActLicense(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isSathaShopActLicense}
              inline
              label={"होय"}
              name="group3"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isSathaShopActLicense}
              inline
              label={"नाही"}
              name="group3"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <div
            className="mb-4"
            style={{ display: isSathaShopActLicense ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setsathaShopActLicenseImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {sathaShopActLicenseImage && (
              <img
                src={sathaShopActLicenseImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <Form.Group
            style={{ display: isSathaShopActLicense ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setsathaShopActNumber(e.target.value);
            }}
          >
            <Form.Label>{label?.page1?.sathaShopActNumber}</Form.Label>
            <Form.Control
              type="email"
              placeholder={label?.page1?.sathaShopActNumber}
            />
          </Form.Group>

          <Form.Group className="mb-4 " controlId="formBasicEmail">
            <Form.Label>{label?.page1?.businessParvana}</Form.Label>
          </Form.Group>

          <Form.Label className="Label-muted">
            {label?.page1?.isBusinessParvana}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisBusinessParvana(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isBusinessParvana}
              inline
              label={"होय"}
              name="group4"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isBusinessParvana}
              inline
              label={"नाही"}
              name="group4"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>
          <div
            className="mb-4"
            style={{ display: isBusinessParvana ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setbusinessParvanaImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {businessParvanaImage && (
              <img
                src={businessParvanaImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <Form.Group
            style={{ display: isBusinessParvana ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setbusinessParvanaDate(e.target.value);
            }}
          >
            <Form.Label>{label?.page1?.businessParvanaDate}</Form.Label>
            <Form.Control
              type="date"
              placeholder={label?.page1?.businessParvanaDate}
            />
          </Form.Group>
          <Form.Group
            style={{ display: isBusinessParvana ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setbusinessParvanaNumber(e.target.value);
            }}
          >
            <Form.Label>{label?.page1?.businessParvanaNumber}</Form.Label>
            <Form.Control
              type="email"
              placeholder={label?.page1?.businessParvanaNumber}
            />
          </Form.Group>

          <Form.Label className="Label-muted">
            {label?.page1?.isBusinessShopAct}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisBusinessShopActLicense(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isBusinessShopActLicense}
              inline
              label={"होय"}
              name="group5"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isBusinessShopActLicense}
              inline
              label={"नाही"}
              name="group5"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>
          <div
            className="mb-4"
            style={{ display: isBusinessShopActLicense ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setbusinessShopActLicenseImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {businessShopActLicenseImage && (
              <img
                src={businessShopActLicenseImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <Form.Group
            style={{ display: isBusinessShopActLicense ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setsathaShopActNumber(e.target.value);
            }}
          >
            <Form.Label>{label?.page1?.sathaShopActNumber}</Form.Label>
            <Form.Control
              type="email"
              placeholder={label?.page1?.sathaShopActNumber}
            />
          </Form.Group>

          <Form.Label className="Label-muted">
            {label?.page1?.isFoodLicense}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisFoodLicense(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isFoodLicense}
              inline
              label={"होय"}
              name="group6"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isFoodLicense}
              inline
              label={"नाही"}
              name="group6"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>
          <div
            className="mb-4"
            style={{ display: isFoodLicense ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setisFoodLicenseImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {isFoodLicenseImage && (
              <img
                src={isFoodLicenseImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <Form.Group
            style={{ display: isFoodLicense ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setisFoodLicenseDate(e.target.value);
            }}
          >
            <Form.Label>{label?.page1?.isFoodLicenseDate}</Form.Label>
            <Form.Control
              type="date"
              placeholder={label?.page1?.isFoodLicenseDate}
            />
          </Form.Group>
          <Form.Group
            style={{ display: isFoodLicense ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setisFoodLicenseNumber(e.target.value);
            }}
          >
            <Form.Label>{label?.page1?.isFoodLicenseNumber}</Form.Label>
            <Form.Control
              type="email"
              placeholder={label?.page1?.isFoodLicenseNumber}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button
              className="primaryBtn"
              onClick={(e) => {
                UpdatePage2(e);
              }}
            >
              Next
            </Button>
          </div>
        </Form>
      )}
    </div>
    </>
  );
}
