import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
  Tab,
  Nav,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import useGetBlockNo from "../Common/ReuseAPI/useGetBlockNo";
import useGetZoneName from "../Common/ReuseAPI/useGetZoneName";
import useGetOfficerList from "../Common/ReuseAPI/useGetOfficerList";
import moment from "moment";
import { setNewActiveKey } from "../Common/ActiveKeySlice";

const HospitalGenerateNotice = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [checkboxArray, setCheckBoxArray] = useState([]);
  // const [type,setType] = useState("all")

  const [showDownload, setShowDownload] = useState(false);
  const [mergeLoader, setMergeLoader] = useState(false);

  const [dateNoticeModal, setDateNoticeModal] = useState(false);
  const [mergeArray, setMergeAraay] = useState([]);
  const [mergeError, setMergeError] = useState({});
  const [noticeDate, setNoticeDate] = useState("");
  const [selectedNoticeFormat, setSelectedNoticeFormat] = useState("");
  const [noticeDropDown, setNoticeDropdown] = useState([]);
  const [noticeFormatDropDown, setNoticeFormatDropDown] = useState([]);

  const [singleNoticeMoodal, setSingleNoticeMoodal] = useState(false);
  const [singleData, setSingleData] = useState("");
  const [singleError, setsingleError] = useState({});
  const [singleNoticeDate, setSingleNoticeDate] = useState("");
  // const [singleSelectedNotice, setSingleSelectedNotice] = useState("");
  const [singleLoader, setSingleLoader] = useState(false);
  const [responses, setResponses] = useState([]);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  // filter states
  const [hospitalName, setHospitalName] = useState("");
  const [managerMobile, setMangerMobile] = useState("");

  const userState = useSelector((state) => state.surveylogin.userData.data);
  const navigate = useNavigate();

  useEffect(() => {
    getNoticeFormat();
  }, []);

  useEffect(() => {
    getHospitalList();
  }, [docPerPage, pageNo]);

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  const getHospitalList = async () => {
    setLoader(true);
    let payload = {
      hospitalName: hospitalName,
      managerMobile: managerMobile,
      documentsPerPage: docPerPage,
      page: pageNo,
    };

    await fetch(process.env.REACT_APP_BASE_URL + "/hospital/getAllHospitals", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoader(false);
        setData(response);
        setNoOfPages(response.noOfPages);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  const checkBoxChnage = (event, item) => {
    if (event.target.checked) {
      let arr = [...checkboxArray];
      arr.push(item);
      setCheckBoxArray(arr);
    } else {
      let arr = [...checkboxArray];
      let getvalue = arr.findIndex(
        (row) => row?.hospitalId === item?.hospitalId
      );
      arr.splice(getvalue, 1);
      setCheckBoxArray(arr);
    }
  };

  const changeSelectAll = (event) => {
    if (event.target.checked) {
      let arr = [];
      data?.data.map((row) => {
        arr.push(row);
      });
      setCheckBoxArray(arr);
    } else {
      setCheckBoxArray([]);
    }
  };

  const closeDateModal = () => {
    setDateNoticeModal(false);
    setSelectedNoticeFormat("");
    setNoticeDate("");
    setCheckBoxArray([]);
    setMergeAraay([]);
  };

  const isValidNotice = () => {
    let errors = {};

    if (!noticeDate) {
      errors.noticeDate = "*Please Select Notice Date.";
    }

    if (!selectedNoticeFormat) {
      errors.selectedNoticeFormat = "*Please Select Notice Type.";
    }

    setMergeError(errors);
    return Object.keys(errors).length === 0;
  };

  const bulkInsert = async () => {
    if (isValidNotice()) {
      setMergeLoader(true);
      let payload = {
        noticeGnrtPrsnId: userState.user_id,
        noticeGnrtPrsnName: userState.name,
        noticeGnrtPrsnMobile: userState.mobile,
        noticeGnrtPrsnRole: userState.userType_keyname,
        noticeDate: noticeDate,
        noticeFormatName: selectedNoticeFormat,
        hospitalData: checkboxArray.map((row) => {
          return {
            hospitalId: row.hospitalId,
            hospitalSrNo: row.hospitalSrNo,
            hospitalName: row.hospitalName,
            hospitalAddress: row.hospitalAddress,
            managerName: row.managerName,
            managerMobile: row.managerMobile,
            zone: row.zone,
            isCompliance: row.isCompliance,
          };
        }),
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "/hospital/hospitalBulkInsertNotice",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setMergeLoader(false);
          setMergeAraay(response.data);
          closeDateModal();
          toast.success("Notice Generated Successfully");
          let timeout = setTimeout(() => {
            navigate("/dashboard/hospital-download-notice");
            dispatch(
              setNewActiveKey({
                activeKey: 22,
                subActiveKey: 22.2,
                activeTab: "Hospital Download Merge Notice",
              })
            );
            clearTimeout(timeout);
          }, 3000);
        })
        .catch((Err) => {
          console.log("Err while getting propertType", Err);
          setMergeLoader(false);
        });
    }
  };

  const isSingleNoticeValid = () => {
    let errors = {};

    if (!singleNoticeDate) {
      errors.noticeDate = "*Please Select Notice Date.";
    }
    if (!selectedNoticeFormat) {
      errors.selectedNoticeFormat = "*Please Select Notice Type.";
    }

    setsingleError(errors);
    return Object.keys(errors).length === 0;
  };

  const closeSingleModal = () => {
    setSingleNoticeMoodal(false);
    setSingleData("");
    setSelectedNoticeFormat("");
    setSingleNoticeDate("");
    setSingleLoader(false);
  };

  const getSingleNotice = async () => {
    if (isSingleNoticeValid()) {
      setSingleLoader(true);
      let payload = {
        ...singleData,
        noticeGnrtPrsnId: userState.user_id,
        noticeGnrtPrsnName: userState.name,
        noticeGnrtPrsnMobile: userState.mobile,
        noticeGnrtPrsnRole: userState.userType_keyname,
        noticeDate: singleNoticeDate,
        noticeFormatName: selectedNoticeFormat,
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "/hospital/hospitalGenerateNotice",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            fetch(response.data)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement("a");
                a.href = url;
                a.download = singleData.hospitalId + ".pdf";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                closeSingleModal();
                setSingleLoader(false);
              })
              .catch((error) => {
                console.error("Error downloading PDF:", error);
                setSingleLoader(false);
              });
          } else {
            toast.error(response.error);
          }
        })
        .catch((Err) => {
          console.log("Err while getting propertType", Err);
          setSingleLoader(false);
        });
    }
  };

  const getNoticeFormat = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/noticeFormat/getNoticeFormat`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userInputNoticeNo: 6 }),
        }
      );

      const data = await response.json();
      if (response.ok && data.status === 200) {
        setNoticeFormatDropDown(data.data);
      } else {
        console.error("Error fetching notice format:", data);
        return [];
      }
    } catch (error) {
      console.error("Error while getting notice format:", error);
      return [];
    }
  };

  const searchByMobile = () => {
    if(managerMobile && managerMobile.length === 10){
      getHospitalList()
    }else{
      toast.error("Please Enter Valid Manager Mobile Number")
    }
  }

  const searchByHospitalName = () => {
    if(hospitalName){
      getHospitalList()
    }else{
      toast.error("Please Enter Hospital Name first")
    }
  }

  return (
    <div className="outletPadding allocate-target">
      <ToastContainer />
      <Container>
        <Row className="justify-content-end mt-5">
          <Col md={3} className="d-flex justify-content-end">
            <Button
              disabled={checkboxArray.length === 0}
              className="primaryBtn"
              onClick={() => setDateNoticeModal(true)}
            >
              <Icon.Intersect className="me-2" />
              Generate Merge Notice
            </Button>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search by Hospital Name
              </Form.Label>{" "}
              <div className="d-flex">
                <Form.Control
                  placeholder="Enter Hospital Name"
                  value={hospitalName}
                  className="me-3"
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^[A-Za-z ]+$/)
                    ) {
                      setHospitalName(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setHospitalName(e.target.value);
                    }
                  }}
                />
                <Button className="primaryBtn" onClick={searchByHospitalName}>
                  <Icon.Search className="me-2" size={15} />
                  Search
                </Button>
              </div>
            </Card>
          </Col>

          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search by Manager Mobile
              </Form.Label>{" "}
              <div className="d-flex">
                <Form.Control
                  placeholder="Enter Mobile No."
                  value={managerMobile}
                  className="me-3"
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^[0-9]+$/) &&
                      e.target.value.length <= 10
                    ) {
                      setMangerMobile(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setMangerMobile(e.target.value);
                    }
                  }}
                />
                <Button className="primaryBtn" onClick={searchByMobile}>
                  <Icon.Search className="me-2" size={15} />
                  Search
                </Button>
              </div>
            </Card>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <Form.Check
                label={"Select All"}
                onChange={(e) => changeSelectAll(e)}
              />
              <div className="table-wrapper mt-2" style={{ maxHeight: "58vh" }}>
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    <th>Hospital Name</th>
                    <th>Zone</th>
                    <th>Managing Person Name</th>
                    <th>Managing Person Mobile</th>
                    <th>Action</th>
                  </thead>
                  {loader ? (
                    <Skeleton rows={10} cols={9} />
                  ) : data?.data !== null &&
                    data?.data !== undefined &&
                    data?.data?.length > 0 ? (
                    data?.data?.map((itm, index) => {
                      return (
                        <tr
                          key={index}
                          // style={
                          //  itm.noticeSend && itm.noticeSend.length > 0
                          //     ? { backgroundColor: "#ccffcc" }
                          //     : {}
                          // }
                        >
                          <td>
                            {pageNo !== 1 ? (
                              <div className="d-flex">
                                {index + 1 + docPerPage * (pageNo - 1)}
                                <Form.Check
                                  style={{ marginLeft: 5 }}
                                  name="group1"
                                  type={"checkbox"}
                                  checked={
                                    checkboxArray.filter(
                                      (row) => row.hospitalId === itm.hospitalId
                                    ).length > 0
                                  }
                                  onChange={(e) => checkBoxChnage(e, itm)}
                                />
                              </div>
                            ) : (
                              <div className="d-flex">
                                {index + 1}
                                <Form.Check
                                  style={{ marginLeft: 5 }}
                                  name="group1"
                                  type={"checkbox"}
                                  checked={
                                    checkboxArray.filter(
                                      (row) => row.hospitalId === itm.hospitalId
                                    ).length > 0
                                  }
                                  onChange={(e) => checkBoxChnage(e, itm)}
                                />
                              </div>
                            )}
                          </td>
                          <td>{itm?.hospitalName ? itm?.hospitalName : "-"}</td>
                          <td>{itm?.zone ? itm?.zone : "-"}</td>
                          <td>{itm?.managerName ? itm?.managerName : "-"}</td>
                          <td>
                            {itm?.managerMobile ? itm?.managerMobile : "-"}
                          </td>
                          <td className="d-flex align-items-center">
                            <Button
                              className="primaryBtn ms-2"
                              disabled={
                                checkboxArray.filter(
                                  (row) => row.hospitalId === itm.hospitalId
                                ).length > 0
                              }
                              onClick={() => {
                                setSingleData(itm);
                                setSingleNoticeMoodal(true);
                              }}
                            >
                              Generate Single Notice
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container>

      <Modal
        size="lg"
        show={dateNoticeModal}
        backdrop="static"
        keyboard={false}
        centered
        onHide={closeDateModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">
              {" "}
              <Icon.Intersect className="me-2" />
              Generate Merge Notice
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex flex-wrap">
            <Col md={4} className="d-flex flex-column">
              <Form.Label>Notice Date</Form.Label>
              <Form.Control
                placeholder="Start Date"
                type="date"
                disabled={mergeArray.length > 0}
                // min={moment(new Date()).format("YYYY-MM-DD")}
                value={noticeDate}
                onChange={(e) =>
                  setNoticeDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />
              {mergeError.noticeDate && (
                <p className="errMsg">{mergeError.noticeDate}</p>
              )}
            </Col>

            <Col md={4} className="d-flex flex-column mb-3">
              <Form.Label>Notice Format</Form.Label>
              <Form.Select
                value={selectedNoticeFormat}
                onChange={(e) => {
                  setSelectedNoticeFormat(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Select Notice Type
                </option>
                {noticeFormatDropDown.map((itm, index) => (
                  <option key={index} value={itm.noticeFormatName}>
                    {itm.noticeFormatName}
                  </option>
                ))}
              </Form.Select>
              {mergeError.selectedNoticeFormat && (
                <p className="errMsg">{mergeError.selectedNoticeFormat}</p>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {mergeLoader ? (
            <Spinner />
          ) : (
            // mergeArray.length > 0 ? (
            //   <Button className="primaryBtn" onClick={() => downloadMergePdf()}>
            //     Download Merge Pdf
            //   </Button>
            // ) :
            <Button className="primaryBtn" onClick={() => bulkInsert()}>
              <Icon.Intersect className="me-2" />
              Generate
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={singleNoticeMoodal}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => closeSingleModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Generate Notice</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 style={{ wordWrap: "break-word" }}>
            Hospital Name - {singleData?.hospitalName}
          </h6>
          <Row className="d-flex flex-wrap">
            <Col md={4} className="d-flex flex-column">
              <Form.Label>Notice Date</Form.Label>
              <Form.Control
                placeholder="Start Date"
                type="date"
                // min={moment(new Date()).format("YYYY-MM-DD")}
                value={singleNoticeDate}
                onChange={(e) =>
                  setSingleNoticeDate(
                    moment(e.target.value).format("YYYY-MM-DD")
                  )
                }
              />
              {singleError.noticeDate && (
                <p className="errMsg">{singleError.noticeDate}</p>
              )}
            </Col>

            <Col md={4} className="d-flex flex-column mb-3">
              <Form.Label>Notice Format</Form.Label>
              <Form.Select
                value={selectedNoticeFormat}
                onChange={(e) => {
                  setSelectedNoticeFormat(e.target.value);
                }}
              >
                <option value="" selected disabled>
                  Select Notice Type
                </option>
                {noticeFormatDropDown.map((itm, index) => (
                  <option key={index} value={itm.noticeFormatName}>
                    {itm.noticeFormatName}
                  </option>
                ))}
              </Form.Select>
              {singleError.selectedNoticeFormat && (
                <p className="errMsg">{singleError.selectedNoticeFormat}</p>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {singleLoader ? (
            <Spinner />
          ) : (
            <Button className="primaryBtn" onClick={() => getSingleNotice()}>
              Generate Single Notice
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HospitalGenerateNotice;
