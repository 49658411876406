import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Modal,
  InputGroup,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useSelector } from "react-redux";
import Pagination from "../Common/Pagination";
import Skeleton from "../Common/Skeleton";
import useGetBlockNo from "../Common/ReuseAPI/useGetBlockNo";
import useGetZoneName from "../Common/ReuseAPI/useGetZoneName";

const OffiecrManageUsers = () => {
  const userState = useSelector((state) => state.surveylogin.userData?.data);

  const [data, setData] = useState("");

  const [searchMobileErr, setSearchMobileErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paginate, setPaginate] = useState(true);

  const [showAdd, setShowAdd] = useState(false);

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);

  const [searchMobile, setSearchMobile] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState(false);

  const [pass, setPass] = useState("");
  const [passErr, setPassErr] = useState(false);

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);

  const [zone, setZone] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [fireZoneErr, setfireZoneErr] = useState(false);

  const [role, setRole] = useState("");
  const [roleOptions, setRoleOptions] = useState([]);
  const [roleErr, setRoleErr] = useState(false);

  const [gat, setGat] = useState(null);
  const [gatList, setGatList] = useState([]);
  const [gatErr, setGatErr] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const [showDel, setShowDel] = useState(false);
  const [userId, setUserId] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  const [blockNo, setBlockNo] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [userBlocks, setUserBlocks] = useState([]);

  // filter error
  const [blockErr, setBlockErr] = useState(false);
  const [zoneErr, setZoneErr] = useState(false);

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getAllUsers();
  }, [pageNo, docPerPage]);

  useEffect(() => {
    getZone();
    getRoles();
  }, []);

  // useEffect(() => {
  //   if (zone?.value) {
  //     getGat();
  //   }
  // }, [zone?.value]);

  //----- Setting up Closing modal & Clearing Values ----//
  const closeModal = () => {
    setUserId("");
    setName("");
    setMobile("");
    setPass("");
    setEmail("");
    setRole("");
    setZone("");
    setGat("");
    setZoneName('')
    setBlockNo('')
    setUserBlocks([])

    setNameErr(false);
    setMobileErr(false);
    setPassErr(false);
    setEmailErr(false);
    setfireZoneErr(false);
    setGatErr(false);
    setZoneErr(false)
    setBlockErr(false)
    setShowAdd(false);
    setShowEdit(false);
    setShowDel(false);
  };

  const getAllUsers = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/newSurvey/getAllUsersDashboard",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          mobile: "",
          userType: "",
          documentsPerPage: docPerPage,
          pageNo: pageNo,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setData(response?.data);
        setNoOfPages(response?.data?.noOfPages);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting users", err);
        setLoader(false);
      });
  };

  const getSingleUsers = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/newSurvey/getAllUsersDashboard",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobile: searchMobile }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setData(response?.data);
        setPaginate(false);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting users", err);
        setLoader(false);
      });
  };

  /* ----------- Role API ------------------- */
  const getRoles = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/usertype/getAlluserType", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userType: "" }),
    })
      .then((res) => res.json())
      .then((response) => {
        const roleOptions = response?.data?.map((itm) => ({
          value: itm.userType_keyname,
          label: itm.userType,
        }));
        setRoleOptions(roleOptions);
      })
      .catch((err) => {
        console.log("err while getting roles", err);
      });
  };

  const getZone = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/zone/zoneList", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
      })
      .catch((err) => {
        console.log("Err while getting users", err);
      });
  };

  // const zoneOption = zoneList !== undefined && zoneList !== null && zoneList.length > 0 ?
  //                    zoneList.map((itm) => ({
  //                     label: `${itm.zonename}-${itm.zoneno}`,
  //                     value: itm.zonekey,
  // }))
  // : [];

  // Reuse API
  const { blockNoList } = useGetBlockNo();
  const { zoneNameList } = useGetZoneName();

  const zoneOption =
    zoneNameList !== undefined &&
    zoneNameList !== null &&
    zoneNameList.length > 0
      ? zoneNameList.map((itm) => ({
          label: `${itm.zonename}`,
          value: itm.zonekey,
        }))
      : [];

  const gatOption =
    gatList !== undefined && gatList !== null && gatList.length > 0
      ? gatList.map((itm) => ({
          label: itm.gatname,
          value: itm.gatkey,
        }))
      : [];

  // const getGat = async () => {
  //   await fetch(process.env.REACT_APP_BASE_URL + "/gat/gatList", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ zonekey: zone?.value }),
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       setGatList(response.data);
  //     })
  //     .catch((err) => {
  //       console.log("Err while getting users", err);
  //     });
  // };

  // --------------- Search User By Mobile ------------------------------
  const searchByValidate = () => {
    let isValid = true;
    if (!searchMobile || searchMobile.length < 10) {
      isValid = false;
      setSearchMobileErr(true);
    }
    return isValid;
  };

  const searchByMobile = async () => {
    if (searchByValidate()) {
      getSingleUsers();
    } else {
      setLoader(false);
    }
  };

  // Block Zone validate
  const blockZoneValidate = () => {
    let isValid = true;
    if (!zoneName) {
      isValid = false;
      setZoneErr(true);
    } else if (!blockNo) {
      isValid = false;
      setBlockErr(true);
    }
    return isValid;
  };

  const addBlockZone = async () => {
    if (blockZoneValidate()) {
      const newItem = {
        label: `${zoneName}${blockNo}`,
        value: `${zoneName}${blockNo}`,
      };

      // Check if the item already exists in the array
      const exists = userBlocks.some((block) => block.value === newItem.value);

      if (exists) {
        toast.error("This block already exists!");
      } else {
        let arr = [...userBlocks];
        arr.push(newItem);
        setUserBlocks(arr);
        setZoneName("");
        setBlockNo("");
        setZoneErr(false)
        setBlockErr(false)
      }
    }
  };

  const removeBlock = (index) => {
    let arr = [...userBlocks];
    arr.splice(index, 1);
    setUserBlocks(arr);
  };

  /*---------------------------- ADD User Functions --------------------------------*/

  const addValidate = () => {
    let isValid = true;
    if (!name) {
      isValid = false;
      setNameErr(true);
    } else if (!mobile || mobile.length < 10) {
      isValid = false;
      setMobileErr(true);
    }
    // else if(!email || !email.includes("@.")){
    //   isValid = false;
    //   setEmailErr(true);
    // }
    else if (!pass) {
      isValid = false;
      setPassErr(true);
    } else if (!role) {
      isValid = false;
      setRoleErr(true);
    } else if (!zone) {
      isValid = false;
      setfireZoneErr(true);
    } else if (userBlocks.length === 0) {
      isValid = false;
      setGatErr(true);
    }
    return isValid;
  };

  const addUser = async () => {
    if (addValidate()) {
      let payload = {
        name: name,
        mobile: mobile,
        email: email,
        userType: role.value,
        password: pass,
        fireZoneKeys: zone.map((row) => row.value),
        blockNo: userBlocks.map((row) => row.value),
      };

      console.log(payload);

      setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + "/users/newAddUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "User Added Successfully") {
            toast.success("User Added Successfully");
            setLoader(false);
            closeModal();
            getAllUsers();
          } else if (response.message === "User Already Exists") {
            toast.error("User Already Exists.");
            setLoader(false);
            closeModal();
          }
        })
        .catch((err) => {
          console.log("Err while adding user", err);
          setLoader(false);
        });
    } else {
      setLoader(false);
    }
  };

  /* --------------------------- UPDATE User Functions -----------------------------*/
  const setEditData = (itm) => {
    console.log(itm);
    setUserId(itm?.user_id);
    setName(itm?.name);
    setEmail(itm?.email);
    setRole({ value: itm?.userType_name, label: itm?.userType_keyname });
    if (itm.fireZoneKey === null) {
      setZone("");
    } else if(itm.fireZoneKey.length > 0){
        let zoneArr = []
        itm.fireZoneKey.map( (row,index) => {
          zoneArr.push({
            label:itm.fireZone[index],
            value:itm.fireZoneKey[index]
          })
          setZone(zoneArr)
        } )

    }else{
      setZone([])
    }
    if (itm.blockNo.length > 0) {
      let blockArr = []
      itm.blockNo.map( (row) => {
        blockArr.push({
          label:row,
          value:row
        })
      } )
      setUserBlocks(blockArr)
    }else{
    setBlockNo([])

    }
  };

  const updateValidate = () => {
    let isValid = true;
    if (!name) {
      isValid = false;
      setNameErr(true);
    }
    // else if(!email){
    //   isValid = false;
    //   setEmailErr(true);
    // }
    else if (zone.length === 0) {
      isValid = false;
      setfireZoneErr(true);
    } else if (userBlocks.length === 0) {
      isValid = false;
      setGatErr(true);
    }
    return isValid;
  };

  const updateUser = async () => {
    if (updateValidate()) {
      let payload = {
        user_id: userId,
        name: name,
        email: email,
        fireZoneKeys: zone.map((row) => row.value),
        blockNo: userBlocks.map((row) => row.value),
      };
      console.log(payload);
      setLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + "/users/newUpdateUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "User updated successfully") {
            setShowEdit(false);
            setMobile("");
            closeModal()
            toast.success("User Updated Successfully");
            getAllUsers();
          }
        })
        .catch((err) => {
          console.log("Err while updating user", err);
          setLoader(false);
        });
    }
  };

  /* ------------------------- DELETE User Functions -----------------------------*/
  const deleteUser = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/users/deleteUser", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user_id: userId }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "User deleted successfully") {
          closeModal();
          toast.success("User Deleted Successfully");
          getAllUsers();
        }
      })
      .catch((err) => {
        console.log("Err while deleting user", err);
        setLoader(false);
      });
  };

  return (
    <div className="OffiecrManageUsers outletPadding">
      <ToastContainer />
      <Container>
        <Row className="justify-content-between">
          <Col md={3}>
            <div className="d-flex">
              <Form.Control
                placeholder="Enter Mobile No."
                value={searchMobile}
                className="me-3"
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[0-9]+$/) &&
                    e.target.value.length <= 10
                  ) {
                    setSearchMobile(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setSearchMobile(e.target.value);
                  }
                  e.target.value.length > 0
                    ? setSearchMobileErr(false)
                    : setSearchMobileErr(true);
                }}
              />
              <Button className="primaryBtn" onClick={searchByMobile}>
                <Icon.Search className="me-2" size={15} />
                Search
              </Button>
            </div>
            {searchMobileErr ? (
              <p className="errMsg">Please enter valid mobile no.</p>
            ) : (
              ""
            )}
          </Col>

          <Col
            md={2}
            className="d-flex justify-content-end my-auto mt-3 mt-md-0"
          >
            <Button className="primaryBtn" onClick={() => setShowAdd(true)}>
              <Icon.PlusCircle className="me-2" size={15} />
              Add User
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Email ID</th>
                <th>Role</th>
                <th>Zone</th>
                <th>Blocks</th>
                <th>Actions</th>
              </thead>
              {loader ? (
                <Skeleton rows={8} cols={8} />
              ) : data?.users !== null &&
                data.users !== undefined &&
                data?.users.length > 0 ? (
                data?.users.map((itm, index) => {
                  const {
                    user_id,
                    name,
                    mobile,
                    userType_name,
                    zoneno,
                    gatname,
                    email,
                    blockNo,
                    fireZone
                  } = itm;
                  return (
                    <tr key={Math.random() * 999999999}>
                      <td>
                        {pageNo !== 1 ? (
                          <> {index + 1 + docPerPage * (pageNo - 1)}</>
                        ) : (
                          <>{index + 1}</>
                        )}
                      </td>
                      <td style={{ width: "18vw" }}>{name}</td>
                      <td>{mobile}</td>
                      <td>{email ? email : "-"}</td>
                      <td>{userType_name}</td>
                      <td>{ fireZone !== null ? fireZone.toString() : ''}</td>
                      <td>{blockNo.toString()}</td>
                      <td>
                        <Icon.PencilSquare
                          size={22}
                          className="ms-2 me-4 icon"
                          onClick={() => {
                            setShowEdit(true);
                            setEditData(itm);
                          }}
                        />
                        <Icon.Trash
                          size={22}
                          className="icon"
                          onClick={() => {
                            setShowDel(true);
                            setUserId(user_id);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <p className="noDataFound">No Data Found</p>
              )}
            </table>
          </div>

          {paginate && (
            <Row>
              <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                <h6 className="text-start mb-0">
                  <Icon.People className="me-2" />
                  Total - <strong>{data?.count}</strong>
                </h6>
              </Col>
              <Col md={8} className="d-flex justify-content-end">
                <Pagination
                  currentPage={pageNo}
                  totalPages={noOfPages}
                  onPageChange={handlePageChange}
                  onDocsPerPage={handleDocsPerPage}
                  docsPerPage={docPerPage}
                />
              </Col>
            </Row>
          )}
        </div>
      </Container>

      {/* ---------------- ADD User Modal ----------------------------------------------------------- */}
      <Modal
        size="md"
        show={showAdd}
        backdrop="static"
        keyboard={false}
        centered
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h4 className="mb-0">
              <Icon.PlusCircle size={20} className="me-2" />
              Add User
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                      ) &&
                      e.target.value.trim()
                    ) {
                      setName(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setName(e.target.value);
                    }
                    e.target.value.length > 0
                      ? setNameErr(false)
                      : setNameErr(true);
                  }}
                />
                {nameErr ? (
                  <p className="errMsg">Please enter user name</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  placeholder="Enter mobile number"
                  value={mobile}
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^[0-9]+$/) &&
                      e.target.value.length <= 10
                    ) {
                      setMobile(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setMobile(e.target.value);
                    }
                    e.target.value.length > 0
                      ? setMobileErr(false)
                      : setMobileErr(true);
                  }}
                />
                {mobileErr ? (
                  <p className="errMsg">Please enter valid mobile no.</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}

                  //   if (!e.target.value.match(/[+#$&%!~=^_:()/\/{}\[\]|/*/\//\-/?<>,;`'""/]/) &&
                  //     e.target.value.trim()
                  // ) {
                  //   setEmail(e.target.value);
                  // } else if (e.target.value.length === 0) {
                  //   setEmail(e.target.value);
                  // }
                  // e.target.value.length>0 ? setEmailErr(false): setEmailErr(true);
                />
                {/* {emailErr ? <p className='errMsg'>Please enter valid email id</p> : ''} */}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    value={pass}
                    onChange={(e) => {
                      setPass(e.target.value);
                      e.target.value.length > 0
                        ? setPassErr(false)
                        : setPassErr(true);
                    }}
                  />

                  <InputGroup.Text>
                    {showPassword ? (
                      <Icon.EyeSlash
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => setshowPassword(!showPassword)}
                      />
                    ) : (
                      <Icon.Eye
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => setshowPassword(!showPassword)}
                      />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                {passErr ? (
                  <p className="errMsg">Please enter valid password</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Select
                  isMulti={false}
                  options={roleOptions}
                  value={role}
                  onChange={(option) => {
                    setRole(option);
                    setRoleErr(false);
                  }}
                />
                {roleErr ? <p className="errMsg">Please enter any role</p> : ""}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Zone</Form.Label>
                <Select
                  isMulti={true}
                  options={zoneOption}
                  value={zone}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Zone"
                  onChange={(option) => {
                    setZone(option);
                    setGat(null);
                    setfireZoneErr(false);
                  }}
                  isSearchable={false}
                />
                {fireZoneErr ? (
                  <p className="errMsg">Please select zone</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Label>Add Blocks</Form.Label>

              <div className="d-flex align-items-center">
                <Col className="m-2 d-flex flex-column">
                  {/* <Form.Label>Zone Name</Form.Label> */}
                  <Form.Select
                    value={zoneName}
                    onChange={(e) => {
                      setZoneName(e.target.value);
                      setBlockNo("");
                      e.target.value.length > 0 && setZoneErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Zone
                    </option>
                    {zoneNameList !== null &&
                    zoneNameList !== undefined &&
                    zoneNameList.length > 0 ? (
                      zoneNameList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.zonename}>
                            {itm?.zonename}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {zoneErr ? (
                    <p className="errMsg">Please select zone</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col className="m-2 d-flex flex-column">
                  {/* <Form.Label>Block</Form.Label> */}
                  <Form.Select
                    disabled={!zoneName}
                    value={blockNo}
                    onChange={(e) => {
                      setBlockNo(e.target.value);
                      e.target.value.length > 0 && setBlockErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Block
                    </option>
                    {blockNoList !== null &&
                    blockNoList !== undefined &&
                    blockNoList.length > 0 ? (
                      blockNoList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.blockNo}>
                            {itm?.blockNo}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {blockErr ? (
                    <p className="errMsg">Please select block</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col className="m-2 d-flex align-items-end">
                  <Button className="primaryBtn p-2" onClick={addBlockZone}>
                    <Icon.Plus size={18} className="me-2" />
                    Add
                  </Button>
                </Col>
              </div>
              {gatErr ? (
                <p className="errMsg">Please Add Atleast one block</p>
              ) : (
                ""
              )}
            </Col>

            <Col md={12}>
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                {userBlocks.length > 0
                  ? userBlocks.map((row, index) => {
                      return (
                        <div className="blockshow" key={index}>
                          <span>{row.label}</span>
                          <Icon.XLg
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => removeBlock(index)}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {loader ? (
            <Spinner variant="primary" />
          ) : (
            <Button className="primaryBtn" onClick={addUser}>
              <Icon.PlusCircle className="me-1" />
              Add
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* ---------------- Edit User Modal ----------------------------------------------------------- */}
      <Modal
        size="md"
        show={showEdit}
        backdrop="static"
        keyboard={false}
        centered
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h4 className="mb-0">
              <Icon.PencilSquare size={20} className="me-2" />
              Update User
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                      ) &&
                      e.target.value.trim()
                    ) {
                      setName(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setName(e.target.value);
                    }
                    e.target.value.length > 0
                      ? setNameErr(false)
                      : setNameErr(true);
                  }}
                />
                {nameErr ? (
                  <p className="errMsg">Please enter user name</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  //   if (!e.target.value.match(/[0-9+#$&%!~=^_:()/\/{}\[\]|/*/\//\-/?<>,;`'""/]/) &&
                  //     e.target.value.trim()
                  // ) {
                  //   setEmail(e.target.value);
                  // } else if (e.target.value.length === 0) {
                  //   setEmail(e.target.value);
                  // }
                  // e.target.value.length>0 ? setEmailErr(false): setEmailErr(true);
                />
                {/* {emailErr ? <p className='errMsg'>Please enter email</p> : ''} */}
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control value={role?.value} disabled={true} />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Zone</Form.Label>
                <Select
                  isMulti={true}
                  options={zoneOption}
                  value={zone}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Zone"
                  onChange={(option) => {
                    setZone(option);
                    setGat(null);
                    setfireZoneErr(false);
                  }}
                  isSearchable={false}
                />
                {fireZoneErr ? (
                  <p className="errMsg">Please select zone</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Label>Add Blocks</Form.Label>

              <div className="d-flex align-items-center">
                <Col className="m-2 d-flex flex-column">
                  {/* <Form.Label>Zone Name</Form.Label> */}
                  <Form.Select
                    value={zoneName}
                    onChange={(e) => {
                      setZoneName(e.target.value);
                      setBlockNo("");
                      e.target.value.length > 0 && setfireZoneErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Zone
                    </option>
                    {zoneNameList !== null &&
                    zoneNameList !== undefined &&
                    zoneNameList.length > 0 ? (
                      zoneNameList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.zonename}>
                            {itm?.zonename}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {zoneErr ? (
                    <p className="errMsg">Please select zone</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col className="m-2 d-flex flex-column">
                  {/* <Form.Label>Block</Form.Label> */}
                  <Form.Select
                    disabled={!zoneName}
                    value={blockNo}
                    onChange={(e) => {
                      setBlockNo(e.target.value);
                      e.target.value.length > 0 && setBlockErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Block
                    </option>
                    {blockNoList !== null &&
                    blockNoList !== undefined &&
                    blockNoList.length > 0 ? (
                      blockNoList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.blockNo}>
                            {itm?.blockNo}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {blockErr ? (
                    <p className="errMsg">Please select block</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col className="m-2 d-flex align-items-end">
                  <Button className="primaryBtn p-2" onClick={addBlockZone}>
                    <Icon.Plus size={18} className="me-2" />
                    Add
                  </Button>
                </Col>
              </div>
              {gatErr ? (
                <p className="errMsg">Please Add Atleast one block</p>
              ) : (
                ""
              )}
            </Col>

            <Col md={12}>
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                {userBlocks.length > 0
                  ? userBlocks.map((row, index) => {
                      return (
                        <div className="blockshow" key={index}>
                          <span>{row.label}</span>
                          <Icon.XLg
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => removeBlock(index)}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {loader ? (
            <Spinner variant="primary" />
          ) : (
            <Button className="primaryBtn" onClick={updateUser}>
              <Icon.PencilSquare className="me-1" />
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* ---------------- Delete User Modal ----------------------------------------------------------- */}
      <Modal
        size="md"
        show={showDel}
        backdrop="static"
        keyboard={false}
        centered
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h4 className="mb-0">
              <Icon.Trash size={20} className="me-2" />
              Delete User
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center mb-0">Are you sure to delete ?</h4>
        </Modal.Body>

        <Modal.Footer>
          {loader ? (
            <Spinner variant="primary" />
          ) : (
            <Button
              className="primaryBtn"
              onClick={deleteUser}
              disabled={loader}
            >
              <Icon.Trash className="me-2" /> Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OffiecrManageUsers;
