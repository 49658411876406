import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Spinner, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
// import Search from '../../Assets/Search.svg'
import Surveys from '../../Assets/Surveys.svg';
import PartlyDoc from '../../Assets/PartlySubmittedDoc.svg';
import FullyChecked from '../../Assets/FullySubmitChecked.svg';
import Updated from '../../Assets/Updated.svg';
import Plus from '../../Assets/Plus.svg';
import EqualTo from '../../Assets/EqualTo.svg';
import OwnerMobile from '../../Assets/OwnerMobile.svg';
import RentedMobile from '../../Assets/RentedShop.svg';
import Rental from '../../Assets/Rental.svg';
import Goal from '../../Assets/Goal.svg';
import Licesne from '../../Assets/License.svg';
import GraphLoader from '../../Assets/GraphLoader.gif';
import MultibarChart from '../../Features/Charts/MultibarChart';
import Chartjs from '../Charts/Chartjs';
import moment from 'moment';
import { useSelector } from 'react-redux';


const OfficerHome = () => {

  const userState = useSelector(state => state.surveylogin.userData.data);

  var today = new Date();
  var curHr = today.getHours();


  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState('');
  const [cards, setCards] = useState('');

  const [propertyTypeData, setPropertyTypeData] = useState('');
  const [propertyTypeLabels, setPropertyTypeLabels] = useState('');

  const [hazardousData, setHazardousData] = useState('');
  const [hazardousLabel, setHazardousLabel] = useState('');

  const [allowedInsData, setAllowedInsData] = useState('');
  const [allowedInsLabel, setAllowedInsLabel] = useState('');

  const [fireEquipData, setFireEquipData] = useState('');
  const [fireEquipLabels, setFireEquipLabels] = useState('');
  

  const [zoneData, setZoneData] = useState('');
  const [zoneLabels, setZoneLabels] = useState('');


    useEffect(()=>{
      getDashCards();
      getPropertyTypeGraph();
      getHazardousGraph();
    //   getAllowedInsideGraph();
      getFireEquipGraph();
      getZoneWiseSurveyGraph();
    },[date])

  const getDashCards = async ()=>{
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/newSurvey/dashboardCards', {
      method:'POST', headers:{'Content-Type':'application/json'},
      body:JSON.stringify({
        surveyDate:date
      })
    }).then((res)=>res.json()).then((response)=>{
      setCards(response);
      setLoader(false);
    }).catch((Err)=>{
      console.log("Err while getting dashcards", Err);
      setLoader(false);
    })
  }

  const getPropertyTypeGraph = async()=>{
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/newSurvey/surveyByPropertyTypeGraph', {
      method:'POST',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({
        surveyDate:date
      })
    }).then((res)=>res.json()).then((response)=>{
      if (response.status === 200) {
        let dataArray = [];

        dataArray.push({
          type: 'bar',
          label: "Property Count",
          fill: false,
          data: response.count,
          borderWidth: 2,
          borderColor: 'rgba(0, 218, 33, 1)',
          backgroundColor: "rgba(28, 255, 68, 0.43)"

        });
        setPropertyTypeData(dataArray);
      }
      setPropertyTypeLabels(response?.propertyTypes);
      setLoader(false);
    }).catch((Err)=>{
      console.log("Err while getting PropertyType Graph", Err);
      setLoader(false);
    })
  }

 /* --------------------- Hazardours Graph ----------------------- */
  const getAllowedInsideGraph = async () => {
      await fetch(process.env.REACT_APP_BASE_URL + '/dashboard/allowedInsideGraph', {
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify({ surveyDate:date })
      }).then(res=>res.json()).then((response)=>{
        if (response.status === 200) {
          let dataArray = [];
  
          dataArray.push({
            type: 'pie',
            label: "Allowed Inside",
            fill: true,
            data: response.allowedInsideCountArr,
            borderWidth: 2,
            borderColor: ['rgba(0, 218, 33, 1)', 'rgba(255, 159, 64, 1)'],
            backgroundColor: ["rgba(28, 255, 68, 0.43)", 'rgba(255, 159, 64, 0.5)' ]
  
          });
          setAllowedInsData(dataArray);
        }
        setAllowedInsLabel(response?.allowedInsideArr);
      }).catch((Err)=>{
        console.log("Err while getting hazardous Graph", Err);
      })
  }

   /* --------------------- Allowed Inside Graph ----------------------- */
   const getHazardousGraph = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + '/newSurvey/hazardousGraph', {
      method:'POST',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({ surveyDate:date })
    }).then(res=>res.json()).then((response)=>{
      if (response.status === 200) {
        let dataArray = [];

        dataArray.push({
          type: 'doughnut',
          label: "Harzardous",
          fill: true,
          data: response.hazardousCountArr,
          borderWidth: 2,
          borderColor: ['rgba(0, 218, 33, 1)', 'rgba(255, 159, 64, 1)'],
          backgroundColor: ["rgba(28, 255, 68, 0.43)", 'rgba(255, 159, 64, 0.5)' ]

        });
        setHazardousData(dataArray);
      }
      setHazardousLabel(response?.hazardousArr);
    }).catch((Err)=>{
      console.log("Err while getting Is Allowed Inside Graph", Err);
    })
}

 /* --------------------- Fire Extinguisher Euipement Graph ----------------------- */
 const getFireEquipGraph = async () => {
  await fetch(process.env.REACT_APP_BASE_URL + '/newSurvey/fireExtinguisherEquipmentGraph', {
    method:'POST',
    headers:{'Content-Type':'application/json'},
    body:JSON.stringify({ surveyDate:date })
  }).then(res=>res.json()).then((response)=>{
    if (response.status === 200) {
      let dataArray = [];

      dataArray.push({
        type: 'pie',
        label: "Fire Extinguisher Equipement",
        fill: true,
        data: response.fireExtinguisherEquipmentCountArr,
        borderWidth: 2,
        borderColor: ['rgba(0, 218, 33, 1)', 'rgba(255, 159, 64, 1)'],
        backgroundColor: ["rgba(28, 255, 68, 0.43)", 'rgba(255, 159, 64, 0.5)' ]

      });
      setFireEquipData(dataArray);
    }
    setFireEquipLabels(response?.fireExtinguisherEquipmentArr);
  }).catch((Err)=>{
    console.log("Err while getting Fire Equip Graph", Err);
  })
}


  const getZoneWiseSurveyGraph = async()=>{
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/newSurvey/zoneWiseSurveyGraph', {
      method:'POST',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({
          surveyDate:date
      })
    }).then((res)=>res.json()).then((response)=>{
      if (response.status === 200) {

        let dataArray = [];

        dataArray.push({
          type: 'bar',
          label: "Zone Count",
          fill: false,
          data: response.count,
          borderWidth: 2,
          borderColor: 'rgba(0, 169, 255, 1)',
          backgroundColor: "rgba(137, 207, 243, 0.43)"
          // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });
        
        setZoneData(dataArray);
      }
      setZoneLabels(response?.zones);
      setLoader(false);
    }).catch((Err)=>{
      console.log("Err while getting PropertyType Graph", Err);
      setLoader(false);
    })
  }



  return (
    <div className='Home outletPadding'>
        <Container>
          <Row className='d-flex justify-content-between  align-items-center mb-4'>
            <Col md={4}>
            <h6 className='mb-1'>Hello {userState?.userType_name}, { curHr < 12 ? <>Good Morning<Icon.BrightnessHighFill className='ms-2 me-1' /></>
                  : curHr < 18 ? <>Good Afternoon<Icon.BrightnessAltHighFill className='ms-2 me-1' size={20} /></> :
                    <>Good Evening<Icon.MoonStars className='ms-2 me-1' /></>}
            </h6>
                  <h3 className='mb-0'>Welcome to dashboard</h3>
            </Col>

            <Col md={2} className='h-100'>
              <Form.Group>
                <Form.Label>Filter By Date</Form.Label> 
                <div className='d-flex align-items-center'>
                <Form.Control type='date' maz={new Date()} value={date} onChange={(e)=>setDate(moment(e.target.value).format('YYYY-MM-DD'))}/>
                </div>
              </Form.Group>
            </Col>
          </Row>

    { loader ? <center><Spinner variant='primary'/></center> :
    <>
      
         <h6 className='headLabel'>General Information Stats</h6>
         <Row className='d-flex justify-content-center'>
           <Col md={5} lg={3} data-aos="fade-up" data-aos-delay='300' 
              className='mb-4 d-flex flex-column flex-md-row align-items-center'>
              <Card className='w-100'>
                <Card.Body>
                <img src={FullyChecked} alt="Fully Checked"/>
                <div className='data'>
                  <h4>{cards?.partlySubmitCount}</h4>
                  <h6>Partly Submitted Count</h6>
                  </div>
                </Card.Body>
              </Card>
                <img src={Plus} alt="Plus" width={25} className='ms-md-3 mt-3 mt-md-0'/>
            </Col> 

           

            
          <Col md={5} lg={3} data-aos="fade-up" data-aos-delay='400' 
              className='mb-4 d-flex flex-column flex-md-row align-items-center'>
            <Card className='h-100 w-100'>
              <Card.Body>
              <img src={FullyChecked} alt="Withouy Property Code"/>
              <div className='data'>
                <h4>{cards?.fullySubmitCount}</h4>
                <h6>Fully Submitted Count</h6>
                </div>
              </Card.Body>
            </Card>
            <img src={EqualTo} alt="EqualTo" width={25} className='ms-md-3 mt-3 mt-md-0'/>
          </Col>

            <Col md={5} lg={3} data-aos="fade-up" data-aos-delay='400' className='mb-4 pe-0 pe-md-5'>
              <Card>
                <Card.Body>
                <img src={Surveys} alt="Fully Submitted"/>
                <div className='data'>
                  <h4>{ cards?.partlySubmitCount + cards?.fullySubmitCount}</h4>
                  <h6>Total Submitted Count</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
      </Row> 

        <Row className='d-flex justify-content-center'>
           <Col md={5} lg={3} data-aos="fade-up" data-aos-delay='300' 
              className='mb-4 d-flex flex-column flex-md-row align-items-center'>
              <Card className='w-100'>
                <Card.Body>
                <img src={FullyChecked} alt="Fully Checked"/>
                <div className='data'>
                  <h4>{cards?.page1SubmitCount}</h4>
                  <h6>Page 1 Submit count</h6>
                  </div>
                </Card.Body>
              </Card>
                <img src={Plus} alt="Plus" width={25} className='ms-md-3 mt-3 mt-md-0'/>
            </Col> 
            
          <Col md={5} lg={3} data-aos="fade-up" data-aos-delay='400' 
              className='mb-4 d-flex flex-column flex-md-row align-items-center'>
            <Card className='h-100 w-100'>
              <Card.Body>
              <img src={FullyChecked} alt="Withouy Property Code"/>
              <div className='data'>
                <h4>{cards?.page2SubmitCount}</h4>
                <h6>Page 2 Submit count</h6>
                </div>
              </Card.Body>
            </Card>
            <img src={EqualTo} alt="EqualTo" width={25} className='ms-md-3 mt-3 mt-md-0'/>
          </Col>

            <Col md={5} lg={3} data-aos="fade-up" data-aos-delay='400' className='mb-4 pe-0 pe-md-5'>
              <Card>
                <Card.Body>
                <img src={Surveys} alt="Fully Submitted"/>
                <div className='data'>
                  <h4>{cards?.partlySubmitCount}</h4>
                  <h6>Partially Submitted Count</h6>
                  </div>
                </Card.Body>
              </Card>
            </Col>
      </Row> 


      <Row className='justify-content-center mt-3'>
         <h6 className='headLabel'>सामान्य माहिती</h6>
         
          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
           <Card className='h-100'>
             <Card.Body>
             <img src={OwnerMobile} alt="Owner Mobile Captured"/>
             <div className='data'>
               <h4>{cards?.ownerMobileCaptured}</h4>
               <h6>मूळ मालमत्तेच्या मालकाचा संपर्क क्रमांक</h6>
               </div>
             </Card.Body>
           </Card>
         </Col>

         <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
           <Card className='h-100'>
             <Card.Body>
             <img src={RentedMobile} alt="Lessee Mobile Captured"/>
             <div className='data'>
               <h4>{cards?.rentedPersonMobileCaptured}</h4>
               <h6>भाडेकरी/व्यवसाय धारकाचे संपर्क क्रमांक</h6>
               </div>
             </Card.Body>
           </Card>
         </Col>

         <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
           <Card className='h-100'>
             <Card.Body>
             <img src={Rental} alt="Total Rental Properties"/>
             <div className='data'>
               <h4>{cards?.rentedCount}</h4>
               <h6>व्यवसाय चालक मूळ मालक आहे कि भाडे तत्त्वावर दिली आहे ?</h6>
               </div>
             </Card.Body>
           </Card>
         </Col>
       </Row>

      <Row className='justify-content-center mt-3'>
       <h6 className='headLabel'>परवाना संबंधित माहिती</h6>
        {/* <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Goal} alt="Total Businesses"/>
              <div className='data'>
                <h4>{cards?.businessCount}</h4>
                <h6>व्यवसाय परवाना संबंधित माहिती</h6>
                </div>
              </Card.Body>
            </Card>
          </Col> */}
        <Row className='justify-content-center mt-3 text-center'>
            <h6 className='mb-4' style={{ fontSize:'larger',fontWeight:600 }}>व्यवसाय संबंधित माहिती</h6>
        <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Total Businesses Parvana"/>
              <div className='data'>
                <h4>{cards?.businessParvanaCount}</h4>
                {/* <h6>Total Business Parvana</h6> */}
                <h6>व्यवसाय परवाना घेतला आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

         <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
           <Card className='h-100'>
             <Card.Body>
             <img src={Licesne} alt="Total Shopact License"/>
             <div className='data'>
               <h4>{cards?.businessShopActLicenseCount}</h4>
               {/* <h6>Total Business Shopact</h6> */}
               <h6>शॉप अँक्ट लायसन्स घेतले आहे का ?</h6>
               </div>
             </Card.Body>
           </Card>
         </Col>

         <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
           <Card className='h-100'>
             <Card.Body>
             <img src={Licesne} alt="Total Food License"/>
             <div className='data'>
               <h4>{cards?.foodLicenseCount}</h4>
               {/* <h6>Total Food Licenses</h6> */}
               <h6>अन्न औषध प्रशासन परवाना घेतला आहे का ?</h6>
               </div>
             </Card.Body>
           </Card>
         </Col>
        </Row>

          <Row className='justify-content-center mt-3 text-center'>
          <h6 className='mb-4' style={{ fontSize:'larger',fontWeight:600 }}>साठा संबंधित माहिती</h6>
          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
           <Card className='h-100'>
             <Card.Body>
             <img src={Licesne} alt="Total Satha License"/>
             <div className='data'>
               <h4>{cards?.sathaCount}</h4>
               {/* <h6>Total Satha Count</h6> */}
               <h6>साठा परवाना संबंधित माहिती</h6>
               </div>
             </Card.Body>
           </Card>
         </Col>

         <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
           <Card className='h-100'>
             <Card.Body>
             <img src={Licesne} alt="Total satha Parvana"/>
             <div className='data'>
               <h4>{cards?.sathaParvanaCount}</h4>
               {/* <h6>Total Satha Parwana</h6> */}
               <h6>मनपा मार्फत साठा परवाना घेतला आहे का ?</h6>
               </div>
             </Card.Body>
           </Card>
         </Col>

         <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
           <Card className='h-100'>
             <Card.Body>
             <img src={Licesne} alt="Total Satha Shopact"/>
             <div className='data'>
               <h4>{cards?.sathaShopActLicenseCount}</h4>
               {/* <h6>Total Satha Shopact</h6> */}
               <h6>शॉप अँक्ट लायसन्स घेतले आहे का ?</h6>
               </div>
             </Card.Body>
           </Card>
         </Col>
          </Row>
       </Row>


       
      <Row className='justify-content-center mt-3'>
       <h6 className='headLabel'>अग्निशमन संबंधित माहिती</h6>
        <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Fire NOC Count"/>
              <div className='data'>
                <h4>{cards?.fireNOCCount}</h4>
                {/* <h6>Fire NOC Count</h6> */}
                <h6>व्यवसाया संबंधित  फायर सेफ्टी सर्टिफिकेट/ वार्षिक अग्निशमन ना हरकत दाखला प्राप्त केला आहे का?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Fire Annual Renewal Count"/>
              <div className='data'>
                <h4>{cards?.fireAnnualRenewalCount}</h4>
                {/* <h6>Fire Annual Renewal Count</h6> */}
                <h6>वैध कालावधीसाठी नूतनीकरण केले आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Fire Safety Certificate Count"/>
              <div className='data'>
                <h4>{cards?.fireSafetyCertificateCount}</h4> */}
                {/* <h6>Fire Safety Certificate Count</h6> */}
                {/* <h6>फायर सेफटी सर्टिफिकेट प्राप्त केले आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col> */}

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Fire Safety Certificate Count"/>
              <div className='data'>
                <h4>{cards?.fireExtinguisherEquipmentCount}</h4>
                {/* <h6>Fire Extinguisher Equipment Count</h6> */}
                <h6>व्यवसाय जागेत अग्निशमन यंत्रणा उपलब्ध आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Hazardous Count"/>
              <div className='data'>
                <h4>{cards?.hazardousCount}</h4>
                {/* <h6>Hazardous Count</h6> */}
                <h6>व्यवसाय जागेत धोकादायक उपकरणे आहेत का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Allowed Inside Count"/>
              <div className='data'>
                <h4>{cards?.allowedInsideCount}</h4> */}
                {/* <h6>Allowed Inside Count</h6> */}
                {/* <h6>व्यवसाय जागेत प्रवेश मिळाला आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col> */}

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Attic Count"/>
              <div className='data'>
                <h4>{cards?.atticCount}</h4>
                {/* <h6>Attic Count</h6> */}
                <h6>पोटमाळ्याचा व्यवसायासाठी वापर होत आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={3} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Attic Count"/>
              <div className='data'>
                <h4>{cards?.basementCount}</h4>
                {/* <h6>Basement Count</h6> */}
                <h6>बेसमेंट व्यवसायासाठी वापर करत आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={5} data-aos="fade-up" data-aos-delay='400' className='mb-4'>
            <Card className='h-100'>
              <Card.Body>
              <img src={Licesne} alt="Residential Purposes Count"/>
              <div className='data'>
                <h4>{cards?.residentialPurposesCount}</h4>
                {/* <h6>Residential Purposes Count</h6> */}
                <h6>संबंधित व्यवसायधारक व्यवसाय जागेत राहत आहे का ?</h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
      </Row>
  </>
}

    <Row className='justify-content-center mt-4'>

    <Col md={4} className='mb-5'>
      {
       hazardousData !== null && hazardousData !== undefined && hazardousData?.length > 0 ?
          <Chartjs chartLabels={hazardousLabel} chartData={hazardousData}  stacked={true}
           chartHeading="Harzardous Graph" height='300px' isPlugin={false}
            isModal={true}/> :
            <div className='d-flex justify-content-center align-items-center h-100'>
              <img src={GraphLoader} alt="Loader" width={200} />
            </div>
      }
      </Col>

      {/* <Col md={4} className='mb-5'>
      {
       allowedInsData !== null && allowedInsData !== undefined && allowedInsData?.length > 0 ?
          <Chartjs chartLabels={allowedInsLabel} chartData={allowedInsData}  stacked={true}
           chartHeading="Allowed Inside Graph" height='300px' isPlugin={false}  isModal={true}/> :
            <div className='d-flex justify-content-center align-items-center h-100'>
              <img src={GraphLoader} alt="Loader" width={200} />
            </div>
      }
      </Col> */}

      <Col md={4} className='mb-5'>
        {
        fireEquipData !== null && fireEquipData !== undefined && fireEquipData?.length > 0 ?
            <Chartjs chartLabels={fireEquipLabels} chartData={fireEquipData}  stacked={true}
            chartHeading="Fire Extinguisher Equipment Graph" height='300px' isPlugin={false}/> :
              <div className='d-flex justify-content-center align-items-center h-100'>
                <img src={GraphLoader} alt="Loader" width={200} />
              </div>
        }
      </Col>
      <Col md={9} className='mb-5'>
      {
        propertyTypeData !== null && propertyTypeData !== undefined && propertyTypeData.length > 0 ?
          <MultibarChart chartLabels={propertyTypeLabels} chartData={propertyTypeData}  height='300px'
            stacked={false} chartHeading="Survey By Property Type Graph" /> :
            <div className='d-flex justify-content-center align-items-center h-100'>
              <img src={GraphLoader} alt="Loader" width={200} />
            </div>
      }
      </Col>

      <Col md={11} className='mb-5'>
        {
          zoneData !== null && zoneData !== undefined && zoneData.length > 0 ?
            <MultibarChart chartLabels={zoneLabels} chartData={zoneData} height='400px'
              stacked={false} chartHeading="Zone Wise Survey Graph" /> :
              <div className='d-flex justify-content-center align-items-center h-100'>
                <img src={GraphLoader} alt="Loader" width={200} />
              </div>
        }
      </Col>
  </Row>
</Container> 
    </div>
  )
}

export default OfficerHome;