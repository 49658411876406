import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Pagination from '../Common/Pagination';
import Skeleton from '../Common/Skeleton';
import moment from 'moment';
import { toast } from 'react-toastify';

const SchoolLetter = () => {

    const [loader, setLoader] =  useState(false);
    const [mergeLoader, setMergeLoader] =  useState(false);
    const [schools, setSchools] = useState([]);

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState();
    const [loaderArray, setLoaderArray] = useState([]);
        
    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1);
        setDocPerPage(docsPageProp);
    };

    useEffect(()=>{
        getAllSchools();
    },[docPerPage, pageNo])

    const getAllSchools = async ()=>{
        setLoader(true);
        let payload={
            documentsPerPage: docPerPage,
            page: pageNo,
        }
        try{
            const res = await fetch(process.env.REACT_APP_BASE_URL + "/school/getAllSchools",{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            })
            const response = await res.json(); 
            setSchools(response);
            setNoOfPages(response.noOfPages);
            setLoader(false);
        }catch(Err){
            console.log("Err while all schools", Err);
            setLoader(false);
        }
    }

    
  const downloadNotice = (notice, index) => {
    let arr = [...loaderArray];
    arr[index] = true;
    setLoaderArray(arr);

    fetch(notice.letterLink)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `Letter_${notice.schoolId}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        let arr = [...loaderArray];
        arr[index] = false;
        setLoaderArray(arr);
      });
  };


  const getMergedLetter = async () =>{
    setMergeLoader(true);
    try{
        const res = await fetch(process.env.REACT_APP_BASE_URL + "/school/getMergeLetterLink", {
            method: "GET",
        })
        const response = await res.json();
        if(response.status === 200){
            const link = response?.data;
            fetch(link)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement("a");
              a.href = url;
              a.download = `${link.split('/').pop()}.pdf`;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              setMergeLoader(false);
            });
        }
        else{
            setMergeLoader(false);
            toast.error('Failed to download');
        }
   
    }catch(Err){
        console.log("Err while getting school merged letter", Err);
        setMergeLoader(false);
    }
  }


  return (
    <div className='SchoolLetter outletPadding'>

      <Button className='primaryBtn ms-auto' disabled={mergeLoader} onClick={getMergedLetter}>
        {
            mergeLoader ? <p className='mb-0'><Spinner size='sm' className='me-1'/> Please Wait, Downloading ...</p> :
            ( <>
                  <Icon.JournalArrowDown className='me-2'/>
                    Download Merge Letter
                </>
            )
        }
      </Button>

      <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <div className="table-wrapper mt-2" style={{ maxHeight: "73vh" }}>
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    <th>School Name</th>
                    <th>School Adress</th>
                    <th>Business Uni. Number</th>
                    <th>Letter Generete Date</th>
                    <th>Action</th>
                  </thead>
                  {loader ? (
                    <Skeleton rows={10} cols={6} />
                  ) : schools?.data !== null &&
                        schools?.data !== undefined &&
                        schools?.data?.length > 0 ? (
                        schools?.data?.map((itm, index) => {
                    
                    const {schoolName, schoolAddress, businessUniqueNo, updatedAt} = itm; 
                      return (
                        <tr key={index} >
                          <td>{index + 1 + docPerPage * (pageNo - 1)}</td>
                          <td style={{width:'310px'}}>{schoolName}</td>
                          <td style={{width:'310px'}}>{schoolAddress ? schoolAddress : "-"}</td>
                          <td>{businessUniqueNo ? businessUniqueNo : "-"}</td>
                          <td>
                            {updatedAt ? moment(updatedAt).format("DD-MM-YYYY hh:mm a") : "-"}
                          </td>
                          <td className="d-flex align-items-center justify-content-center">
                            {loaderArray[index] ? (
                              <center><Spinner /></center>
                            ) : (
                              <Button
                                className="primaryBtn ms-2"
                                onClick={() => {
                                  downloadNotice(itm, index);
                                }}>
                                <Icon.ArrowDownCircle className='me-2'/> Download Single Letter
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{schools?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
    </div>
  )
}

export default SchoolLetter