import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Button,
  Spinner,
  Col,
  Form,
  Modal,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Skeleton from "../Common/Skeleton";
import { toast } from "react-toastify";
import { setNewActiveKey } from "../Common/ActiveKeySlice";

const HospitalAddCompliance = () => {
  const userState = useSelector((state) => state.surveylogin.userData?.data);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [activeSurvey, setActiveSurvey] = useState("");
  const [show, setShow] = useState(false);
  const [complianceName, setComplianceName] = useState("");
  const [complianceMobile, setComplianceMobile] = useState("");
  const [complianceReason, setComplianceReason] = useState("");
  const [addLoader, setAddLoader] = useState(false);
  const [addErr, setAddErr] = useState("");

  useEffect(() => {
    getHospitalById();
  }, []);

  const getHospitalById = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/hospital/getHospitalById", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ hospitalId: location?.state }),
    })
      .then((res) => res.json())
      .then((response) => {
        setActiveSurvey(response?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.error("Error while view currentSurvey", err);
        setLoader(false);
      });
  };

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const closeModal = () => {
    setShow(false);
    setComplianceMobile("");
    setComplianceName("");
    setComplianceReason("");
  };

  const validate = () => {
    let errors = {};

    if (!complianceName) {
      errors.complianceName = "*Please Enter Compliance Name.";
    }

    if (!complianceMobile) {
      errors.complianceMobile = "*Please Enter Compliance Mobile.";
    }

    if (!complianceReason) {
      errors.complianceReason = "*Please Enter Compliance Reason.";
    }

    setAddErr(errors);
    return Object.keys(errors).length === 0;
  };

  const AddComplianceAPI = async () => {
    if (validate()) {
      setAddLoader(true);
      let payload = {
        hospitalId: location.state,
        complianceRcvrName: complianceName,
        complianceRcvrMobile: complianceMobile,
        complianceById: userState.user_id,
        complianceByName: userState.name,
        complianceByMobile: userState.mobile,
        complianceByRole: userState.userType_name,
        complianceReason: complianceReason,
      };

      await fetch(process.env.REACT_APP_BASE_URL + "/hospital/hospitalAddCompliance", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            toast.success("Added Compliance Successfully.");
            setAddLoader(false);
            navigate("/dashboard/hospital-compliance-history");
            dispatch(
              setNewActiveKey({
                activeKey: 22,
                subActiveKey: 22.3,
                activeTab: "Hospital Compliance History",
              })
            );
          }
        })
        .catch((err) => {
          console.error("Error while view currentSurvey", err);
          setAddLoader(false);
        });
    }
  };

  return (
    <div className="outletPadding">
      <Container>
        <Button className="primaryBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />
          Back
        </Button>

        <Button
          className="primaryBtn ms-auto"
          onClick={() => {
            setShow(true);
          }}
        >
          Add Compliance
        </Button>

        {loader ? (
          <center>
            <Spinner variant="primary mt-3" />
          </center>
        ) : (
          <Row className="outer-wrapper mx-auto mt-4">
            <div className="table-wrapper">
              <table data-aos="fade-up" data-aos-delay="200">
                <tbody>

                <tr>
                    <th>
                      <Icon.Code className="me-2" />
                      Hospital Sr No
                    </th>
                    <td>
                      {activeSurvey?.hospitalSrNo
                        ? activeSurvey?.hospitalSrNo
                        : "-"}
                    </td>
                  </tr>

                <tr>
                    <th>
                      <Icon.Code className="me-2" />
                      Hospital Name
                    </th>
                    <td>
                      {activeSurvey?.hospitalName
                        ? activeSurvey?.hospitalName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <Icon.Code className="me-2" />
                      Hospital Address
                    </th>
                    <td>
                      {activeSurvey?.hospitalAddress
                        ? activeSurvey?.hospitalAddress
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <Icon.Code className="me-2" />
                      Zone
                    </th>
                    <td>
                      {activeSurvey?.zone
                        ? activeSurvey?.zone
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <Icon.Code className="me-2" />
                      Manager Name
                    </th>
                    <td>
                      {activeSurvey?.managerName
                        ? activeSurvey?.managerName
                        : "-"}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <Icon.Code className="me-2" />
                      Manager Mobile
                    </th>
                    <td>
                      {activeSurvey?.managerMobile
                        ? activeSurvey?.managerMobile
                        : "-"}
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </Row>
        )}
      </Container>
      <Modal
        size="lg"
        show={show}
        backdrop="static"
        keyboard={false}
        centered
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Add Compliance</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex flex-wrap">
            <Col md={6} className="d-flex flex-column">
              <Form.Label className="searchLabel">Compliance Name</Form.Label>
              <Form.Control
                placeholder="Enter Compliance Name"
                value={complianceName}
                className="me-3"
                onChange={(e) => {
                    setComplianceName(e.target.value);
                }}
              />
              {addErr.complianceName && (
                <p className="errMsg">{addErr.complianceName}</p>
              )}
            </Col>
            <Col md={6} className="d-flex flex-column">
              <Form.Label className="searchLabel">Compliance Mobile</Form.Label>
              <Form.Control
                placeholder="Enter Compliance Mobile"
                value={complianceMobile}
                className="me-3"
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[0-9]+$/) &&
                    e.target.value.length <= 10
                  ) {
                    setComplianceMobile(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setComplianceMobile(e.target.value);
                  }
                }}
              />
              {addErr.complianceMobile && (
                <p className="errMsg">{addErr.complianceMobile}</p>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12} className="d-flex flex-column">
              <Form.Label className="searchLabel">Compliance Reason</Form.Label>
              <Form.Control
                placeholder="Enter Compliance Reason"
                value={complianceReason}
                className="me-3"
                onChange={(e) => {
                    setComplianceReason(e.target.value);
                }}
              />
              {addErr.complianceReason && (
                <p className="errMsg">{addErr.complianceReason}</p>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {addLoader ? (
            <Spinner />
          ) : (
            <Button className="primaryBtn" onClick={() => {AddComplianceAPI()}}>
              Add Compliance
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HospitalAddCompliance;
