import React, { useEffect, useState } from 'react'

const useGetBlockNo = () => {
    const [blockNoList, setBlockNoList] = useState([])

    useEffect(() =>{
        try{
            fetch(
                process.env.REACT_APP_BASE_URL + "/block/getAllBlocks",
                {
                  method: "GET",
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((res) => res.json())
              .then((response) => {
                setBlockNoList(response?.data)
                })
                .catch((Err) => {
                  console.log("Err while getting propertType", Err);
                });
        }catch(error){
            console.log(error)
        }
            
    },[])

  return {blockNoList}
}

export default useGetBlockNo
