import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useGetBlockNo from "../Common/ReuseAPI/useGetBlockNo";
import useGetZoneName from "../Common/ReuseAPI/useGetZoneName";

const OffiecerManageSurveys = () => {
  const userState = useSelector((state) => state.surveylogin.userData.data);
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  // States for Mobile search filter ----------------
  const [searchMobile, setSearchMobile] = useState("");
  const [searchMobileErr, setSearchMobileErr] = useState(false);

  const [zonekey, setZonekey] = useState("");
  const [download, setDownload] = useState(false);
  const [excelLoader, setExceLoader] = useState(false);

  const [filters, setFilter] = useState("");
  const [radio, setRadio] = useState("");
  const [filtersErr, setFiltersErr] = useState("");

  const [propertyTypeList, setPropertyTypeList] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [propertyTypeErr, setPropertyTypeErr] = useState("");

  const [subPropertyTypeList, setSubPropertyTypeList] = useState("");
  const [subPropertyType, setSubPropertyType] = useState("");

  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");

  // Delete Modal and state
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [apiLoader, setApiLoader] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [error, setError] = useState({});

  const [blockNo, setBlockNo] = useState("");
  const [zoneName, setZoneName] = useState("");

  // filter error
  const [blockErr, setBlockErr] = useState(false);
  const [zoneErr, setZoneErr] = useState(false);

  // Reuse API
  const { blockNoList } = useGetBlockNo();
  const { zoneNameList } = useGetZoneName();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getAllSurveys();
  }, [pageNo, docPerPage]);

  useEffect(() => {
    getAllPropertyType();
  }, []);

  useEffect(() => {
    getAllSubPropertyTypeList();
  }, [propertyType]);

  const getAllPropertyType = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/pt/getAllPropertyType", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        let arr = response.data.filter(
          (row) => row?.propertyTypeId !== "POTYP1695284963574"
        );
        setPropertyTypeList(arr);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
      });
  };

  const getAllSubPropertyTypeList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/spt/getSubPropertyType", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ parentTypeId: propertyType }),
    })
      .then((res) => res.json())
      .then((response) => {
        setSubPropertyTypeList(response?.data);
      })
      .catch((Err) => {
        console.log("Err while getting sub property type", Err);
      });
  };

  const getAllSurveys = async () => {
    setLoader(true);
    let payload = {
      documentsPerPage: docPerPage,
      page: pageNo,
      mobile: searchMobile,
      [filters]: radio,
      // zonekey: zonekey,
      surveyZoneKey: zoneName,
      newBlock: blockNo,
      propertyTypeId: propertyType,
      subPropertyTypeId: subPropertyType,
      fromDate: startdate,
      toDate: enddate,
    };
    // if(filters){
    //     payload.filters=radio;
    // }
    await fetch(
      process.env.REACT_APP_BASE_URL + `/newSurvey/getAllSurveyDashboard`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setNoOfPages(response.noOfPages);
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting surveys", err);
        setLoader(false);
      });
  };

  // --------------- Search Survey By Mobile ------------------------------
  const searchByValidate = () => {
    let isValid = true;
    if (!searchMobile || searchMobile.length < 10) {
      isValid = false;
      setSearchMobileErr(true);
    }
    return isValid;
  };

  const searchByMobile = async () => {
    if (searchByValidate()) {
      getAllSurveys();
    }
  };

  // --------------- Search Survey By Zone ------------------------------
  // Search by block and zone
  const searchByBlockZoneValidate = () => {
    let isValid = true;
    if (!zoneName) {
      isValid = false;
      setZoneErr(true);
    } else if (!blockNo) {
      isValid = false;
      setBlockErr(true);
    }
    return isValid;
  };

  const searchByBlockZone = async () => {
    if (searchByBlockZoneValidate()) {
      await getAllSurveys();
    }
  };

  //------------ Filter By Status ---------------------------
  const filterByStatusValidate = () => {
    let isValid = true;
    if (!filters) {
      isValid = false;
      setFiltersErr("Please select valid status");
    } else if (!radio) {
      isValid = false;
      setFiltersErr("Please check Yes/No Filter");
    }
    return isValid;
  };

  const filterByStatus = async () => {
    if (filterByStatusValidate()) {
      await getAllSurveys();
    }
  };

  //---------- Search By Property Type ----------------------

  const searchByPropertyValidate = () => {
    let isValid = true;
    if (!propertyType) {
      isValid = false;
      setPropertyTypeErr("Please select valid property type");
    } else if (!subPropertyType) {
      isValid = false;
      setPropertyTypeErr("Please select valid sub property type");
    }
    return isValid;
  };
  const searchByPropertyType = async () => {
    if (searchByPropertyValidate()) {
      await getAllSurveys();
    }
  };

  // ------------ Download Excel after searching by zone ---------------------
  const downloadSurveyExcel = async () => {
    setExceLoader(true);

    const keys_to_keep = [
      "propertyCode",
      "propertyName",
      "propertyType",
      "propertyAddress",
      "finalBusinessAddress",
      "zonename",
      "zoneno",
      ,
      "ownerMobile",
      "ownerName",
      "surveyId",
      "surveyDate",
      "lat",
      "long",
      "propertyFloor",
      "buildingFloors",
      "addressLandmark",
      "addressFloor",
      "addressRoadColony",
      "propertyAreaMeter",
      "propertyAreaFoot",
      "propertyWorkPlace",
      "subPropertyType",
      "subPropertyTypeOther",
      "businessAddress",
      "ownerEmail",
      "isRented",
      "rentedPersonName",
      "rentedPersonMobile",
      "businessStartDate",
    ];

    await fetch(process.env.REACT_APP_BASE_URL + `/surveys/getAllSurvey`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ zonekey: zonekey }),
    })
      .then((res) => res.json())
      .then((response) => {
        const ExcelData = response?.data?.surveys?.map((e) => {
          const obj = {};
          keys_to_keep.forEach((k) => (obj[k] = e[k]));
          return obj;
        });

        const downloadExcel = (heading, fileName) => {
          let dataSet = [];
          dataSet = heading;
          const worksheet = XLSX.utils.json_to_sheet(dataSet);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, fileName);
        };

        downloadExcel(
          ExcelData,
          `${response?.data?.surveys[0].zonename}-Survey Report.xlsx`
        );
        setExceLoader(false);
      })
      .catch((err) => {
        console.log("Err while getting surveys", err);
        setExceLoader(false);
      });
    setLoader(false);
  };

  const searchByDate = async () => {
    if (startdate && enddate) {
      getAllSurveys();
    } else {
      !startdate && toast.error("Please Select From Date");
      !enddate && toast.error("Please Select To Date");
    }
  };

  const openDelete = (item) => {
    setDeleteId(item?.surveyId);
    setShowDelete(true);
  };

  const closeDelete = () => {
    setDeleteReason("");
    setDeleteId();
    setShowDelete(false);
  };

  const validate = () => {
    let errors = {};

    if (!deleteReason) {
      errors.deleteReason = "*Please Enter Delete Reason";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const DeleteSurvey = async () => {
    if (validate()) {
      await fetch(process.env.REACT_APP_BASE_URL + "/newSurvey/deleteSurvey", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          surveyId: deleteId,
          // deleteDate: new Date(),
          deleteReason: deleteReason,
          deletePersonId: userState.user_id,
          deletePersonName: userState.name,
          deletePersonMobile: userState.mobile,
          deletePersonRole: userState.userType_keyname,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            closeDelete();
            getAllSurveys();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getExcelExportData = async () => {
    setExceLoader(true);
    let payload = {
      documentsPerPage: "",
      page: "",
      mobile: searchMobile,
      [filters]: radio,
      // zonekey: zonekey,
      surveyZoneKey: zoneName,
      newBlock: blockNo,
      propertyTypeId: propertyType,
      subPropertyTypeId: subPropertyType,
      fromDate: startdate,
      toDate: enddate,
    };
    await fetch(
      process.env.REACT_APP_BASE_URL + `/newSurvey/getAllSurveyDashboard`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        downloadExcel(response.data);
      })
      .catch((err) => {
        console.log("Err while getting surveys", err);
        setExceLoader(false);
      });
  };

  const downloadExcel = (data) => {
    const ExcelData = data?.map((e) => {
      return {
        blockNo: e.blockNo ?? "-",
        propertyType: e.propertyType ?? "-",
        businessName: e.businessName ?? "-",
        finalBusinessAddress: e.finalBusinessAddress ?? "-",
        ownerName: e.ownerName ?? "-",
        ownerMobile: e.ownerMobile ?? "-",
        rentedPersonName: e.rentedPersonName ?? "-",
        rentedPersonMobile: e.rentedPersonMobile ?? "-",
        surveyDate: e.surveyDate ? moment(e.surveyDate).format("DD-MMM-YYYY hh:mm a") :"-",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(ExcelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `Survey Report- ${moment(new Date()).format("DD-MM-YYYY hh:mm a")}.xlsx`
    );
    setExceLoader(false);
  };

  return (
    <div className="ManageSurveys outletPadding">
      <Container>
        <Row className="justify-content-between">
          <Col md={4}>
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search by survey mobile
              </Form.Label>
              <Form.Control
                placeholder="Enter Mobile"
                value={searchMobile}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^[0-9]+$/) &&
                    e.target.value.length <= 10
                  ) {
                    setSearchMobile(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setSearchMobile(e.target.value);
                  }
                  e.target.value.length > 0
                    ? setSearchMobileErr(false)
                    : setSearchMobileErr(true);
                }}
              />
              {searchMobileErr ? (
                <p className="errMsg">Please enter valid mobile no.</p>
              ) : (
                ""
              )}
              <Button
                className="primaryBtn mx-auto mt-2"
                onClick={searchByMobile}
              >
                <Icon.Search className="me-2" size={15} />
                Search
              </Button>
            </Card>
          </Col>

          <Col md={4} className="my-auto mt-3 mt-md-0">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Filter by status</Form.Label>
              <Form.Select
                value={filters}
                onChange={(e) => {
                  setFilter(e.target.value);
                  e.target.value.length > 0 && setFiltersErr("");
                }}
                className="me-3"
              >
                <option value="" selected disabled>
                  Select any filter
                </option>
                <option value="isPropertyCode">PropertyCode</option>
                <option value="isRented">Rented</option>
                <option value="isSatha">Satha</option>
                <option value="isVyavsay">Vyavsay</option>
                <option value="isSathaParvana">Satha Parvana</option>
                <option value="isSathaShopActLicense">
                  Satha ShopAct License
                </option>
                <option value="isBusinessParvana">Business Parvana</option>
                <option value="isBusinessShopActLicense">
                  Business ShopAct License
                </option>
                <option value="isFoodLicense">FoodLicense</option>
                <option value="isFireNOC">FireNOC</option>
                <option value="isFireAnnualRenewal">Fire Annual Renewal</option>
                <option value="isFireSafetyCertificate">
                  Fire Safety Certificate
                </option>
                <option value="electricMeter">Electric Meter</option>
                <option value="isFireAnisFireExtinguisherEquipmentnualRenewal">
                  Fire Ext. Equipment
                </option>
                <option value="isHazardous">Hazardous</option>
                <option value="isAttic">Attic</option>
                <option value="isBasement">Basement</option>
                <option value="isResidentialPurpose">
                  Residential Purpose
                </option>
                <option value="isDetailsGiven">Details Given</option>
              </Form.Select>
              <span
                style={{ display: filters ? "flex" : "none" }}
                className="my-2"
              >
                <Form.Check
                  inline
                  value="true"
                  label="Yes"
                  name="group1"
                  type="radio"
                  onChange={(e) => {
                    setRadio(e.target.value);
                    e.target.value.length > 0 && setFiltersErr("");
                  }}
                />
                <Form.Check
                  inline
                  value="false"
                  label="No"
                  name="group1"
                  type="radio"
                  onChange={(e) => {
                    setRadio(e.target.value);
                    e.target.value.length > 0 && setFiltersErr("");
                  }}
                />
              </span>
              {filtersErr ? <p className="errMsg">{filtersErr}</p> : ""}
              <Button
                className="primaryBtn mx-auto mt-2"
                onClick={filterByStatus}
              >
                <Icon.Funnel className="me-2" />
                Filter
              </Button>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search by Property Type
              </Form.Label>
              <Form.Select
                value={propertyType}
                onChange={(e) => {
                  setPropertyType(e.target.value);
                  e.target.value.length > 0 && setPropertyTypeErr("");
                }}
              >
                <option value="" selected disabled>
                  Select Type
                </option>
                {propertyTypeList !== null &&
                propertyTypeList !== undefined &&
                propertyTypeList.length > 0 ? (
                  propertyTypeList?.map((itm, index) => {
                    return (
                      <option key={index} value={itm?.propertyTypeId}>
                        {itm?.propertyType}
                      </option>
                    );
                  })
                ) : (
                  <option value="">No Data Found</option>
                )}
              </Form.Select>

              {propertyType && (
                <Form.Select
                  className="mt-2"
                  value={subPropertyType}
                  onChange={(e) => {
                    setSubPropertyType(e.target.value);
                    e.target.value.length > 0 && setPropertyTypeErr(false);
                  }}
                >
                  <option value="" selected disabled>
                    Select Type
                  </option>
                  {subPropertyTypeList !== null &&
                  subPropertyTypeList !== undefined &&
                  subPropertyTypeList.length > 0 ? (
                    subPropertyTypeList?.map((itm) => {
                      return (
                        <>
                          <option value={itm?.subPropertyTypeId}>
                            {itm?.subPropertyType}
                          </option>
                        </>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              )}

              {propertyTypeErr ? (
                <p className="errMsg">{propertyTypeErr}</p>
              ) : (
                ""
              )}
              <Button
                className="primaryBtn mx-auto mt-2"
                onClick={searchByPropertyType}
              >
                <Icon.Search className="me-2" />
                Search
              </Button>
            </Card>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Block</Form.Label>{" "}
              <div className="d-flex align-items-start">
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>Zone Name</Form.Label>
                  <Form.Select
                    value={zoneName}
                    onChange={(e) => {
                      setZoneName(e.target.value);
                      setBlockNo("");
                      e.target.value.length > 0 && setZoneErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Zone
                    </option>
                    {zoneNameList !== null &&
                    zoneNameList !== undefined &&
                    zoneNameList.length > 0 ? (
                      zoneNameList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.zonekey}>
                            {itm?.zonename}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {zoneErr ? <p className="errMsg">Please select zone</p> : ""}
                </Col>

                <Col className="m-2 d-flex flex-column">
                  <Form.Label>Block</Form.Label>
                  <Form.Select
                    disabled={!zoneName}
                    value={blockNo}
                    onChange={(e) => {
                      setBlockNo(e.target.value);
                      e.target.value.length > 0 && setBlockErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Block
                    </option>
                    {blockNoList !== null &&
                    blockNoList !== undefined &&
                    blockNoList.length > 0 ? (
                      blockNoList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.blockNo}>
                            {itm?.blockNo}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {blockErr ? (
                    <p className="errMsg">Please select block</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col className="m-2 d-flex align-items-end">
                  <Button
                    className="primaryBtn p-2 mt-4"
                    onClick={searchByBlockZone}
                  >
                    <Icon.Search size={18} className="me-2" />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>
          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Dates</Form.Label>{" "}
              <div className="date-flex">
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    placeholder="Start Date"
                    type="date"
                    value={startdate}
                    onChange={(e) =>
                      setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    placeholder="End Date"
                    type="date"
                    min={startdate}
                    value={enddate}
                    onChange={(e) =>
                      setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex ">
                  {" "}
                  <Button
                    className="primaryBtn p-2 mt-1"
                    onClick={searchByDate}
                  >
                    <Icon.Search size={18} className="me-2" />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-end mt-5">
          {excelLoader ? (
            <Spinner />
          ) : (
            <Col md={2} className="d-flex justify-content-end">
              <Button
                className="primaryBtn"
                onClick={() => getExcelExportData()}
              >
                Excel Export
              </Button>
            </Col>
          )}
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <div
                className="table-wrapper mt-2"
                style={{ maxHeight: download ? "45vh" : "58vh" }}
              >
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    {/* <th>Property Code</th> */}
                    <th>Business Name</th>
                    <th>Owner Name</th>
                    <th>Owner Mobile</th>
                    <th>Property Type</th>
                    {/* <th>Property Address</th> */}
                    <th>Business Address</th>
                    <th>Action</th>
                  </thead>
                  {loader ? (
                    <Skeleton rows={10} cols={9} />
                  ) : data?.data !== null &&
                    data?.data !== undefined &&
                    data?.data?.length > 0 ? (
                    data?.data?.map((itm, index) => {
                      const {
                        surveyId,
                        propertyCode,
                        propertyName,
                        ownerName,
                        ownerMobile,
                        propertyType,
                        finalBusinessAddress,
                        propertyAddress,
                        businessName
                      } = itm;

                      return (
                        <tr key={Math.random() * 999999999}>
                          <td>
                            {pageNo !== 1 ? (
                              <> {index + 1 + docPerPage * (pageNo - 1)}</>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          {/* <td>{propertyCode ? propertyCode : "-"}</td> */}
                          <td style={{ maxWidth: "15vw" }}>
                            {businessName ? businessName : "-"}
                          </td>
                          <td style={{ maxWidth: "15vw" }}>
                            {ownerName ? ownerName : "-"}
                          </td>
                          <td>{ownerMobile}</td>
                          <td style={{ maxWidth: "14vw" }}>{propertyType}</td>
                          {/* <td style={{ maxWidth: "18vw" }}>
                            {propertyAddress ? propertyAddress : "-"}
                          </td> */}
                          <td style={{ maxWidth: "20vw" }}>
                            {finalBusinessAddress}
                          </td>
                          <td>
                            <Icon.Eye
                              className="ms-2 icon"
                              size={24}
                              onClick={() =>
                                navigate("/dashboard/officer-view-survey", {
                                  state: surveyId,
                                })
                              }
                            />

                            <Icon.Trash
                              className="ms-2 icon"
                              size={24}
                              onClick={() => openDelete(itm)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container>
      <Modal
        size="sm"
        show={showDelete}
        onHide={() => closeDelete()}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h4 className="mb-0">
            <Icon.TrashFill size={20} className="me-2" />
            Delete Survey
          </h4>
        </Modal.Header>
        <Modal.Body>
          {/* <h5>Are sure about deleting the Bill ?</h5> */}
          <Form.Group className="m-2" controlId="formComment">
            <Form.Label>Enter Delete Reason:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter Delete Reason"
              value={deleteReason}
              onChange={(e) => setDeleteReason(e.target.value)}
            />
            {error.deleteReason ? (
              <div style={{ color: "red" }}>{error.deleteReason}</div>
            ) : null}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {apiLoader ? (
            <Spinner variant="primary" />
          ) : (
            <>
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  closeDelete();
                }}
              >
                Cancel
              </Button>

              <Button className="btn btn-danger" onClick={() => DeleteSurvey()}>
                <Icon.Trash className="me-1" />
                Delete
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OffiecerManageSurveys;
