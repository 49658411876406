import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Button,
  Spinner,
  Col,
  Card,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

const ViewNotice = () => {
  const userState = useSelector((state) => state.surveylogin.userData?.data);

  const navigate = useNavigate();
  const location = useLocation();

  const noticeData = location.state;
  const [loader,setLoader] = useState(false)

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const createNotice = async () => {
    setLoader(true)
    let payload = {
      noticeGenerateId: userState.user_id,
      noticeGenerateMobile: userState.mobile,
      noticeGenerateName: userState.name,
      userRole: userState.userType_keyname,
      initials: "ADM",
      surveyId: noticeData.surveyId,
    };

    await fetch(process.env.REACT_APP_BASE_URL + "/notice/createNoticePDF", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoader(false)
        if (response.status === 200) {
            navigate(-1)
        }
      })
      .catch((error) => {toast.error(error) ;setLoader(false)});
  };

  return (
    <div className="outletPadding">
      <Container>
        <Row className="justify-content-between">
          <Col md={3}>
            <Button className="primaryBtn" onClick={() => navigate(-1)}>
              <Icon.ArrowLeft className="me-2" />
              Back
            </Button>
          </Col>
          <Col md={3} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            {
                loader ? <Spinner /> : <Button className="primaryBtn " onClick={() => createNotice()}>
                Create Notice
              </Button>
            }
          </Col>
        </Row>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.FileText className="me-1" /> Notice Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <thead>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Property Code
                  </th>
                  <td>
                    {noticeData?.propertyCode ? noticeData?.propertyCode : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    {" "}
                    <Icon.Bullseye className="me-2" />
                   Tax Zone
                  </th>
                  <td>{noticeData?.zonename + " - " + noticeData?.zoneno}</td>
                </tr>

                <tr>
                  <th>
                    {" "}
                    <Icon.Bullseye className="me-2" />
                   Owner Zone
                  </th>
                  <td>{noticeData?.surveyZoneName + " - " + noticeData?.surveyZoneKey}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Bullseye className="me-2" />
                    Gat
                  </th>
                  <td>{noticeData?.gatname}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Building className="me-2" />
                    Building Floors
                  </th>
                  <td>{noticeData?.buildingFloors}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Clock className="me-2" />
                    Business Start Date
                  </th>
                  <td>{noticeData?.businessStartDate}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Building className="me-2" />
                    Property Type
                  </th>
                  <td>{noticeData?.propertyType}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Building className="me-2" />
                    Sub Property Type
                  </th>
                  <td>{noticeData?.subPropertyType}</td>
                </tr>

                {noticeData?.subPropertyType === "इतर" && (
                  <tr>
                    <th>
                      <Icon.Building className="me-2" />
                      Sub Property Type
                    </th>
                    <td>{noticeData?.subPropertyType}</td>
                  </tr>
                )}

                <tr>
                  <th>
                    <Icon.Building className="me-2" />
                    Property Name
                  </th>
                  <td>{noticeData?.propertyName}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.GeoAlt className="me-2" />
                    Property Address
                  </th>
                  <td>{noticeData?.propertyAddress}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.GeoAlt className="me-2" />
                    Business Address
                  </th>
                  <td>{noticeData?.businessAddress}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.GeoAlt className="me-2" />
                    Final Business Address
                  </th>
                  <td>{noticeData?.finalBusinessAddress}</td>
                </tr>

                <tr>
                  <th>
                    {" "}
                    <Icon.Person className="me-2" />
                    Owner Name
                  </th>
                  <td>{noticeData?.ownerName}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Telephone className="me-2" />
                    Owner Mobile
                  </th>
                  <td>{noticeData?.ownerMobile}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Envelope className="me-2" />
                    Owner Email
                  </th>
                  <td>
                    {noticeData?.ownerEmail ? noticeData?.ownerEmail : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    {" "}
                    <Icon.Person className="me-2" />
                    Rental Person Name
                  </th>
                  <td>{noticeData?.rentedPersonName}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Telephone className="me-2" />
                    Rental Person Mobile
                  </th>
                  <td>{noticeData?.rentedPersonMobile}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.ArrowRight className="me-2" />
                    Property Area Foot
                  </th>
                  <td>
                    {noticeData?.propertyAreaFoot
                      ? noticeData?.propertyAreaFoot
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.ArrowRight className="me-2" />
                    Property Area Meter
                  </th>
                  <td>
                    {noticeData?.propertyAreaMeter
                      ? noticeData?.propertyAreaMeter
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Building className="me-2" />
                    Property Floor
                  </th>
                  <td>
                    {noticeData?.propertyFloor
                      ? noticeData?.propertyFloor
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Fire className="me-2" />
                    Fire Extinguisher Equipments
                  </th>
                  <td>
                    {noticeData?.fireExtinguisherEquipments
                      ? noticeData?.fireExtinguisherEquipments
                          ?.map((itm) => itm)
                          .join(", ")
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Person className="me-2" />
                    Survey Person Name
                  </th>
                  <td>{noticeData?.surveyByName}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Telephone className="me-2" />
                    Survey Person Mobile
                  </th>
                  <td>{noticeData?.surveyByMobile}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Outside Image
                  </th>
                  <td>
                    {noticeData?.outsideImage?.length > 0
                      ? noticeData?.outsideImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt="Outside"
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Inside Image
                  </th>
                  <td>
                    {noticeData?.insideImage?.length > 0
                      ? noticeData?.insideImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt="Inside"
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Satha Parvana Image
                  </th>
                  <td>
                    {noticeData?.sathaParvanaImage?.length > 0
                      ? noticeData?.sathaParvanaImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt="Satha Parvana"
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Satha ShopAct License Image
                  </th>
                  <td>
                    {noticeData?.sathaShopActLicenseImage?.length > 0
                      ? noticeData?.sathaShopActLicenseImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Business Parvana Image
                  </th>
                  <td colSpan={9}>
                    {noticeData?.businessParvanaImage?.length > 0
                      ? noticeData?.businessParvanaImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <div>
                      <Icon.CardImage className="me-2" /> Business ShopAct
                      License Image
                    </div>
                  </th>
                  <td colSpan={9}>
                    {noticeData?.businessShopActLicenseImage?.length > 0
                      ? noticeData?.businessShopActLicenseImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Food License Image
                  </th>
                  <td colSpan={9}>
                    {noticeData?.isFoodLicenseImage?.length > 0
                      ? noticeData?.isFoodLicenseImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Fire NOC Image
                  </th>
                  <td colSpan={9}>
                    {noticeData?.fireNOCImage?.length > 0
                      ? noticeData?.fireNOCImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Fire Annual Image
                  </th>
                  <td>
                    {noticeData?.fireAnnualImage?.length > 0
                      ? noticeData?.fireAnnualImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Fire Safety Certificate Image
                  </th>
                  <td colSpan={9}>
                    {noticeData?.fireSafetyCertificateImage?.length > 0
                      ? noticeData?.fireSafetyCertificateImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Electric Meter Image
                  </th>
                  <td colSpan={9}>
                    {noticeData?.electricMeterImage?.length > 0
                      ? noticeData?.electricMeterImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Fire Ext. Equipment Image
                  </th>
                  <td colSpan={9}>
                    {noticeData?.fireExtinguisherEquipmentImage?.length > 0
                      ? noticeData?.fireExtinguisherEquipmentImage?.map(
                          (itm) => {
                            return (
                              <img
                                src={itm}
                                width={80}
                                height={100}
                                alt=""
                                onClick={() => openImageInNewTab(itm)}
                              />
                            );
                          }
                        )
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Hazardous Equipment Image
                  </th>
                  <td colSpan={9}>
                    {noticeData?.hazardousEquipmentImage?.length > 0
                      ? noticeData?.hazardousEquipmentImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Attic Image
                  </th>
                  <td colSpan={9}>
                    {noticeData?.atticImage?.length > 0
                      ? noticeData?.atticImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Basement Image
                  </th>
                  <td>
                    {noticeData?.basementImage?.length > 0
                      ? noticeData?.basementImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.CardImage className="me-2" />
                    Residential Image
                  </th>
                  <td>
                    {noticeData?.residentialImage?.length > 0
                      ? noticeData?.residentialImage?.map((itm) => {
                          return (
                            <img
                              src={itm}
                              width={80}
                              height={100}
                              alt=""
                              onClick={() => openImageInNewTab(itm)}
                            />
                          );
                        })
                      : "-"}
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewNotice;
