import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import useGetBlockNo from "../Common/ReuseAPI/useGetBlockNo";
import useGetZoneName from "../Common/ReuseAPI/useGetZoneName";
import useGetOfficerList from "../Common/ReuseAPI/useGetOfficerList";

const DownloadMergePdf = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();
  const [loaderArray, setLoaderArray] = useState([]);

  // filter states
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [blockNo, setBlockNo] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [officer, setOfficer] = useState("");

  // filter error
  const [blockErr, setBlockErr] = useState(false);
  const [zoneErr, setZoneErr] = useState(false);
  const [officerErr, setOfficerErr] = useState(false);

  // Reuse API
  const { blockNoList } = useGetBlockNo();
  const { zoneNameList } = useGetZoneName();
  const { officerList } = useGetOfficerList();
  // States for Mobile search filter ----------------
  const [searchMobile, setSearchMobile] = useState("");
  const [searchMobileErr, setSearchMobileErr] = useState(false);

  const userState = useSelector((state) => state.surveylogin.userData.data);
  const navigate = useNavigate();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect( () => {
    setTimeout( () => {
      getMergePDFsData()
    },[3000] )
  },[] )

  useEffect(() => {
    getMergePDFsData();
  }, [docPerPage, pageNo]);

  const getMergePDFsData = async () => {
    setLoader(true);
    let payload = {
      noticeGnrtPrsnMobile: searchMobile,
      newBlock: blockNo,
      surveyZoneKey: "",
      documentsPerPage: docPerPage,
      page: pageNo,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "/newNoticeRoute/getMergePDFs",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if(response.status === 200){
          setData(response);
          let arr = [];
          response?.data?.map((row) => {
            arr.push(false);
          });
          setLoaderArray(arr);
          setNoOfPages(response.noOfPages);
          setLoader(false);
        }
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  // Search by Date function
  const searchByDate = async () => {
    if (startdate && enddate) {
      getMergePDFsData();
    } else {
      !startdate && toast.error("Please Select From Date");
      !enddate && toast.error("Please Select To Date");
    }
  };

  // Search by block and zone
  const searchByBlockZoneValidate = () => {
    let isValid = true;
    if (!zoneName) {
      isValid = false;
      setZoneErr(true);
    } else if (!blockNo) {
      isValid = false;
      setBlockErr(true);
    }
    return isValid;
  };

  const searchByBlockZone = async () => {
    if (searchByBlockZoneValidate()) {
      await getMergePDFsData();
    }
  };

  const downloadNotice = (notice, index) => {
    let arr = [...loaderArray];
    arr[index] = true;
    setLoaderArray(arr);

    fetch(notice.mergePDF)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `Merge_${notice.mergeId}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        let arr = [...loaderArray];
        arr[index] = false;
        setLoaderArray(arr);
      });
  };

  // --------------- Search Survey By Mobile ------------------------------
  const searchByValidate = () => {
    let isValid = true;
    if (!searchMobile || searchMobile.length < 10) {
      isValid = false;
      setSearchMobileErr(true);
    }
    return isValid;
  };

  const searchByMobile = async () => {
    if (searchByValidate()) {
      getMergePDFsData();
    }
  };

  return (
    <div className="outletPadding">
      <ToastContainer />
      <Container>
        <Row className="justify-content-center">
        <Col md={4}>
            <Card className="searchCard mt-3">
              <Form.Label className="searchLabel">Search by Mobile</Form.Label>{" "}
              <Form.Label>Mobile Number</Form.Label>
            <div className='d-flex mb-3'>
                  
                  <Form.Control placeholder='Enter Mobile No.' value={searchMobile} className='me-3'
                    onChange={(e)=>{ 
                      if(e.target.value.match(/^[0-9]+$/) && e.target.value.length <= 10){
                        setSearchMobile(e.target.value)
                      }
                      else if (e.target.value.length === 0){
                        setSearchMobile(e.target.value)
                      }
                  e.target.value.length>0 ? setSearchMobileErr(false): setSearchMobileErr(true); }} />
                  <Button className='primaryBtn' onClick={searchByMobile}>
                      <Icon.Search className='me-2' size={15}/>Search
                  </Button>
                  </div>
              { searchMobileErr ? <p className='errMsg'>Please enter valid mobile no.</p> : '' }
            </Card>
          </Col>

          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Block</Form.Label>{" "}
              <div className="d-flex align-items-start">
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>Zone Name</Form.Label>
                  <Form.Select
                    value={zoneName}
                    onChange={(e) => {
                      setZoneName(e.target.value);
                      setBlockNo("");
                      e.target.value.length > 0 && setZoneErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Zone
                    </option>
                    {zoneNameList !== null &&
                    zoneNameList !== undefined &&
                    zoneNameList.length > 0 ? (
                      zoneNameList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.zonename}>
                            {itm?.zonename}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {zoneErr ? <p className="errMsg">Please select zone</p> : ""}
                </Col>

                <Col className="m-2 d-flex flex-column">
                  <Form.Label>Block</Form.Label>
                  <Form.Select
                    disabled={!zoneName}
                    value={blockNo}
                    onChange={(e) => {
                      setBlockNo(e.target.value);
                      e.target.value.length > 0 && setBlockErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Block
                    </option>
                    {blockNoList !== null &&
                    blockNoList !== undefined &&
                    blockNoList.length > 0 ? (
                      blockNoList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.blockNo}>
                            {itm?.blockNo}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {blockErr ? (
                    <p className="errMsg">Please select block</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col className="m-2 d-flex align-items-end">
                  <Button
                    className="primaryBtn p-2 mt-4"
                    onClick={searchByBlockZone}
                  >
                    <Icon.Search size={18} className="me-2" />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>
        </Row>
        <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <div className="table-wrapper mt-2" style={{ maxHeight: "58vh" }}>
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    <th>Generate Person Name</th>
                    <th>Generate Person Mobile</th>
                    <th>Notice Generete Date</th>
                    <th>Blocks</th>
                    <th>Notice Number</th>
                    <th>Action</th>
                  </thead>
                  {loader ? (
                    <Skeleton rows={10} cols={9} />
                  ) : data?.data !== null &&
                    data?.data !== undefined &&
                    data?.data?.length > 0 ? (
                    data?.data?.map((itm, index) => {
                      return (
                        <tr key={index} >
                          <td>{index + 1 + docPerPage * (pageNo - 1)}</td>
                          <td>
                            {itm?.noticeGnrtPrsnName
                              ? itm?.noticeGnrtPrsnName
                              : "-"}
                          </td>
                          <td>
                            {itm?.noticeGnrtPrsnMobile
                              ? itm?.noticeGnrtPrsnMobile
                              : "-"}
                          </td>
                          <td>
                            {itm?.mergeDate
                              ? moment(itm?.mergeDate).format("DD-MM-YYYY hh:mm a")
                              : "-"}
                          </td>
                          <td>
                            {itm?.newBlockArr.length > 0
                              ? itm?.newBlockArr.toString()
                              : "-"}
                          </td>
                          <td>
                            {itm?.userInputNoticeNo
                              ? itm?.userInputNoticeNo
                              : "-"}
                          </td>
                          <td className="d-flex align-items-center">
                            {loaderArray[index] ? (
                              <Spinner />
                            ) : (
                              <Button
                                className="primaryBtn ms-2"
                                onClick={() => {
                                  downloadNotice(itm, index);
                                }}
                              >
                                Download Notice
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container>
    </div>
  );
};

export default DownloadMergePdf;
