import React, { useEffect, useState } from 'react'
import { Container, Row, Button, Spinner, Col, Card, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Skeleton from '../Common/Skeleton';

const ViewSurvey = () => {
    
    const userState = useSelector(state => state.surveylogin.userData?.data);
    
    const navigate = useNavigate();
    const location = useLocation();
    const [verifications, setVerifications] = useState('');
    const [verifyLoader, setVertifyLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [archLoader, setArchLoader] = useState(false);
    const [archives, setArchives] = useState('');
    // const [currentArchive, setCurrentArchive] = useState('');
    const [currentSurvey, setCurrentSurvey] = useState('');
    const [activeSurvey, setActiveSurvey] = useState('');
    const [activeId, setActiveId]= useState('');


    useEffect(()=>{
        getSurveyById();
        getSurveyArchives();
        getDropdownValuesBySurveyId();
    },[])

 
    const getDropdownValuesBySurveyId =async()=>{
        setVertifyLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/dropdownValues/getDropdownValuesBySurveyId',{
            method: "POST",
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ 
                propertyCode : '',
                user_id : userState?._id,
                surveyId :location?.state
            })
        }).then((res)=>res.json()).then((response)=>{
            setVerifications(response?.verificationDetails);
            setVertifyLoader(false);
        }).catch((Err)=>{
            console.log('Err while getting Dropdown Values B ySurveyId', Err);
            setVertifyLoader(false);
        })
    }

    const getSurveyArchives = async()=>{
        setArchLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/getSurveyArchives',{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ surveyId : location?.state})
        }).then((res)=>res.json()).then((response)=>{
            setArchives(response?.data);
            setArchLoader(false);
        }).catch((Err)=>{
            console.log('Err while getting currentSurvey archives', Err);
            setArchLoader(false);
        })
    }
    
    const getSurveyById= async ()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/surveys/getSurveyBySurveyId2', {
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ surveyId : location?.state })
        }).then((res)=>res.json()).then((response)=>{
            setCurrentSurvey(response?.data);
            setActiveSurvey(response?.data);
            setLoader(false);
        }).catch((err)=>{
            console.error('Error while view currentSurvey', err);
            setLoader(false);        
        })
    }

    const openImageInNewTab = (imageUrl) => {
        window.open(imageUrl, '_blank');
      };

  return (
    <div className='ViewSurvey outletPadding mb-5'>
        <Container>
            <Button className='primaryBtn' onClick={()=>navigate(-1)}>
                <Icon.ArrowLeft className='me-2'/>Back
            </Button>

            <Row className='verifications justify-content-center mt-3 mt-md-0 mb-4'>
                <h4 className='text-center'>
                    <Icon.Check2Circle className='me-2'/>Verification History
                </h4>
                
                { verifyLoader ? <center><Spinner variant='primary'/></center> :
                    verifications!==undefined && verifications!=null && verifications?.length>0 ?
                        verifications?.map((itm)=>{
                        return(
                            <Col md={4} className={verifications?.length>4 ? 'mb-3' : 'mb-3 mb-md-0'}>
                                <Card className='h-100'>
                                    <div className='d-flex'>
                                        <h6><Icon.Person className='me-2'/>Name : </h6>
                                        <p>{itm?.verifiedByName}</p>
                                    </div>
                                    <div className='d-flex'>
                                        <h6><Icon.CardHeading className='me-2'/>Reason : </h6>
                                        <p>{itm?.notVerifiedReason ? itm?.notVerifiedReason : '-' }</p>
                                    </div>
                                    <div className='d-flex'>
                                        <h6><Icon.Calendar2Check className='me-2'/>Date : </h6>
                                        <p>{moment(itm?.verifiedDate).format('DD-MM-YYYY')}</p>
                                    </div>
                                    <p className='role'>{itm?.verifiedByRole === 'fireofficer' ? 'Fire Officer' : 'Mavim'}</p>
                                    </Card>
                            </Col>
                        )
                    })
                    : <p className='text-center text-danger'>No Verification history available</p>
                }
            </Row>

            <Row className='mb-2 mt-4'>
                <h4 className='mb-0 text-center'>
                <Icon.Clipboard2Data className='me-1'/> Survey Details</h4>
            </Row>

            <Row className='d-flex mx-2 mt-3'>
                <button className={ activeSurvey?._id === currentSurvey?._id  ? 'archiveBtn activeArchiveBtn me-3' :
                        'archiveBtn nonActiveArchiveBtn me-3'} 
                         onClick={()=>{ setActiveSurvey(currentSurvey); setActiveId(currentSurvey?._id) }}>
                        {activeSurvey?._id === currentSurvey?._id  ? <Icon.ClockFill className='me-2'/> : <Icon.Clock className='me-2'/> }
                        Current Survey
                </button>

                {archives!==undefined && archives!==null && archives.length > 0
                 
                    && archives?.map((itm, index) => (
                    <button key={itm.surveyId} className={ activeId === itm?._id ? 'archiveBtn activeArchiveBtn me-3' : ' archiveBtn nonActiveArchiveBtn me-3'}
                    onClick={() => { setActiveSurvey(itm); setActiveId(itm?._id)}}> 
                       {activeId === itm?._id ? <Icon.ClockFill className='me-2'/> : <Icon.Clock className='me-2'/>} {itm.archiveDate}
                  </button>
                ))}
            </Row>

            {/* <ButtonGroup>
                        { archives!==undefined && archives!==null && archives.length>0 
                            && archives?.map((itm, idx) => (
                            <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    className={currentArchive?.archiveDate === itm?.archiveDate ? 'activeArchive' :'nonActiveArchive'}
                                    name="radio"
                                    value={itm}
                                    checked={currentArchive?.archiveDate === itm?.archiveDate}
                                    onChange={(e) =>{ console.log('itm', itm); setCurrentArchive(e.target.value); } }>
                                    {itm?.archiveDate}
                                </ToggleButton>
                            ))}
            </ButtonGroup> */}

            {
                loader ? <center><Spinner variant='primary mt-3'/></center> :
                <Row className="outer-wrapper mx-auto mt-4">
                <div className="table-wrapper">
                        <table data-aos="fade-up" data-aos-delay="200">
                            <tbody>
                                <tr>
                                    <th><Icon.Code className='me-2'/>Property Code</th>
                                    <td>{activeSurvey?.propertyCode ? activeSurvey?.propertyCode :'-' }</td>
                                </tr>

                                <tr>
                                    <th> <Icon.Bullseye className='me-2'/>Zone</th>
                                    <td>{activeSurvey?.zonename+' - '+activeSurvey?.zoneno}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Bullseye className='me-2'/>Gat</th>
                                    <td>{activeSurvey?.gatname}</td>
                                </tr>
                                
                                <tr>
                                <th><Icon.Building className='me-2'/>Building Floors</th>
                                    <td>{activeSurvey?.buildingFloors}</td>
                                </tr>
                                
                                <tr>
                                <th><Icon.Clock className='me-2'/>Business Start Date</th>
                                    <td>{activeSurvey?.businessStartDate}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Building className='me-2'/>Property Type</th>
                                    <td>{activeSurvey?.propertyType}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Building className='me-2'/>Sub Property Type</th>
                                    <td>{activeSurvey?.subPropertyType}</td>
                                </tr>

                                { activeSurvey?.subPropertyType === "इतर" &&
                                    <tr>
                                        <th><Icon.Building className='me-2'/>Sub Property Type</th>
                                        <td>{activeSurvey?.subPropertyType}</td>
                                    </tr>
                                }

                                <tr>
                                    <th><Icon.Building className='me-2'/>Property Name</th>
                                    <td>{activeSurvey?.propertyName}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Property Address</th>
                                    <td>{activeSurvey?.propertyAddress}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Business Address</th>
                                    <td >{activeSurvey?.businessAddress}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Final Business Address</th>
                                    <td >{activeSurvey?.finalBusinessAddress}</td>
                                </tr>
                                
                                <tr>
                                    <th > <Icon.Person className='me-2'/>Owner Name</th>
                                    <td>{activeSurvey?.ownerName}</td>
                                </tr>
                                
                                <tr>
                                <th><Icon.Telephone className='me-2'/>Owner Mobile</th>
                                    <td>{activeSurvey?.ownerMobile}</td>
                                </tr>
                                <tr>
                                    <th><Icon.Envelope className='me-2'/>Owner Email</th>
                                    <td>{activeSurvey?.ownerEmail ? activeSurvey?.ownerEmail  :'-' }</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.ArrowRight className='me-2'/>Property Area Foot</th>
                                    <td>{activeSurvey?.propertyAreaFoot ? activeSurvey?.propertyAreaFoot : '-'}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.ArrowRight className='me-2'/>Property Area Meter</th>
                                    <td>{activeSurvey?.propertyAreaMeter ? activeSurvey?.propertyAreaMeter :'-'}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.Building className='me-2'/>Property Floor</th>
                                    <td>{activeSurvey?.propertyFloor ? activeSurvey?.propertyFloor :'-'}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.Fire className='me-2'/>Fire Extinguisher Equipments</th>
                                    <td>{activeSurvey?.fireExtinguisherEquipments ? activeSurvey?.fireExtinguisherEquipments?.map((itm)=>itm).join(', ') :'-'}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.Person className='me-2'/>Survey Person Name</th>
                                    <td>{activeSurvey?.surveyByName}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Telephone className='me-2'/>Survey Person Mobile</th>
                                    <td>{activeSurvey?.surveyByMobile}</td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Outside Image</th>
                                    <td>{
                                    activeSurvey?.outsideImage?.length > 0 ? 
                                    activeSurvey?.outsideImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Outside"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Inside Image</th>
                                    <td>{
                                        activeSurvey?.insideImage?.length > 0 ? 
                                        activeSurvey?.insideImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Inside"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Satha Parvana Image</th>
                                    <td>{
                                        activeSurvey?.sathaParvanaImage?.length > 0 ? 
                                        activeSurvey?.sathaParvanaImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Satha Parvana"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Satha ShopAct License Image</th>
                                    <td>{
                                        activeSurvey?.sathaShopActLicenseImage?.length > 0 ? 
                                         activeSurvey?.sathaShopActLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Business Parvana Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.businessParvanaImage?.length > 0 ? 
                                         activeSurvey?.businessParvanaImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        <div>
                                         <Icon.CardImage className='me-2'/> Business ShopAct License Image
                                        </div>
                                    </th>
                                    <td colSpan={9}>{
                                        activeSurvey?.businessShopActLicenseImage?.length > 0 ? 
                                         activeSurvey?.businessShopActLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Food License Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.isFoodLicenseImage?.length > 0 ? 
                                         activeSurvey?.isFoodLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire NOC Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.fireNOCImage?.length > 0 ? 
                                         activeSurvey?.fireNOCImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="" 
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Annual Image</th>
                                    <td>{
                                        activeSurvey?.fireAnnualImage?.length > 0 ? 
                                         activeSurvey?.fireAnnualImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Safety Certificate Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.fireSafetyCertificateImage?.length > 0 ? 
                                         activeSurvey?.fireSafetyCertificateImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Electric Meter Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.electricMeterImage?.length > 0 ? 
                                         activeSurvey?.electricMeterImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Ext. Equipment Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.fireExtinguisherEquipmentImage?.length > 0 ? 
                                         activeSurvey?.fireExtinguisherEquipmentImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Hazardous Equipment Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.hazardousEquipmentImage?.length > 0 ? 
                                         activeSurvey?.hazardousEquipmentImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Attic Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.atticImage?.length > 0 ? 
                                         activeSurvey?.atticImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Basement Image</th>
                                    <td>{
                                        activeSurvey?.basementImage?.length > 0 ? 
                                         activeSurvey?.basementImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Residential Image</th>
                                    <td>{
                                        activeSurvey?.residentialImage?.length > 0 ? 
                                         activeSurvey?.residentialImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                               
                            </tbody>
                        </table>
              </div>
                </Row>
            }

            {/* {
                loader ? <center><Spinner variant='primary mt-4'/></center> :
                <Row className='justify-content-center mt-4' data-aos="fade-up" data-aos-delay='100'>
                <Col md={10}>
                    <Card>
                        <Row className='d-flex flex-column flex-md-row'>
                            <div className='keys'>
                                <div className='value'>
                                    <Icon.Code className='me-2'/><h6>Property Code - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Building className='me-2'/><h6>Property Name - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.GeoAlt className='me-2'/><h6>Property Address - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Bullseye className='me-2'/><h6>Zone - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Bullseye className='me-2'/><h6>Gat - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Building className='me-2'/><h6>Building Floors - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.GeoAlt className='me-2'/><h6>Business Address - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Clock className='me-2'/><h6>Business Start Date - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.GeoAlt className='me-2'/><h6>Final Business Address - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Toggle2On className='me-2'/><h6>Rental Status - </h6>
                                </div> <div className='value'>
                                    <Icon.Person className='me-2'/><h6>Owner Name - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Telephone className='me-2'/><h6>Owner Mobile - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.ArrowRight className='me-2'/><h6>Property Area Foot - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.ArrowRight className='me-2'/><h6>Property Area Meter - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Building className='me-2'/><h6>Property Floor - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.GeoAlt className='me-2'/><h6>Property WorkPlace - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Fire className='me-2'/><h6>Fire Extinguisher Equipments - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Building className='me-2'/><h6>Sub Property Type - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Person className='me-2'/><h6>Survey Person Name  - </h6>
                                </div>
                                <div className='value'>
                                    <Icon.Telephone className='me-2'/><h6>Survey Person Mobile  - </h6>
                                </div>
                            </div>
                            <Col md={6}>
                                <p>{currentSurvey?.propertyCode}</p>
                                <p>{currentSurvey?.propertyName}</p>
                                <p>{currentSurvey?.propertyAddress}</p>
                                <p>{currentSurvey?.zonename+' - '+currentSurvey?.zoneno}</p>
                                <p>{currentSurvey?.gatname}</p>
                                <p>{currentSurvey?.buildingFloors}</p>
                                <p>{currentSurvey?.businessAddress}</p>
                                <p>{currentSurvey?.businessStartDate}</p>
                                <p>{currentSurvey?.finalBusinessAddress}</p>
                                <p>{currentSurvey?.ownerName}</p>
                                <p>{currentSurvey?.ownerMobile}</p>
                                <p>{currentSurvey?.propertyAreaFoot ? currentSurvey.propertyAreaFoot :'-'}</p>
                                <p>{currentSurvey?.propertyAreaMeter ? currentSurvey.propertyAreaMeter :'-'}</p>
                                <p>{currentSurvey?.propertyFloor}</p>
                                <p>{currentSurvey?.propertyWorkPlace}</p>
                                <p>{currentSurvey?.fireExtinguisherEquipments?.map((itm)=>itm)}</p>
                                <p>{currentSurvey?.subPropertyType}</p>
                                <p>{currentSurvey?.currentSurveyByName}</p>
                                <p>{currentSurvey?.currentSurveyByMobile}</p>
                            </Col>
                        </Row>      
                    </Card>
                </Col>
                </Row> 
            } */}
           
        </Container>
    </div>
  )
}

export default ViewSurvey;