import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import ReactS3 from "../../Utils/ReactS3";
window.Buffer = window.Buffer || require("buffer").Buffer;
export default function Formthree() {
  const [label, setlabel] = useState("");
  const [dropdown, setdropdown] = useState("");
  const [loader, setloader] = useState(false);
  const [isFireNOC, setisFireNOC] = useState("");
  const [fireNOCNumber, setfireNOCNumber] = useState("");
  const [fireNOCDate, setfireNOCDate] = useState("");
  const [fireNOCImage, setfireNOCImage] = useState([]);
  const [fireAnnualNumber, setfireAnnualNumber] = useState("");
  const [isFireAnnualRenewal, setisFireAnnualRenewal] = useState("");
  const [fireAnnualDate, setfireAnnualDate] = useState("");
  const [fireAnnualImage, setfireAnnualImage] = useState([]);
  const [isFireSafetyCertificate, setisFireSafetyCertificate] = useState("");
  const [fireSafetyCertificateDate, setfireSafetyCertificateDate] =
    useState("");
  const [fireSafetyCertificateImage, setfireSafetyCertificateImage] = useState(
    []
  );
  const [isFireExtinguisherEquipment, setisFireExtinguisherEquipment] =
    useState("");
  const [fireSafetyCertificateNumber, setfireSafetyCertificateNumber] =
    useState("");
  const [fireExtinguisherEquipments, setfireExtinguisherEquipments] =
    useState("");
  const [fireExtinguisherEquipmentsOther, setfireExtinguisherEquipmentsOther] =
    useState("");
  const [fireExtinguisherEquipmentImage, setfireExtinguisherEquipmentImage] =
    useState([]);
  const [electricMeter, setelectricMeter] = useState("");
  const [electricMeterNumber, setelectricMeterNumber] = useState("");
  const [electricMeterImage, setelectricMeterImage] = useState("");
  const [isHazardous, setisHazardous] = useState("");
  const [hazardousEquipment, sethazardousEquipment] = useState("");
  const [hazardousEquipmentOther, sethazardousEquipmentOther] = useState("");
  const [hazardousEquipmentImage, sethazardousEquipmentImage] = useState([]);
  const [isAllowedInside, setisAllowedInside] = useState("");
  const [outsideImage, setoutsideImage] = useState("");
  const [insideImage, setinsideImage] = useState([]);
  const [isAttic, setisAttic] = useState("");
  const [atticInfo, setatticInfo] = useState("");
  const [atticInfoOther, setatticInfoOther] = useState("");
  const [atticImage, setatticImage] = useState([]);
  const [isBasement, setisBasement] = useState("");
  const [basementInfo, setbasementInfo] = useState("");
  const [basementInfoOther, setbasementInfoOther] = useState("");
  const [basementImage, setbasementImage] = useState("");
  const [isResidentialPurpose, setisResidentialPurpose] = useState("");
  const [residentialPurposes, setresidentialPurposes] = useState([]);
  const [residentialPurposesOther, setresidentialPurposesOther] = useState("");
  const [residentialImage, setresidentialImage] = useState([]);
  const [lat, setlat] = useState("");
  const [long, setlong] = useState("");
  const [surveyById, setsurveyById] = useState("");
  const [surveyByName, setsurveyByName] = useState("");
  const [surveyByMobile, setsurveyByMobile] = useState("");
  const [version, setversion] = useState("");
  const getLebal = async () => {
    setloader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/labels/getLabels")
      .then((res) => {
        if (res.data.data) {
          setlabel(res.data.data);
          //getdropdownvalue()
          getIntialValue();
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getIntialValue = async () => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/getSurveyBySurveyId", {
        surveyId: "SURVEY_1110100008_042023032024_9359330702",
      })
      .then((res) => {
        setloader(false);
        setdropdown(res.data.dropdownValues);
        if (res.data.page3) {
          const e = res.data.page3;

          setisFireNOC(e.isFireNOC);
          setfireNOCNumber(e.fireNOCNumber);
          setfireNOCDate(e.fireNOCDate);
          setfireNOCImage(e.fireNOCImage);
          setfireAnnualNumber(e.fireAnnualNumber);
          setisFireAnnualRenewal(e.isFireAnnualRenewal);
          setfireAnnualDate(e.fireAnnualDate);
          setfireAnnualImage(e.fireAnnualImage);
          setisFireSafetyCertificate(e.isFireSafetyCertificate);
          setfireSafetyCertificateDate(e.fireSafetyCertificateDate);
          setfireSafetyCertificateImage(e.fireSafetyCertificateImage);
          setisFireExtinguisherEquipment(e.isFireExtinguisherEquipment);
          setfireSafetyCertificateNumber(e.fireSafetyCertificateNumber);
          setfireExtinguisherEquipments(
            e.fireExtinguisherEquipments ? e.fireExtinguisherEquipments : []
          );
          setfireExtinguisherEquipmentsOther(e.fireExtinguisherEquipmentsOther);
          setfireExtinguisherEquipmentImage(e.fireExtinguisherEquipmentImage);
          setelectricMeterNumber(e.electricMeterNumber);
          setelectricMeterImage(e.electricMeterImage);
          setisHazardous(e.isHazardous);
          sethazardousEquipment(
            e.hazardousEquipment ? e.hazardousEquipment : []
          );
          sethazardousEquipmentOther(e.hazardousEquipmentOther);
          sethazardousEquipmentImage(e.hazardousEquipmentImage);
          setisAllowedInside(e.isAllowedInside);
          setoutsideImage(e.outsideImage);
          setisAttic(e.isAttic);
          setatticInfo(e.atticInfo ? e.atticInfo : []);
          setatticInfoOther(e.atticInfoOther);
          setatticImage(e.atticImage);
          setisBasement(e.isBasement);
          setbasementInfo(e.basementInfo ? e.basementInfo : []);
          setbasementImage(e.basementImage);
          setisResidentialPurpose(e.isResidentialPurpose);
          setresidentialPurposes(
            e.residentialPurposes ? e.residentialPurposes : []
          );
          setresidentialPurposesOther(e.residentialPurposesOther);
          setresidentialImage(e.residentialImage);
          setlat(e.lat);
          setlong(e.long);
          setsurveyById(e.surveyById);
          setsurveyByName(e.surveyByName);
          setsurveyByMobile(e.surveyByMobile);
          setversion(e.version);
        }
      })
      .catch((err) => {
        setloader(false);
      });
  };
  const getdropdownvalue = async () => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/dropdownValues/getAllDropdownValues",
        {
          propertyCode: "1020100097.00",
          user_id: "",
        }
      )
      .then((res) => {
        if (res.data.dropdownValues) {
          setloader(false);
          setdropdown(res.data.dropdownValues);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const Updatepage3 = async (e) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/updateSurveyPage3", {
        isFireNOC: isFireNOC,
        fireNOCNumber: fireNOCNumber,
        fireNOCDate: fireNOCDate,
        fireNOCImage: fireNOCImage,
        isFireAnnualRenewal: isFireAnnualRenewal,
        fireAnnualNumber: fireAnnualNumber,
        fireAnnualDate: fireAnnualDate,
        fireAnnualImage: fireAnnualImage,
        isFireSafetyCertificate: isFireSafetyCertificate,
        fireSafetyCertificateDate: fireSafetyCertificateDate,
        fireSafetyCertificateImage: fireSafetyCertificateImage,
        isFireExtinguisherEquipment: isFireExtinguisherEquipment,
        fireSafetyCertificateNumber: fireSafetyCertificateNumber,
        fireExtinguisherEquipments: fireExtinguisherEquipments,
        fireExtinguisherEquipmentsOther: fireExtinguisherEquipmentsOther,
        fireExtinguisherEquipmentImage: fireExtinguisherEquipmentImage,
        electricMeter: electricMeter,
        electricMeterNumber: electricMeterNumber,
        electricMeterImage: electricMeterImage,
        isHazardous: isHazardous,
        hazardousEquipment: hazardousEquipment,
        hazardousEquipmentOther: hazardousEquipmentOther,
        hazardousEquipmentImage: hazardousEquipmentImage,
        isAllowedInside: isAllowedInside,
        outsideImage: outsideImage,
        insideImage: insideImage,
        isAttic: isAttic,
        atticInfo: atticInfo,
        atticInfoOther: atticInfoOther,
        atticImage: atticImage,
        isBasement: isBasement,
        basementInfo: basementInfo,
        basementInfoOther: basementInfoOther,
        basementImage: basementImage,
        isResidentialPurpose: isResidentialPurpose,
        residentialPurposes: residentialPurposes,
        residentialPurposesOther: residentialPurposesOther,
        residentialImage: residentialImage,
        lat: lat,
        long: long,
        surveyById: surveyById,
        surveyByName: surveyByName,
        surveyByMobile: surveyByMobile,
        version: version,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getLebal();
  }, []);
  const s3Data = {
    projectName: "itgoa",
    bucketName: "it-goa",
    region: "ap-south-1",
    accessKeyId: "AKIA4GWLDQ2LL4DLFWEF",
    secretAccessKey: "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",
    s3Url: "https://it-goa.s3.ap-south-1.amazonaws.com",
  };

  const handleProfileImg = async (e) => {
    let result = await ReactS3(e.target.files[0], s3Data, "citizen");
    return result;
  };
  return (
    <>
    <Row>
     <h1 className="text-center">Form 3</h1>
    </Row>
    <div style={{ padding: 20, display: "flex", justifyContent: "center" }}>
      {loader ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Form className="form-layout">
          <Form.Label className="Label-muted">
            {label?.page2?.isAllowedInside}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisAllowedInside(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isAllowedInside}
              inline
              label={"होय"}
              name="group1"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isAllowedInside}
              inline
              label={"नाही"}
              name="group1"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Text>{label?.page2?.outsideImage}</Form.Text>
          </Form.Group>

          <div className="mb-4">
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setoutsideImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {outsideImage && (
              <img
                src={outsideImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          {/* <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Text>{label?.page2?.insideImage}</Form.Text>
          </Form.Group> */}

          <Form.Label className="Label-muted">
            {label?.page2?.fireNOC}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisFireNOC(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isFireNOC}
              inline
              label={"होय"}
              name="group2"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isFireNOC}
              inline
              label={"नाही"}
              name="group2"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>
          <div
            className="mb-4"
            style={{ display: isFireNOC ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setfireNOCImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {fireNOCImage && (
              <img
                src={fireNOCImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <Form.Group
            className="mb-4"
            controlId="formBasicEmail"
            style={{ display: isFireNOC ? "block" : "none" }}
          >
            <Form.Label>{label?.page2?.fireNOCNumber}</Form.Label>
            <Form.Control
              onChange={(e) => {
                setfireNOCNumber(e.target.value);
              }}
              type="number"
              placeholder={label?.page2?.fireNOCNumber}
            />
          </Form.Group>
          <Form.Group
            style={{ display: isFireNOC ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setfireNOCDate(e.target.value);
            }}
          >
            <Form.Label>{label?.page2?.fireNOCDate}</Form.Label>
            <Form.Control type="date" placeholder={label?.page2?.fireNOCDate} />
          </Form.Group>

          <Form.Label className="Label-muted">
            {label?.page2?.fireSafetyCertificate}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisFireSafetyCertificate(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isFireSafetyCertificate}
              inline
              label={"होय"}
              name="group3"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isFireSafetyCertificate}
              inline
              label={"नाही"}
              name="group3"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <div
            className="mb-4"
            style={{ display: isFireSafetyCertificate ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setfireSafetyCertificateImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {fireSafetyCertificateImage && (
              <img
                src={fireSafetyCertificateImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <Form.Group
            style={{ display: isFireSafetyCertificate ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setfireSafetyCertificateNumber(e.target.value);
            }}
          >
            <Form.Label>{label?.page2?.fireSafetyCertificateNumber}</Form.Label>
            <Form.Control
              type="number"
              placeholder={label?.page2?.fireSafetyCertificateNumber}
            />
          </Form.Group>
          <Form.Group
            style={{ display: isFireSafetyCertificate ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setfireSafetyCertificateDate(e.target.value);
            }}
          >
            <Form.Label>{label?.page2?.fireSafetyCertificateDate}</Form.Label>
            <Form.Control
              type="date"
              placeholder={label?.page2?.fireSafetyCertificateDate}
            />
          </Form.Group>

          <Form.Label className="Label-muted">
            {label?.page2?.fireAnnual}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisFireAnnualRenewal(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isFireAnnualRenewal}
              inline
              label={"होय"}
              name="group4"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isFireAnnualRenewal}
              inline
              label={"नाही"}
              name="group4"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <div
            className="mb-4"
            style={{ display: isFireAnnualRenewal ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setfireAnnualImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {fireAnnualImage && (
              <img
                src={fireAnnualImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <Form.Group
            style={{ display: isFireAnnualRenewal ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setfireAnnualNumber(e.target.value);
            }}
          >
            <Form.Label>{label?.page2?.fireAnnualNumber}</Form.Label>
            <Form.Control
              type="number"
              placeholder={label?.page2?.fireAnnualNumber}
            />
          </Form.Group>
          <Form.Group
            style={{ display: isFireAnnualRenewal ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setfireAnnualDate(e.target.value);
            }}
          >
            <Form.Label>{label?.page2?.fireAnnualDate}</Form.Label>
            <Form.Control
              type="date"
              placeholder={label?.page2?.fireAnnualDate}
            />
          </Form.Group>
          <Form.Group
            style={{ display: isFireAnnualRenewal ? "block" : "none" }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setelectricMeterNumber(e.target.value);
            }}
          >
            <Form.Label>{label?.page2?.electricMeterNumber}</Form.Label>

            <Form.Control
              type="number"
              placeholder={label?.page2?.electricMeterNumber}
            />
          </Form.Group>

          <div
            className="mb-4"
            style={{
              display: isFireAnnualRenewal ? "block" : "none",
            }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setelectricMeterImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {electricMeterImage && (
              <img
                src={electricMeterImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>

          <Form.Label className="Label-muted">
            {label?.page2?.isResidentialPurpose}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisResidentialPurpose(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              inline
              checked={isResidentialPurpose}
              label={"होय"}
              name="group5"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isResidentialPurpose}
              inline
              label={"नाही"}
              name="group5"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <Form style={{ display: isResidentialPurpose ? "block" : "none" }}>
            <div
              key={"inline"}
              className="mb-3"
              onChange={(e) => {
                let array = [];
                if (residentialPurposes?.includes(e.target.id)) {
                  array = residentialPurposes?.filter(
                    (item) => item !== e.target.id
                  );
                } else {
                  array = [...residentialPurposes, e.target.id];
                }

                let uniqueArray = [...new Set(array)];

                setresidentialPurposes(uniqueArray);
              }}
            >
              {dropdown?.residentialPurposes?.map((item) => {
                return (
                  <Form.Check
                    label={item}
                    name="group1"
                    type={"checkbox"}
                    id={item}
                  />
                );
              })}
            </div>
          </Form>

          <Form.Group
            style={{
              display:
                isResidentialPurpose && residentialPurposes?.includes("इतर")
                  ? "block"
                  : "none",
            }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setresidentialPurposesOther(e.target.value);
            }}
          >
            <Form.Control type="number" placeholder={"कृपया उल्लेख करा ..."} />
          </Form.Group>

          <div
            className="mb-4"
            style={{ display: isResidentialPurpose ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setresidentialImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {setresidentialImage && (
              <img
                src={setresidentialImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <Form.Label className="Label-muted">
            {label?.page2?.fireExtinguisherEquipemnts}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisFireExtinguisherEquipment(
                e.target.id == "true" ? true : false
              );
            }}
          >
            <Form.Check
              checked={isFireExtinguisherEquipment}
              inline
              label={"होय"}
              name="group6"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isFireExtinguisherEquipment}
              inline
              label={"नाही"}
              name="group6"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <Form
            style={{ display: isFireExtinguisherEquipment ? "block" : "none" }}
            onChange={(e) => {
              let array = [];
              if (
                fireExtinguisherEquipments?.length > 0 &&
                fireExtinguisherEquipments?.includes(e.target.id)
              ) {
                array = fireExtinguisherEquipments?.filter(
                  (item) => item !== e.target.id
                );
              } else {
                array = [...fireExtinguisherEquipments, e.target.id];
              }

              let uniqueArray = [...new Set(array)];
              setfireExtinguisherEquipments(uniqueArray);
            }}
          >
            <div key={"inline"} className="mb-3">
              {dropdown?.fireExtinguisherEquipments?.map((item) => {
                return (
                  <Form.Check
                    label={item}
                    name="group1"
                    type={"checkbox"}
                    id={item}
                  />
                );
              })}
            </div>
          </Form>

          <Form.Group
            style={{
              display:
                isFireExtinguisherEquipment &&
                fireExtinguisherEquipments?.includes("इतर")
                  ? "block"
                  : "none",
            }}
            className="mb-4"
            controlId="formBasicEmail"
            onChange={(e) => {
              setfireExtinguisherEquipmentsOther(e.target.value);
            }}
          >
            <Form.Control type="number" placeholder={"कृपया उल्लेख करा ..."} />
          </Form.Group>
          <div
            className="mb-4"
            style={{
              display: isFireExtinguisherEquipment ? "block" : "none",
            }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setfireExtinguisherEquipmentImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {fireExtinguisherEquipmentImage && (
              <img
                src={fireExtinguisherEquipmentImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>

          <Form.Label className="Label-muted">
            {label?.page2?.hazardous}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisHazardous(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isHazardous}
              inline
              label={"होय"}
              name="group7"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isHazardous}
              inline
              label={"नाही"}
              name="group7"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <Form style={{ display: isHazardous ? "block" : "none" }}>
            <div
              key={"inline"}
              className="mb-3"
              onChange={(e) => {
                let array = [];
                if (hazardousEquipment.includes(e.target.id)) {
                  array = hazardousEquipment.filter(
                    (item) => item !== e.target.id
                  );
                } else {
                  array = [...hazardousEquipment, e.target.id];
                }

                let uniqueArray = [...new Set(array)];
                sethazardousEquipment(uniqueArray);
              }}
            >
              {dropdown?.hazardousValues?.map((item) => {
                return (
                  <Form.Check
                    label={item}
                    name="group1"
                    type={"checkbox"}
                    id={item}
                  />
                );
              })}
            </div>
            <Form.Group
              style={{
                display:
                  isHazardous && hazardousEquipment?.includes("इतर")
                    ? "block"
                    : "none",
              }}
              className="mb-4"
              controlId="formBasicEmail"
              onChange={(e) => {
                sethazardousEquipmentOther(e.target.value);
              }}
            >
              <Form.Control
                type="number"
                placeholder={"कृपया उल्लेख करा ..."}
              />
            </Form.Group>

            <div
              className="mb-4"
              style={{ display: isHazardous ? "block" : "none" }}
            >
              <input
                type="file"
                accept="image/*,video/*"
                onChange={(e) => {
                  handleProfileImg(e)
                    .then((res) => {
                      sethazardousEquipmentImage(res);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
              {hazardousEquipmentImage && (
                <img
                  src={hazardousEquipmentImage}
                  alt="new"
                  style={{ height: 50, width: 50 }}
                />
              )}
            </div>
          </Form>

          <Form.Label className="Label-muted">
            {label?.page2?.isAttic}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisAttic(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isAttic}
              inline
              label={"होय"}
              name="group8"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isAttic}
              inline
              label={"नाही"}
              name="group8"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <Form style={{ display: isAttic ? "block" : "none" }}>
            <div
              key={"inline"}
              className="mb-3"
              onChange={(e) => {
                let array = [];
                if (atticInfo.includes(e.target.id)) {
                  array = atticInfo.filter((item) => item !== e.target.id);
                } else {
                  array = [...atticInfo, e.target.id];
                }

                let uniqueArray = [...new Set(array)];
                setatticInfo(uniqueArray);
              }}
            >
              {dropdown?.atticValues?.map((item) => {
                return (
                  <Form.Check
                    label={item}
                    name="group1"
                    type={"checkbox"}
                    id={item}
                  />
                );
              })}
            </div>

            <Form.Group
              style={{
                display:
                  isAttic && atticInfo?.includes("इतर") ? "block" : "none",
              }}
              className="mb-4"
              controlId="formBasicEmail"
              onChange={(e) => {
                setatticInfoOther(e.target.value);
              }}
            >
              <Form.Control
                type="number"
                placeholder={"कृपया उल्लेख करा ..."}
              />
            </Form.Group>
            <div
              className="mb-4"
              style={{ display: isAttic ? "block" : "none" }}
            >
              <input
                type="file"
                accept="image/*,video/*"
                onChange={(e) => {
                  handleProfileImg(e)
                    .then((res) => {
                      setatticImage(res);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
              {atticImage && (
                <img
                  src={atticImage}
                  alt="new"
                  style={{ height: 50, width: 50 }}
                />
              )}
            </div>
          </Form>

          <Form.Label className="Label-muted">
            {label?.page2?.isBasement}
          </Form.Label>
          <Form.Group
            key={`radio`}
            className="mb-4"
            onChange={(e) => {
              setisBasement(e.target.id == "true" ? true : false);
            }}
          >
            <Form.Check
              checked={isBasement}
              inline
              label={"होय"}
              name="group9"
              type={"radio"}
              id={"true"}
            />
            <Form.Check
              checked={!isBasement}
              inline
              label={"नाही"}
              name="group9"
              type={"radio"}
              id={"false"}
            />
          </Form.Group>

          <Form style={{ display: isBasement ? "block" : "none" }}>
            <div
              key={"inline"}
              className="mb-3"
              onChange={(e) => {
                let array = [];
                if (basementInfo.includes(e.target.id)) {
                  array = basementInfo.filter((item) => item !== e.target.id);
                } else {
                  array = [...basementInfo, e.target.id];
                }

                let uniqueArray = [...new Set(array)];
                setbasementInfo(uniqueArray);
              }}
            >
              {dropdown?.basementValues?.map((item) => {
                return (
                  <Form.Check
                    label={item}
                    name="group1"
                    type={"checkbox"}
                    id={item}
                  />
                );
              })}
            </div>

            <Form.Group
              style={{
                display:
                  isBasement && basementInfo?.includes("इतर")
                    ? "block"
                    : "none",
              }}
              className="mb-4"
              controlId="formBasicEmail"
              onChange={(e) => {
                setbasementInfoOther(e.target.value);
              }}
            >
              <Form.Control
                type="number"
                placeholder={"कृपया उल्लेख करा ..."}
              />
            </Form.Group>
          </Form>

          <div
            className="mb-4"
            style={{ display: isBasement ? "block" : "none" }}
          >
            <input
              type="file"
              accept="image/*,video/*"
              onChange={(e) => {
                handleProfileImg(e)
                  .then((res) => {
                    setbasementImage(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
            {basementImage && (
              <img
                src={basementImage}
                alt="new"
                style={{ height: 50, width: 50 }}
              />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="primaryBtn"
              onClick={(e) => {
                Updatepage3(e);
              }}
            >
              माहिती जतन करा
            </Button>
          </div>
        </Form>
      )}
    </div>
    </>
  );
}
