import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Spinner, Card, Button, Modal, Tab, Nav } from 'react-bootstrap';
import Pagination from '../Common/Pagination';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import RejectShield from '../../Assets/RejectShield.svg';
import { setImgVerifyReport } from '../Common/ActiveKeySlice';
import Question from '../../Assets/Question.svg';
import ApproveShield from '../../Assets/ApproveShield.svg';


const CrossVerification = () => {

    const userState = useSelector(state => state.surveylogin.userData?.data);
    const activeImgVerifyReportState = useSelector(state => state.surveyActiveData.activeImgVerifyReport);

    const dispatch = useDispatch();

    const ImageKeys = [
        { key: 'outsideImage', engLabel: 'Outside', marLabel: 'बाहेरील', verifyKey: 'isOutsideImageVerified' },
        { key: 'insideImage', engLabel: 'Inside', marLabel: 'आतील', verifyKey: 'isInsideImageVerified' },
        { key: 'sathaParvanaImage', engLabel: 'Satha Parvana', marLabel: 'साठा परवाना', verifyKey: 'isSathaParvanaImageVerified' },
        { key: 'sathaShopActLicenseImage', engLabel: 'Satha ShopAct', marLabel: 'साठा शॉपॲक्ट', verifyKey: 'isSathaShopActLicenseImageVerified' },
        { key: 'businessParvanaImage', engLabel: 'Business Parvana', marLabel: 'बिझिनेस परवाना', verifyKey: 'isBusinessParvanaImageVerified' },
        { key: 'businessShopActLicenseImage', engLabel: 'Business Shopact', marLabel: 'बिझिनेस शॉपॲक्ट', verifyKey: 'isBusinessShopActLicenseImageVerified' },
        { key: 'foodLicenseImage', engLabel: 'Food License', marLabel: 'फूड लायसेन', verifyKey: 'isFoodLicenseImageVerified' },
        { key: 'fireNOCImage', engLabel: 'Fire NOC', marLabel: 'फायर NOC', verifyKey: 'isFireNOCImageVerified' },
        { key: 'fireAnnualImage', engLabel: 'Fire Annual', marLabel: 'फायर नूतनीकरण', verifyKey: 'isFireAnnualImageVerified' },
        { key: 'fireSafetyCertificateImage', engLabel: 'Fire Safety Certificate', marLabel: 'फायर सेफ्टी सर्टिफिकेट', verifyKey: 'isFireSafetyCertificateImageVerified' },
        { key: 'electricMeterImage', engLabel: 'Electric Meter', marLabel: 'इलेट्रीक मीटर', verifyKey: 'isElectricMeterImageVerified' },
        { key: 'fireExtinguisherEquipmentImage', engLabel: 'Fire Ext. Equipment', marLabel: 'फायर उपकरणे', verifyKey: 'isFireExtinguisherEquipmentImageVerified' },
        { key: 'hazardousEquipmentImage', engLabel: 'Hazardous Equipment', marLabel: 'धोकादायक यंत्रणा', verifyKey: 'isHazardousEquipmentImageVerified' },
        { key: 'atticImage', engLabel: 'Attic', marLabel: 'पोटमळा', verifyKey: 'isAtticImageVerified' },
        { key: 'basementImage', engLabel: 'Basement', marLabel: 'तळघर', verifyKey: 'isBasementImageVerified' },
        { key: 'residentialImage', engLabel: 'Residential', marLabel: 'निवासी मालमत्ता', verifyKey: 'isResidentialImageVerified' }
    ]


    const [mahilaList, setMahilaList] = useState('');

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(50);
    const [noOfPages, setNoOfPages] = useState();

    const [loader, setLoader] = useState(false);
    const [surveyImages, setSurveyImages] = useState([]);


    // States for Approve-Reject survey models ----------------
    const [showCrossVerify, setShowCrossVerify] = useState(false);
    const [showApproveCrossVerification, setShowApproveCrossVerification] = useState(false);
    const [verifyPayload, setVerifyPayload] = useState('');
    const [crossVerifiedReason, setcrossVerifiedReason] = useState('');
    const [notVerifiedReasonErr, setNotVerifiedReasonErr] = useState(false);
    const [crossverifyLoader, setCrossVerifyLoader] = useState(false);
    const [approveCrossverifyLoader, setApproveCrossverifyLoader] = useState(false);
    const [verificationId, setVerificationId] = useState("")

    useEffect(() => {
        getAllMahilaList();
    }, [])

    const getAllMahilaList = async () => {
        if (userState?.userType_keyname === "mavim") {
            await fetch(process.env.REACT_APP_BASE_URL + '/users/getContractorsByMavim', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    mavimHeadId: userState?.user_id
                })
            }).then((res) => res.json()).then((response) => {
                const result = response?.data?.map((itm) => {
                    return {
                        label: itm.name + " - " + itm.mobile, value: itm.mobile
                    }
                });
                // setMahilaName({label: response?.data[0].name + " - " + response.data[0].mobile, value: response.data[0].mobile});

                setMahilaList(result);
            }).catch((Err) => {
                console.log("Err while getting mahila by ladies", Err);
            })
        }

        else {
            await fetch(process.env.REACT_APP_BASE_URL + '/users/getAllMavim', {
                method: 'GET'
            }).then((res) => res.json()).then((response) => {
                const result = response?.data?.map((itm) => {
                    return {
                        label: itm.name + " - " + itm.mobile, value: itm.mobile
                    }
                });
                setMahilaList(result);
            }).catch((Err) => {
                console.log("Error while getting contractors", Err);
            })
        }

    }

    useEffect(() => {
        if (activeImgVerifyReportState) {
            getSurveyImages();
        }
    }, [pageNo, docPerPage, activeImgVerifyReportState])


    // function for pagination ----------------------------------
        const handlePageChange = (pageNumber) => {
            setPageNo(pageNumber);
        };

        const handleDocsPerPage = (docsPageProp) => {
            setPageNo(1)
            setDocPerPage(docsPageProp);
        }

    const getSurveyImages = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/verification/getAllVerifications', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userType: userState?.userType_keyname,
                user_id: userState?.user_id,
                isVerified: activeImgVerifyReportState?.toggle === 'true' ? true : false,
                verifiedByMobile: activeImgVerifyReportState?.mahilaName?.value,
                verifiedDate: "",
                documentsPerPage: docPerPage,
                page: pageNo,
            })
        }).then((res) => res.json()).then((response) => {
            setSurveyImages(response?.data);
            setNoOfPages(response?.data?.noOfPages);
            setLoader(false);
        }).catch((Err) => {
            console.log("Err while getting verified images", Err);
            setLoader(false);
        })
    }


    const openImageInNewTab = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };


    // ---- Verify Survey (Bulk Images verify) --------------------------------------------------
    const handleVerifyModal = (verificationId) => {
        setShowCrossVerify(true);
        setVerifyPayload({ verificationId });
    }

    const closeModal = () => {
        setShowCrossVerify(false);
        setcrossVerifiedReason('');
        setNotVerifiedReasonErr(false);
    }

    const closeApproveCrossModal = () => {
        setShowApproveCrossVerification(false);
        setApproveCrossverifyLoader(false)
        setVerificationId("")
    }



    const crossVerifySurvey = async () => {
        setCrossVerifyLoader(true);
        if (crossVerifiedReason.length === 0) {
            setNotVerifiedReasonErr(true);
            setCrossVerifyLoader(false);
            return;
        }
        await fetch(process.env.REACT_APP_BASE_URL + '/verification/crossVerification', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...verifyPayload,
                isCrossVerified: true,
                crossVerifiedId: userState?.user_id,
                crossVerifiedName: userState?.name,
                crossVerifiedMobileNo: userState?.mobile,
                crossVerifiedDate: new Date().toISOString(),
                crossVerifiedReason: crossVerifiedReason
            })
        }).then((res) => res.json()).then((response) => {
            if (response.data.isCrossVerified === true) {
                toast.success('Cross Verification Successfull !');
                setCrossVerifyLoader(false);
                closeModal();
                getSurveyImages();
            }
        }).catch((Err) => {
            console.log("Err while verifying survey", Err);
            setCrossVerifyLoader(false);
        })
    }

    const handleCheckVerify = async () => {
        setApproveCrossverifyLoader(true)
        await fetch(process.env.REACT_APP_BASE_URL + '/verification/approveCrossVerification', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "verificationId": verificationId,
                "user_id": userState?.user_id
            })
        }).then((res) => res.json()).then((response) => {
            getSurveyImages()
            setShowApproveCrossVerification(false)
            setApproveCrossverifyLoader(false)
        }).catch((Err) => {
            console.log("Err while getting verified images", Err);
            setShowApproveCrossVerification(false)
            setApproveCrossverifyLoader(false)
        })
    }


    return (
        <div className='SurveyImgVerification outletPadding'>
            <ToastContainer />
            <Container>
                <Row className='justify-content-between align-items-center'>

                    <Tab.Container activeKey={activeImgVerifyReportState?.toggle} onSelect={(current) => { dispatch(setImgVerifyReport({ toggle: current })); }}>
                        <Col md={4}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Search by sahyogini mobile or name</Form.Label>
                                <Select value={activeImgVerifyReportState?.mahilaName} options={Array.isArray(mahilaList) ? mahilaList : []} isMulti={false}
                                    placeholder={<div>Enter name or mobile</div>}
                                    onChange={(selected) => { dispatch(setImgVerifyReport({ mahilaName: selected })); }} />
                            </Form.Group>
                        </Col>

                        <Col md={3}>
                            <Nav variant="pills" className="flex-row justify-content-center ms-auto">
                                <Nav.Item>
                                    <Nav.Link eventKey={true} className='Approved'>Approved</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey={false} className='Rejected'>Rejected</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>

                        <Tab.Content className='mt-5'>
                            <Tab.Pane eventKey={true}>
                                {
                                    loader ? <center><Spinner variant='primary' /></center> :
                                        <Row className='imgCardParent' style={{ maxHeight: '60vh' }} data-aos="zoom-in">
                                            {
                                                surveyImages?.surveys !== null && surveyImages?.surveys !== undefined
                                                    && surveyImages?.surveys?.length > 0 ? surveyImages?.surveys?.map((itm, surveyIndex) => {

                                                        return (
                                                            <Col md={10} className='mb-4' key={surveyIndex}>
                                                                <Card className={itm?.isVerified === true ? 'approved'
                                                                    : itm?.isVerified === undefined ? 'nothing' : 'rejected'}>
                                                                    {/* <p className='index'>{surveyIndex+1}</p> */}
                                                                    <p className='surveyId'>{itm?.surveyId}</p>
                                                                    <Row className='mt-4 mt-md-3'>
                                                                        {ImageKeys.map((category, Imageindex) =>

                                                                            itm[category.key] && itm[category.key].length > 0 ? (

                                                                                itm[category.key].length === 1 ?
                                                                                    itm[category.key].map((img, index) => {

                                                                                        const isImage = img.endsWith('.jpg') || img.endsWith('.jpeg') || img.endsWith('.png');
                                                                                        const isVideo = img.endsWith('.mp4') || img.endsWith('.mov') || img.endsWith('.avi');

                                                                                        return (

                                                                                            <Col xs={6} md={2} key={index} className='mb-0'>
                                                                                                <p>{`${category.marLabel} ${itm[category.key].length > 1 ? index + 1 : ''}`}</p>
                                                                                                {
                                                                                                    <div className='imgBlock'>
                                                                                                        {isImage && <img src={img} alt={`${category.marLabel} ${index + 1}`} onClick={() => openImageInNewTab(img)}
                                                                                                            className={itm[category.verifyKey] === true ? 'approved'
                                                                                                                : itm[category.verifyKey] === undefined ? '' : 'rejected'} />}
                                                                                                        {isVideo && <video className={itm[category.verifyKey] === true ? 'approved'
                                                                                                            : itm[category.verifyKey] === undefined ? '' : 'rejected'}> <source src={img} type="video/mp4" /> </video>}
                                                                                                        {isVideo && <span className='PlayBtn'><Icon.PlayCircleFill size={34} onClick={() => openImageInNewTab(img)} /></span>}
                                                                                                    </div>
                                                                                                }
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                    :

                                                                                    // Map for multiple image in single col -----------------------
                                                                                    <Col xs={itm[category.key.length * 4]} md={itm[category.key].length * 2} className='mb-0'>
                                                                                        <Row>
                                                                                            {
                                                                                                itm[category.key].map((img, index) => {

                                                                                                    const isImage = img.endsWith('.jpg') || img.endsWith('.jpeg') || img.endsWith('.png');
                                                                                                    const isVideo = img.endsWith('.mp4') || img.endsWith('.mov') || img.endsWith('.avi');

                                                                                                    return (
                                                                                                        <Col xs={6} md={itm[category.key].length === 3 ? 4 : 6 } key={index}>
                                                                                                            <p>{`${category.marLabel} ${itm[category.key].length > 1 ? index + 1 : ''}`}</p>
                                                                                                            <div className='imgBlock'>
                                                                                                                {isImage && <img src={img} alt={`${category.marLabel} ${index + 1}`} onClick={() => openImageInNewTab(img)}
                                                                                                                    className={itm[category.verifyKey] === true ? 'approved'
                                                                                                                        : itm[category.verifyKey] === undefined ? '' : 'rejected'} />}
                                                                                                                {isVideo && <video className={itm[category.verifyKey] === true ? 'approved'
                                                                                                                    : itm[category.verifyKey] === undefined ? '' : 'rejected'}> <source src={img} type="video/mp4" /> </video>}
                                                                                                                {isVideo && <span className='PlayBtn'><Icon.PlayCircleFill size={34} onClick={() => openImageInNewTab(img)} /></span>}
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </Row>
                                                                                    </Col>
                                                                            ) : null
                                                                        )}
                                                                    </Row>

                                                                    <div className='mt-0 extraFields'>
                                                                        <span>
                                                                            <h6 style={{
                                                                                color: itm?.isPropertyTypeVerified === true ? 'green' :
                                                                                    itm?.isPropertyTypeVerified === undefined ? '#333333' : 'red'
                                                                            }}>
                                                                                Property Type</h6>
                                                                            <p>{itm?.propertyType}</p>
                                                                        </span>


                                                                        <span>
                                                                            <h6 style={{
                                                                                color: itm?.isOwnerMobileVerified === true ? 'green' :
                                                                                    itm?.isOwnerMobileVerified === undefined ? '#333333' : 'red'
                                                                            }}>Owner Mobile</h6>
                                                                            <p>{itm?.ownerMobile}</p>
                                                                        </span>


                                                                        {
                                                                            itm?.rentedPersonMobile &&
                                                                            <span>
                                                                                <h6 style={{
                                                                                    color: itm?.isRentedPersonMobileVerified === true ? 'green' :
                                                                                        itm?.isRentedPersonMobileVerified === undefined ? '#333333' : 'red'
                                                                                }}>Rented Mobile</h6>
                                                                                <p>{itm?.rentedPersonMobile}</p>
                                                                            </span>
                                                                        }
                                                                    </div>

                                                                    {
                                                                        userState?.userType_keyname === "admin" &&
                                                                        <div className='d-flex justify-content-center mt-4'>
                                                                             <button className='approveBtn me-3'
                                                                                onClick={() => { setShowApproveCrossVerification(true); setVerificationId(itm?.verificationId) }}>
                                                                                <Icon.CheckCircleFill className='me-2' size={13} />Approve Cross Verification
                                                                            </button>
                                                                            <button className='rejectBtn'
                                                                                onClick={() => handleVerifyModal(itm?.verificationId)} >
                                                                                <Icon.XCircleFill className='me-2' size={12} />Cross Verify & Reject
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                    : <div className='text-center' data-aos="zoom-in">
                                                        <img src={Question} alt="NoData" width={180} />
                                                        <h5 className='mt-3'>No Approved Survey Found !</h5>
                                                    </div>
                                            }
                                        </Row>
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey={false}>
                                {
                                    loader ? <center><Spinner variant='primary' /></center> :
                                        <Row className='imgCardParent' style={{ maxHeight: '60vh' }} data-aos="zoom-in">
                                            {
                                                surveyImages?.surveys !== null && surveyImages?.surveys !== undefined
                                                    && surveyImages?.surveys?.length > 0 ? surveyImages?.surveys?.map((itm, surveyIndex) => {

                                                        return (
                                                            <Col md={10} className='mb-4' key={surveyIndex}>
                                                                <Card className={itm?.isVerified === true ? 'approved'
                                                                    : itm?.isVerified === undefined ? 'nothing' : 'rejected'}>
                                                                    {/* <p className='index'>{surveyIndex+1}</p> */}
                                                                    <p className='surveyId'>{itm?.surveyId}</p>
                                                                    <Row className='mt-4 mt-md-3'>
                                                                        {ImageKeys.map((category, Imageindex) =>

                                                                            itm[category.key] && itm[category.key].length > 0 ? (

                                                                                itm[category.key].length === 1 ?
                                                                                    itm[category.key].map((img, index) => {

                                                                                        const isImage = img.endsWith('.jpg') || img.endsWith('.jpeg') || img.endsWith('.png');
                                                                                        const isVideo = img.endsWith('.mp4') || img.endsWith('.mov') || img.endsWith('.avi');

                                                                                        return (

                                                                                            <Col xs={6} md={2} key={index} className='mb-0'>
                                                                                                <p>{`${category.marLabel} ${itm[category.key].length > 1 ? index + 1 : ''}`}</p>
                                                                                                {
                                                                                                    <div className='imgBlock'>
                                                                                                        {isImage && <img src={img} alt={`${category.marLabel} ${index + 1}`} onClick={() => openImageInNewTab(img)}
                                                                                                            className={itm[category.verifyKey] === true ? 'approved'
                                                                                                                : itm[category.verifyKey] === undefined ? '' : 'rejected'} />}
                                                                                                        {isVideo && <video className={itm[category.verifyKey] === true ? 'approved'
                                                                                                            : itm[category.verifyKey] === undefined ? '' : 'rejected'}> <source src={img} type="video/mp4" /> </video>}
                                                                                                        {isVideo && <span className='PlayBtn'><Icon.PlayCircleFill size={34} onClick={() => openImageInNewTab(img)} /></span>}
                                                                                                    </div>
                                                                                                }
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                    :

                                                                                    // Map for multiple image in single col -----------------------
                                                                                    <Col xs={itm[category.key.length * 4]} md={itm[category.key].length * 2} className='mb-0'>
                                                                                        <Row>
                                                                                            {
                                                                                                itm[category.key].map((img, index) => {

                                                                                                    const isImage = img.endsWith('.jpg') || img.endsWith('.jpeg') || img.endsWith('.png');
                                                                                                    const isVideo = img.endsWith('.mp4') || img.endsWith('.mov') || img.endsWith('.avi');

                                                                                                    return (
                                                                                                        <Col xs={6} md={6} key={index}>
                                                                                                            <p>{`${category.marLabel} ${itm[category.key].length > 1 ? index + 1 : ''}`}</p>
                                                                                                            <div className='imgBlock'>
                                                                                                                {isImage && <img src={img} alt={`${category.marLabel} ${index + 1}`} onClick={() => openImageInNewTab(img)}
                                                                                                                    className={itm[category.verifyKey] === true ? 'approved'
                                                                                                                        : itm[category.verifyKey] === undefined ? '' : 'rejected'} />}
                                                                                                                {isVideo && <video className={itm[category.verifyKey] === true ? 'approved'
                                                                                                                    : itm[category.verifyKey] === undefined ? '' : 'rejected'}> <source src={img} type="video/mp4" /> </video>}
                                                                                                                {isVideo && <span className='PlayBtn'><Icon.PlayCircleFill size={34} onClick={() => openImageInNewTab(img)} /></span>}
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    )
                                                                                                })
                                                                                            }

                                                                                        </Row>
                                                                                    </Col>
                                                                            ) : null
                                                                        )}
                                                                    </Row>

                                                                    <div className='mt-0 extraFields'>
                                                                        <span>
                                                                            <h6 style={{
                                                                                color: itm?.isPropertyTypeVerified === true ? 'green' :
                                                                                    itm?.isPropertyTypeVerified === undefined ? '#333333' : 'red'
                                                                            }}>
                                                                                Property Type</h6>
                                                                            <p>{itm?.propertyType}</p>
                                                                        </span>


                                                                        <span>
                                                                            <h6 style={{
                                                                                color: itm?.isOwnerMobileVerified === true ? 'green' :
                                                                                    itm?.isOwnerMobileVerified === undefined ? '#333333' : 'red'
                                                                            }}>Owner Mobile</h6>
                                                                            <p>{itm?.ownerMobile}</p>
                                                                        </span>


                                                                        {
                                                                            itm?.rentedPersonMobile &&
                                                                            <span>
                                                                                <h6 style={{
                                                                                    color: itm?.isRentedPersonMobileVerified === true ? 'green' :
                                                                                        itm?.isRentedPersonMobileVerified === undefined ? '#333333' : 'red'
                                                                                }}>Rented Mobile</h6>
                                                                                <p>{itm?.rentedPersonMobile}</p>
                                                                            </span>
                                                                        }
                                                                    </div>

                                                                    {
                                                                        userState?.userType_keyname === "admin" &&
                                                                        <div className='d-flex justify-content-center mt-4'>
                                                                            {
                                                                                itm?.isCrossVerified === true ? <h6>Cross Verifcation Done</h6> :
                                                                                    <button className='rejectBtn me-3'
                                                                                        onClick={() => handleVerifyModal(itm?.verificationId)} >
                                                                                        <Icon.XCircleFill className='me-2' size={12} />Cross Verify & Reject
                                                                                    </button>
                                                                            }

                                                                            <button className='approveBtn'
                                                                                onClick={() => { setShowApproveCrossVerification(true); setVerificationId(itm?.verificationId) }}>
                                                                                <Icon.CheckCircleFill className='me-2' size={13} />Approve Cross Verification
                                                                            </button>

                                                                        </div>
                                                                    }


                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                    : <div className='text-center' data-aos="zoom-in">
                                                        <img src={Question} alt="NoData" width={180} />
                                                        <h5 className='mt-3'>No Rejected Survey Found !</h5>
                                                    </div>
                                            }
                                        </Row>
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </Row>

                {
                    !loader && surveyImages?.surveys?.length > 0 &&
                    <Row>
                        <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                            <h6 className='text-start mb-0'>
                                <Icon.People className='me-2' />Total - <strong>{surveyImages?.count}</strong>
                            </h6>
                        </Col>
                        <Col md={8} className='d-flex justify-content-end p-md-0'>
                            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
                                onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
                        </Col>
                    </Row>
                }

            </Container>




            <Modal size='md' show={showCrossVerify} backdrop='static' keyboard={false} centered onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className='mb-0'>
                            <Icon.XCircleFill size={20} className='me-2' color='red' />Cross Verify & Reject Survey
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <div data-aos='zoom-in'>
                        <img src={RejectShield} alt="Aprrove Survey" width={80} height={80} />
                        <h4 style={{ fontWeight: '600', marginTop: '6px' }}>Are you sure to reject this survey ?</h4>
                        <Form.Group className='text-start mt-3 px-3'>
                            <Form.Label><span>*</span> Enter reject reason to continue</Form.Label>
                            <Form.Control placeholder='Enter reason' value={crossVerifiedReason}
                                onChange={(e) => setcrossVerifiedReason(e.target.value)} />
                            {notVerifiedReasonErr ? <p className='errMsg'>Please enter reason for cross verify & reject</p> : ''}
                        </Form.Group>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {
                        crossverifyLoader ? <Spinner variant='primary' /> :
                            <>
                                <Button className='primaryBtn me-3' onClick={crossVerifySurvey}>
                                    <Icon.HandThumbsUp className='me-1' />Yes</Button>
                                <Button className='secondaryBtn' onClick={closeModal}>
                                    <Icon.HandThumbsDown className='me-1' />No</Button>
                            </>
                    }

                </Modal.Footer>
            </Modal>


            <Modal size='md' show={showApproveCrossVerification} backdrop='static' keyboard={false} centered onHide={closeApproveCrossModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className='mb-0'>
                            <Icon.CheckCircleFill size={20} className='me-2' color='green' />Approve Cross Verification
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <div data-aos='zoom-in'>
                        <img src={ApproveShield} alt="Aprrove Survey" width={80} height={80} />
                        <h4 style={{ fontWeight: '600', marginTop: '6px' }}>Are you sure you want to approve this survey ?</h4>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {
                        approveCrossverifyLoader ? <Spinner variant='primary' /> :
                            <>
                                <Button className='primaryBtn me-3' onClick={() => { handleCheckVerify() }}>
                                    <Icon.HandThumbsUp className='me-1' />Yes</Button>
                                <Button className='secondaryBtn' onClick={closeApproveCrossModal}>
                                    <Icon.HandThumbsDown className='me-1' />No</Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CrossVerification;