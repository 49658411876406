import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Formone = () => {

  const navigation = useNavigate();
  const [label, setlabel] = useState("");
  const [dropdown, setdropdown] = useState("");
  const [loader, setloader] = useState(false);
  const [properytyType, setproperytyType] = useState([]);
  const [subpropertyType, setsubpropertyType] = useState([]);
  const [propertyFloor, setpropertyFloor] = useState("");
  const [buildingFloors, setbuildingFloors] = useState("");
  const [propertyAreaMeter, setpropertyAreaMeter] = useState("");
  const [propertyAreaFoot, setpropertyAreaFoot] = useState("");
  const [addressLandmark, setaddressLandmark] = useState("");
  const [addressFloor, setaddressFloor] = useState("");
  const [addressRoadColony, setaddressRoadColony] = useState("");
  const [propertyWorkPlace, setpropertyWorkPlace] = useState("");
  const [propertyType, setpropertyType] = useState("");
  const [propertyTypeId, setpropertyTypeId] = useState("");
  const [subPropertyType, setsubPropertyType] = useState("");
  const [subPropertyTypeOther, setsubPropertyTypeOther] = useState("");
  const [subPropertyTypeId, setsubPropertyTypeId] = useState("");
  const [ownerName, setownerName] = useState("");
  const [businessAddress, setbusinessAddress] = useState("");
  const [finalBusinessAddress, setfinalBusinessAddress] = useState("");
  const [ownerMobile, setownerMobile] = useState("");
  const [ownerEmail, setownerEmail] = useState("");
  const [isRented, setisRented] = useState(false);
  const [rentedPersonName, setrentedPersonName] = useState("");
  const [rentedPersonMobile, setrentedPersonMobile] = useState("");
  const [businessStartDate, setbusinessStartDate] = useState("");
  const [surveyById, setsurveyById] = useState("");
  const [surveyByName, setsurveyByName] = useState("");
  const [surveyByMobile, setsurveyByMobile] = useState("");
  const [surveyId, setsurveyId] = useState("");

  useEffect(() => {
    getAllpropertyType();
    getLebal();
  }, []);


  const getLebal = async () => {
    setloader(true);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/labels/getLabels")
      .then((res) => {
        if (res.data.data) {
          setlabel(res.data.data);
          getIntialValue();
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getdropdownvalue = async () => {
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/dropdownValues/getAllDropdownValues",
        {
          propertyCode: "1020100097.00",
          user_id: "",
        }
      )
      .then((res) => {
        if (res.data.dropdownValues) {
          setloader(false);

          setdropdown(res.data.dropdownValues);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };
  const getAllpropertyType = async () => {
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/pt/getAllPropertyType")
      .then((res) => {
        setproperytyType(res.data.data);
      })
      .catch((err) => {
        console.log("errrr", err);
      });
  };
  const getSubpropertyType = async (e) => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/spt/getSubPropertyType", {
        parentTypeId: e,
      })
      .then((res) => {
        if (res.data.data) {
          setsubpropertyType(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const UpadtePage1 = async (e) => {
    e.preventDefault();
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/updateSurveyPage1", {
        propertyFloor: propertyFloor,
        buildingFloors: buildingFloors,
        propertyAreaMeter: propertyAreaMeter,
        propertyAreaFoot: propertyAreaFoot,
        // new Address Fields
        addressLandmark: addressLandmark,
        addressFloor: addressFloor,
        zonekey: "",
        addressRoadColony: addressRoadColony,
        propertyWorkPlace: propertyWorkPlace,
        propertyType: propertyType,
        propertyTypeId: propertyTypeId,
        subPropertyType: subPropertyType,
        subPropertyTypeOther: subPropertyTypeOther,
        subPropertyTypeId: subPropertyTypeId,
        ownerName: ownerName,
        businessAddress: businessAddress,
        finalBusinessAddress: finalBusinessAddress,
        ownerMobile: ownerMobile,
        ownerEmail: ownerEmail,
        isRented: isRented,
        rentedPersonName: rentedPersonName,
        rentedPersonMobile: rentedPersonMobile,
        businessStartDate: businessStartDate,
        surveyById: surveyById,
        surveyByName: surveyByName,
        surveyByMobile: surveyByMobile,
        checkLat: "",
        checkLong: "",
        surveyId: surveyId,
      })
      .then((res) => {
        console.log(res.data);
        navigation("/CitizenLayout/Formtwo");
      })
      .catch((err) => {
        console.log(err);
      });
    // navigation("/CitizenLayout/Formtwo");
  };
  const getIntialValue = async () => {
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/surveys/getSurveyBySurveyId", {
        surveyId: "SURVEY_1110100008_042023032024_9359330702",
      })
      .then((res) => {
        setloader(false);
        setdropdown(res.data.dropdownValues);
        if (res.data.page1) {
          setpropertyFloor(res.data.page1.propertyFloor);
          setbuildingFloors(res.data.page1.buildingFloors);
          setpropertyAreaMeter(res.data.page1.propertyAreaMeter);
          setpropertyAreaFoot(res.data.page1.propertyAreaFoot);
          setaddressLandmark(res.data.page1.addressLandmark);
          setaddressFloor(res.data.page1.addressFloor);
          setaddressRoadColony(res.data.page1.addressRoadColony);
          setpropertyWorkPlace(res.data.page1.propertyWorkPlace);
          setpropertyType(res.data.page1.propertyType);
          setpropertyTypeId(res.data.page1.propertyTypeId);
          setsubPropertyType(res.data.page1.propertyType);
          setownerName(res.data.page1.ownerName);
          setbusinessAddress(res.data.page1.businessAddress);
          setfinalBusinessAddress(res.data.page1.finalBusinessAddress);
          setownerMobile(res.data.page1.ownerMobile);
          setownerEmail(res.data.page1.ownerEmail);
          setisRented(res.data.page1.isRented);
          setrentedPersonName(res.data.page1.rentedPersonName);
          setrentedPersonMobile(res.data.page1.rentedPersonMobile);
          setbusinessStartDate(res.data.page1.businessStartDate);
          setsurveyById(res.data.page1.surveyId);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
  };



  return (
    <div className="form1 my-4">
    <Container>
        <h1 className='text-center'>General Information</h1>
     
        {
          loader ? <Spinner variant="primary"/> :
            <Form>
            <Row>
              <Col md={6} className="mb-4">
                <Form.Group>
                  <Form.Label>{label?.page1?.buildingFloor}</Form.Label>
                  <Form.Control value={buildingFloors}
                    onChange={(e) => { setbuildingFloors(e.target.value); }} type="number"
                    placeholder={label?.page1?.buildingFloor} />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-4">
                  <Form.Label>{label?.page1?.propertyFloor}</Form.Label>
                  <Form.Control value={propertyFloor} onChange={(e) => { setpropertyFloor(e.target.value) }}
                    type="number" placeholder={label?.page1?.propertyFloor}/>
                </Form.Group>
              </Col>

              <Col md={6}>
               <Form.Label>{label?.page1?.propertyAreaHeader}</Form.Label>
                <div className='d-flex'>
                  <Form.Group className='me-3'  as={Col} onChange={(e) => { setpropertyAreaFoot(e.target.value)}}>
                    <Form.Label>{"चौ. फुट "}</Form.Label>
                    <Form.Control value={propertyAreaFoot} type="number" placeholder={"चौ. फुट "}/>
                  </Form.Group>

                  <Form.Group as={Col} onChange={(e) => { setpropertyAreaMeter(e.target.value)}}>
                    <Form.Label>{"चौ. मिटर "}</Form.Label>
                    <Form.Control value={propertyAreaMeter} type="number" placeholder={"चौ. मिटर "}/>
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
              <Form.Group
                key={`radio`}
                className="mb-4"
                onChange={(e) => {
                  setpropertyWorkPlace(e.target.id);
                }}
              >
                <Form.Label className="Label-muted">
                  {label?.page1?.propertyWorkPlace}
                </Form.Label>
                <Form.Check
                  label={label?.page1?.MIDC}
                  name="group1"
                  type={"radio"}
                  id={label?.page1?.MIDC}
                  value={propertyWorkPlace}
                  checked={propertyWorkPlace === label?.page1?.MIDC}
                />
                <Form.Check
                  label={label?.page1?.PCMC}
                  name="group1"
                  type={"radio"}
                  id={label?.page1?.PCMC}
                  value={propertyWorkPlace}
                  checked={propertyWorkPlace === label?.page1?.PCMC}
                />
                <Form.Check
                  label={label?.page1?.PMRDA}
                  name="group1"
                  type={"radio"}
                  id={label?.page1?.PMRDA}
                  value={propertyWorkPlace}
                  checked={propertyWorkPlace === label?.page1?.PMRDA}
                />
              </Form.Group>
    

              </Col>
              

              
              <Form.Group className="mb-4">
                <Form.Label className="Label-muted ">
                  {label?.page1?.propertyType}
                </Form.Label>
    
                <Form.Select
                  onChange={(e) => {
                    setpropertyTypeId(JSON.parse(e.target.value).propertyTypeId);
                    setpropertyType(JSON.parse(e.target.value).propertyType);
                    getSubpropertyType(JSON.parse(e.target.value).propertyTypeId);
                  }}
                >
                  {properytyType?.length > 0 &&
                    properytyType.map((item) => {
                      return (
                        <option
                          label={item.propertyType}
                          value={JSON.stringify(item)}
                          key={item}
                        ></option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
    
              <Form.Group
                className="mb-4"
                onChange={(e) => {
                  setsubPropertyType(JSON.parse(e.target.value).subPropertyType);
                  setsubPropertyTypeId(
                    JSON.parse(e.target.value).subPropertyTypeId
                  );
                }}
              >
                <Form.Label className="Label-muted ">
                  {label?.page1?.businessType}
                </Form.Label>
                <Form.Select aria-label="Default select example">
                  {subpropertyType.length > 0 &&
                    subpropertyType.map((item) => {
                      return (
                        <option value={JSON.stringify(item)}>
                          {item.subPropertyType}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
    
              <Form.Group
                style={{ display: subPropertyType == "इतर" ? "block" : "none" }}
                className="mb-4"
                controlId="formBasicEmail"
              >
                <Form.Label>{"इतर"}</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setsubPropertyTypeOther(e.target.value);
                  }}
                  value={subPropertyTypeOther}
                  type="name"
                  placeholder={"इतर"}
                />
              </Form.Group>
    
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>{label?.page1?.ownerName}</Form.Label>
                <Form.Control
                  value={ownerName}
                  onChange={(e) => {
                    setownerName(e.target.value);
                  }}
                  type="name"
                  placeholder={label?.page1?.ownerName}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>{label?.page1?.addressFloor}</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setaddressFloor(e.target.value);
                  }}
                  type="name"
                  value={addressFloor}
                  placeholder={label?.page1?.addressFloor}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>{label?.page1?.addressRoadColony}</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setaddressRoadColony(e.target.value);
                  }}
                  value={addressRoadColony}
                  type="name"
                  placeholder={label?.page1?.addressRoadColony}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>{label?.page1?.addressLandmark}</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setaddressLandmark(e.target.value);
                  }}
                  type="name"
                  value={addressLandmark}
                  placeholder={label?.page1?.addressLandmark}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>{label?.page1?.businessAddress}</Form.Label>
                <Form.Control
                  value={businessAddress}
                  onChange={(e) => {
                    setbusinessAddress(e.target.value);
                  }}
                  type="name"
                  placeholder={label?.page1?.businessAddress}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>{label?.page1?.finalBusinessAddress}</Form.Label>
                <Form.Control
                  value={finalBusinessAddress}
                  onChange={(e) => {
                    setfinalBusinessAddress(e.target.value);
                  }}
                  type="name"
                  placeholder={label?.page1?.finalBusinessAddress}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>{label?.page1?.ownerMobile}</Form.Label>
                <Form.Control
                  value={ownerMobile}
                  onChange={(e) => {
                    setownerMobile(e.target.value);
                  }}
                  type="mobile"
                  placeholder={label?.page1?.ownerMobile}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>{label?.page1?.ownerEmail}</Form.Label>
                <Form.Control
                  value={ownerEmail}
                  onChange={(e) => {
                    setownerEmail(e.target.value);
                  }}
                  type="email"
                  placeholder={label?.page1?.ownerEmail}
                />
              </Form.Group>
              <Form.Label className="Label-muted">
                {label?.page1?.isRented}
              </Form.Label>
              <Form.Group
                key={`radio`}
                className="mb-4"
                onChange={(e) => {
                  setisRented(e.target.id == "true" ? true : false);
                }}
              >
                <Form.Check
                  checked={isRented == false}
                  inline
                  label={"मूळ जागा मालक आहे"}
                  name="group2"
                  type={"radio"}
                  id={"false"}
                  value={isRented}
                />
                <Form.Check
                  checked={isRented == true}
                  inline
                  label={"भाड़ेतत्त्वावर आहे"}
                  name="group2"
                  type={"radio"}
                  id={"true"}
                  value={isRented}
                />
              </Form.Group>
    
              <Form.Group
                style={{ display: isRented ? "block" : "none" }}
                className="mb-4"
                controlId="formBasicEmail"
              >
                <Form.Label>{label?.page1?.rentedPersonName}</Form.Label>
                <Form.Control
                  value={rentedPersonName}
                  onChange={(e) => {
                    setrentedPersonName(e.target.value);
                  }}
                  type="name"
                  placeholder={label?.page1?.rentedPersonName}
                />
              </Form.Group>
              <Form.Group
                style={{ display: isRented ? "block" : "none" }}
                className="mb-4"
                controlId="formBasicEmail"
              >
                <Form.Label>{label?.page1?.rentedPersonMobile}</Form.Label>
                <Form.Control
                  value={rentedPersonMobile}
                  onChange={(e) => {
                    setrentedPersonMobile(e.target.value);
                  }}
                  type="mobile"
                  placeholder={label?.page1?.rentedPersonMobile}
                />
              </Form.Group>
              <Form.Group
                className="mb-4"
                controlId="formBasicEmail"
                onChange={(e) => {
                  setbusinessStartDate(e.target.value);
                }}
              >
                <Form.Label>{label?.page1?.businessDate}</Form.Label>
                <Form.Control
                  value={businessStartDate}
                  type="date"
                  placeholder={label?.page1?.businessDate}
                />
              </Form.Group>
    
              <div className="d-flex justify-content-end">
                <Button
                  className="primaryBtn"
                  onClick={(e) => {
                    UpadtePage1(e);
                  }}
                >
                  Next
                </Button>
              </div>
              
      </Row>
            </Form>
        }
    </Container>
    </div>
  )
}

export default Formone