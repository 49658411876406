import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Button,
  Card,
  Modal,
  Tab,
  Nav,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import Pagination from "../Common/Pagination";
import * as XLSX from "xlsx";
import Skeleton from "../Common/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import useGetBlockNo from "../Common/ReuseAPI/useGetBlockNo";
import useGetZoneName from "../Common/ReuseAPI/useGetZoneName";
import useGetOfficerList from "../Common/ReuseAPI/useGetOfficerList";
import moment from "moment";
import { setNewActiveKey } from "../Common/ActiveKeySlice";
import JSZip from "jszip";

const GenerateNotice = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [checkboxArray, setCheckBoxArray] = useState([]);
  // const [type,setType] = useState("all")

  const [showDownload, setShowDownload] = useState(false);
  const [mergeLoader, setMergeLoader] = useState(false);

  const [dateNoticeModal, setDateNoticeModal] = useState(false);
  const [mergeArray, setMergeAraay] = useState([]);
  const [mergeError, setMergeError] = useState({});
  const [noticeDate, setNoticeDate] = useState("");
  const [selectedNotice, setSelectedNotice] = useState("");
  const [selectedNoticeFormat, setSelectedNoticeFormat] = useState("");
  const [noticeDropDown, setNoticeDropdown] = useState([]);
  const [noticeFormatDropDown, setNoticeFormatDropDown] = useState([]);

  const [singleNoticeMoodal, setSingleNoticeMoodal] = useState(false);
  const [singleData, setSingleData] = useState("");
  const [singleError, setsingleError] = useState({});
  const [singleNoticeDate, setSingleNoticeDate] = useState("");
  const [singleSelectedNotice, setSingleSelectedNotice] = useState("");
  const [singleLoader, setSingleLoader] = useState(false);
  const [responses, setResponses] = useState([]);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();

  const [download, setDownload] = useState(false);

  // filter states
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [blockNo, setBlockNo] = useState("");
  const [zoneName, setZoneName] = useState("");
  const [officer, setOfficer] = useState("");

  // cascaded Variables
  const [cascadedBlockNo, setCascadedBlockNo] = useState("");
  const [cascadedZoneName, setCascadedZoneName] = useState("");
  const [cascadedOfficer, setCascadedOfficer] = useState("");
  const [cascadedError, setCascadedError] = useState("");

  // noOfDays
  const [noOfDays, setNoOfDays] = useState("");
  const [daysError, setDaysError] = useState(false);

  // Search By Notice No
  const [noticeNo, setNoticeNos] = useState("");
  const [noticeErr, setNoticeErr] = useState(false);

  // filter error
  const [blockErr, setBlockErr] = useState(false);
  const [zoneErr, setZoneErr] = useState(false);
  const [officerErr, setOfficerErr] = useState(false);

  // Reuse API
  const { blockNoList } = useGetBlockNo();
  const { zoneNameList } = useGetZoneName();
  const { officerList } = useGetOfficerList();

  const userState = useSelector((state) => state.surveylogin.userData.data);
  const navigate = useNavigate();

  useEffect(() => {
    getNoticeNos();
  }, []);

  useEffect(() => {
    getNoticeToBeGiven();
  }, [docPerPage, pageNo]);

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  const getNoticeToBeGiven = async () => {
    setLoader(true);
    let payload = {
      fromDate: startdate,
      toDate: enddate,
      user_id: officer,
      blockNo: blockNo,
      zonename: zoneName,
      documentsPerPage: docPerPage,
      page: pageNo,
      // noOfDays: Number(noOfDays),
      userInputNoticeNo: noticeNo,
    };

    await fetch(
      process.env.REACT_APP_BASE_URL + "/newSurvey/getAllSurveyNoticeDashboard",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setLoader(false);
        setData(response);
        setNoOfPages(response.noOfPages);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
        setLoader(false);
      });
  };

  const noticeTypeLabels = {
    paniType: "Pani Notice Type",
    veejType: "Veej Notice Type"
  };

  const defaultLabel = "Notice Type";

  const getLabelForIndex = (index) => {
    const keys = Object.keys(noticeTypeLabels);
    return keys[index] ? noticeTypeLabels[keys[index]] : defaultLabel;
  }; 

  const getNoticeNos = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/newNoticeRoute/noticeNosGenerate", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response.data)
        setNoticeDropdown(response.data);
      })
      .catch((Err) => {
        console.log("Err while getting propertType", Err);
      });
  };

  const checkBoxChnage = (event, item) => {
    if (event.target.checked) {
      let arr = [...checkboxArray];
      arr.push(item);
      setCheckBoxArray(arr);
    } else {
      let arr = [...checkboxArray];
      let getvalue = arr.findIndex((row) => row?.surveyId === item?.surveyId);
      arr.splice(getvalue, 1);
      setCheckBoxArray(arr);
    }
  };

  const changeSelectAll = (event) => {
    if (event.target.checked) {
      let arr = [];
      data?.data.map((row) => {
        arr.push(row);
      });
      setCheckBoxArray(arr);
    } else {
      setCheckBoxArray([]);
    }
  };

  const closeModal = () => {
    setShowDownload(false);
  };

  const closeDateModal = () => {
    setDateNoticeModal(false);
    setSelectedNotice("");
    setSelectedNoticeFormat("");
    setNoticeDate("");
    setCheckBoxArray([]);
    setMergeAraay([]);
  };

  const isValidNotice = () => {
    let errors = {};

    if (!noticeDate) {
      errors.noticeDate = "*Please Select Notice Date.";
    }

    if (!selectedNotice) {
      errors.selectedNotice = "*Please Select Notice Number.";
    }

    if (!selectedNoticeFormat) {
      errors.selectedNoticeFormat = "*Please Select Notice Type.";
    }

    setMergeError(errors);
    return Object.keys(errors).length === 0;
  };

  const bulkInsert = async () => {
    if (isValidNotice()) {
      setMergeLoader(true);
      let payload = {
        surveyData: checkboxArray,
        noticeGnrtPrsnId: userState.user_id,
        noticeGnrtPrsnName: userState.name,
        noticeGnrtPrsnMobile: userState.mobile,
        noticeGnrtPrsnRole: userState.userType_keyname,
        noticeDate: noticeDate,
        userInputNoticeNo: selectedNotice,
        noticeFormatName: selectedNoticeFormat.length>1 ? selectedNoticeFormat : selectedNoticeFormat[0],
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "/newNoticeRoute/bulkInsertNotice",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setMergeLoader(false);
          setMergeAraay(response.data);
          closeDateModal();
          toast.success("Notice Generated Successfully");
          let timeout = setTimeout(() => {
            navigate("/dashboard/merge-pdf");
            dispatch(
              setNewActiveKey({
                activeKey: 13,
                subActiveKey: 13.6,
                activeTab: "Download Merge Notice",
              })
            );
            clearTimeout(timeout);
          }, 3000);
        })
        .catch((Err) => {
          console.log("Err while getting propertType", Err);
          setMergeLoader(false);
        });
    }
  };

  // Search by Date function
  const searchByDate = async () => {
    if (startdate && enddate) {
      getNoticeToBeGiven();
    } else {
      !startdate && toast.error("Please Select From Date");
      !enddate && toast.error("Please Select To Date");
    }
  };

  // Search by block and zone
  const searchByBlockZoneValidate = () => {
    let isValid = true;
    if (!zoneName) {
      isValid = false;
      setZoneErr(true);
    } else if (!blockNo) {
      isValid = false;
      setBlockErr(true);
    }
    return isValid;
  };

  const searchByBlockZone = async () => {
    if (searchByBlockZoneValidate()) {
      await getNoticeToBeGiven();
    }
  };

  // Search by officer
  const searchByOfficerValidate = () => {
    let isValid = true;
    if (!officer) {
      isValid = false;
      setOfficerErr(true);
    }
    return isValid;
  };

  const searchByOfficer = async () => {
    if (searchByOfficerValidate()) {
      await getNoticeToBeGiven();
    }
  };

  const isSingleNoticeValid = () => {
    let errors = {};

    if (!singleNoticeDate) {
      errors.noticeDate = "*Please Select Notice Date.";
    }

    if (!singleSelectedNotice) {
      errors.selectedNotice = "*Please Select Notice Number.";
    }

    responses.forEach((_, idx) => {
      if (!selectedNoticeFormat[idx]) {
        errors[`selectedNoticeFormat_${idx}`] = "*Please Select Notice Type.";
      }
    });

    setsingleError(errors);
    return Object.keys(errors).length === 0;
  };

  const downloadZip = async(dataArray)=>{
    const zip = new JSZip();

    // Download each PDF and add it to the zip file
    await Promise.all(
      dataArray.map(async (pdfUrl, index) => {
        const response = await fetch(pdfUrl);
        const data = await response.arrayBuffer();

        // Compress the PDF content using pako
        // const compressedData = await pako.deflateRaw(data);

        zip.file(`document${index + 1}.pdf`, data);
      })
    );

    // Generate the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });

     // Create a link element to trigger the download
     const link = document.createElement("a");
     link.href = URL.createObjectURL(zipBlob);
     link.download = "pdfs.zip";
     document.body.appendChild(link);

     // Trigger the download
     link.click();

     // Remove the link element
     document.body.removeChild(link);
     setSingleLoader(false);


  }


  const getSingleNotice = async () => {
    if (isSingleNoticeValid()) {
      setSingleLoader(true);
      let payload = {
        ...singleData,
        noticeGnrtPrsnId: userState.user_id,
        noticeGnrtPrsnName: userState.name,
        noticeGnrtPrsnMobile: userState.mobile,
        noticeGnrtPrsnRole: userState.userType_keyname,
        noticeDate: singleNoticeDate,
        userInputNoticeNo: singleSelectedNotice,
        noticeFormatName: selectedNoticeFormat.length>1 ? selectedNoticeFormat : selectedNoticeFormat[0] ,
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "/newNoticeRoute/generateNotice",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            if(Array.isArray(response?.data)){
                downloadZip(response?.data);
            }
            else{
            fetch(response.data)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement("a");
                a.href = url;
                a.download = singleData.surveyId + ".pdf";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                closeSingleModal();
                setSingleLoader(false);
              })
              .catch((error) => {
                console.error("Error downloading PDF:", error);
                setSingleLoader(false);
              });
          }
        }
        })
        .catch((Err) => {
          console.log("Err while getting propertType", Err);
          setSingleLoader(false);
        });
    }
  };

  const closeSingleModal = () => {
    setSingleNoticeMoodal(false);
    setSingleData("");
    setSingleSelectedNotice("");
    setSelectedNoticeFormat("");
    setSingleNoticeDate("");
    setSingleLoader(false)
  };

  /* -------handling Merged Notice Format ------ */
  const handleNoticeChange = (e, type) => {
    const selectedValue = JSON.parse(e.target.value);
    console.log(selectedValue)
    type === "multiple" ? setSelectedNotice(selectedValue) : setSingleSelectedNotice(selectedValue)
    setSelectedNoticeFormat('');

    if (Array.isArray(selectedValue)) {
      const promises = selectedValue.map((value) => getNoticeFormat(value));
      Promise.all(promises).then((results) => {
        setResponses(results);
      });
    } else {
        getNoticeFormat(selectedValue).then((result) => {
        setResponses([result]);
      });
    }
  };


  const getNoticeFormat = async (noticeType) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/noticeFormat/getNoticeFormat`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userInputNoticeNo: noticeType }),
      }
    );

    const data = await response.json();
    if (response.ok && data.status === 200) {
      return data.data;
    } else {
      console.error("Error fetching notice format:", data);
      return [];
    }
  } catch (error) {
    console.error("Error while getting notice format:", error);
    return [];
  }
  };
  const isCascadedValid = () => {
    let errors = {};

    if (!cascadedZoneName) {
      errors.cascadedZoneName = "*Please Select Zone.";
    }

    if (!cascadedBlockNo) {
      errors.cascadedBlockNo = "*Please Select Block No.";
    }

    if (!cascadedOfficer) {
      errors.cascadedOfficer = "*Please Select Officer.";
    }

    setCascadedError(errors);
    return Object.keys(errors).length === 0;
  };

  const searchByNoOfDays = async () => {
    if (noOfDays) {
      await getNoticeToBeGiven();
      setDaysError(false);
    } else {
      setDaysError(true);
    }
  };

  const searchByNotice = async () => {
    if (noticeNo) {
      await getNoticeToBeGiven();
      setNoticeErr(false);
    } else {
      setNoticeErr(true);
    }
  };

  return (
    <div className="outletPadding allocate-target">
      <ToastContainer />
      <Container>
        <Row className="justify-content-end mt-5">
          {/* <Col md={2} className="d-flex justify-content-end">
                <Button
                  disabled={checkboxArray.length === 0}
                  className="primaryBtn"
                  onClick={() => setShowDownload(true)}
                >
                  Download PDF's
                </Button>
              </Col> */}

          <Col md={3} className="d-flex justify-content-end">
            <Button
              disabled={checkboxArray.length === 0}
              className="primaryBtn"
              onClick={() => setDateNoticeModal(true)}
            >
               <Icon.Intersect className="me-2"/>Generate Merge Notice
            </Button>
          </Col>
        </Row>

        <Row md={12}>
          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Dates</Form.Label>{" "}
              <div className="date-flex">
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    placeholder="Start Date"
                    type="date"
                    value={startdate}
                    onChange={(e) =>
                      setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    placeholder="End Date"
                    type="date"
                    min={startdate}
                    value={enddate}
                    onChange={(e) =>
                      setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Col>
                <Col className="m-2 d-flex ">
                  {" "}
                  <Button
                    className="primaryBtn p-2 mt-1"
                    onClick={searchByDate}
                  >
                    <Icon.Search size={18} className="me-2" />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>

          <Col md={6} className="mt-3">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Block</Form.Label>{" "}
              <div className="d-flex align-items-start">
                <Col className="m-2 d-flex flex-column">
                  <Form.Label>Zone Name</Form.Label>
                  <Form.Select
                    value={zoneName}
                    onChange={(e) => {
                      setZoneName(e.target.value);
                      setBlockNo("");
                      e.target.value.length > 0 && setZoneErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Zone
                    </option>
                    {zoneNameList !== null &&
                    zoneNameList !== undefined &&
                    zoneNameList.length > 0 ? (
                      zoneNameList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.zonename}>
                            {itm?.zonename}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {zoneErr ? <p className="errMsg">Please select zone</p> : ""}
                </Col>

                <Col className="m-2 d-flex flex-column">
                  <Form.Label>Block</Form.Label>
                  <Form.Select
                    disabled={!zoneName}
                    value={blockNo}
                    onChange={(e) => {
                      setBlockNo(e.target.value);
                      e.target.value.length > 0 && setBlockErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Block
                    </option>
                    {blockNoList !== null &&
                    blockNoList !== undefined &&
                    blockNoList.length > 0 ? (
                      blockNoList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.blockNo}>
                            {itm?.blockNo}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {blockErr ? (
                    <p className="errMsg">Please select block</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Col className="m-2 d-flex align-items-end">
                  <Button
                    className="primaryBtn p-2 mt-4"
                    onClick={searchByBlockZone}
                  >
                    <Icon.Search size={18} className="me-2" />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-center mt-3">
          <Col md={6}>
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Officer</Form.Label>
              <div className="d-flex align-items-start">
                <Col md={8}>
                  <Form.Select
                    value={officer}
                    onChange={(e) => {
                      setOfficer(e.target.value);
                      e.target.value.length > 0 && setOfficerErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Officer
                    </option>
                    {officerList !== null &&
                    officerList !== undefined &&
                    officerList.length > 0 ? (
                      officerList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.user_id}>
                            {itm?.name + " - " + itm?.mobile}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {officerErr ? (
                    <p className="errMsg">Please select officer</p>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={4}>
                  <Button
                    className="primaryBtn mx-auto"
                    onClick={searchByOfficer}
                  >
                    <Icon.Search className="me-2" size={15} />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Notice</Form.Label>
              <div className="d-flex align-items-start">
                <Col md={8} className="date-flex">
                  <Form.Select
                    value={noticeNo}
                    onChange={(e) => {
                      setNoticeNos(e.target.value);
                      e.target.value.length > 0 && setNoticeErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Notice Number
                    </option>
                    {noticeDropDown !== null &&
                    noticeDropDown !== undefined &&
                    noticeDropDown.length > 0 ? (
                      noticeDropDown?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.value}>
                            {itm?.label}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {noticeErr ? (
                    <p className="errMsg">Please select Notice Type</p>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={4}>
                  <Button
                    className="primaryBtn mx-auto"
                    onClick={searchByNotice}
                  >
                    <Icon.Search className="me-2" size={15} />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col>
          {/* <Col md={6}>
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search By No of Days Passed
              </Form.Label>
              <div className="d-flex align-items-start">
                <Col md={8}>
                  <Form.Control
                    placeholder="Enter No of Days Passed"
                    value={noOfDays}
                    className="me-3"
                    onChange={(e) => {
                      if (
                        e.target.value.match(/^[0-9]+$/) &&
                        e.target.value !== "0"
                      ) {
                        setNoOfDays(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setNoOfDays(e.target.value);
                      }
                    }}
                  />
                  {daysError ? (
                    <p className="errMsg">Please Enter No of Days Passed</p>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={4}>
                  <Button
                    className="primaryBtn mx-auto"
                    onClick={searchByNoOfDays}
                  >
                    <Icon.Search className="me-2" size={15} />
                    Search
                  </Button>
                </Col>
              </div>
            </Card>
          </Col> */}
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          {
            <>
              <Form.Check
                label={"Select All"}
                onChange={(e) => changeSelectAll(e)}
              />
              <div
                className="table-wrapper mt-2"
                style={{ maxHeight: download ? "45vh" : "58vh" }}
              >
                <table data-aos="fade-up" data-aos-delay="200">
                  <thead>
                    <th>Sr. No.</th>
                    <th>Business Name</th>
                    <th>Owner Name</th>
                    <th>Owner Mobile</th>
                    <th>Owner Address</th>
                    <th>Rented Person Name</th>
                    <th>Rented Person Mobile</th>
                    {/* <th>Rented Person Address</th> */}
                    <th>Action</th>
                  </thead>
                  {loader ? (
                    <Skeleton rows={10} cols={9} />
                  ) : data?.data !== null &&
                    data?.data !== undefined &&
                    data?.data?.length > 0 ? (
                    data?.data?.map((itm, index) => {
                      return (
                        <tr
                          key={index}
                          // style={
                          //  itm.noticeSend && itm.noticeSend.length > 0
                          //     ? { backgroundColor: "#ccffcc" }
                          //     : {}
                          // }
                        >
                          <td>
                            {pageNo !== 1 ? (
                              <div className="d-flex">
                                {index + 1 + docPerPage * (pageNo - 1)}
                                <Form.Check
                                  style={{ marginLeft: 5 }}
                                  name="group1"
                                  type={"checkbox"}
                                  checked={
                                    checkboxArray.filter(
                                      (row) => row.surveyId === itm.surveyId
                                    ).length > 0
                                  }
                                  onChange={(e) => checkBoxChnage(e, itm)}
                                />
                              </div>
                            ) : (
                              <div className="d-flex">
                                {index + 1}
                                <Form.Check
                                  style={{ marginLeft: 5 }}
                                  name="group1"
                                  type={"checkbox"}
                                  checked={
                                    checkboxArray.filter(
                                      (row) => row.surveyId === itm.surveyId
                                    ).length > 0
                                  }
                                  onChange={(e) => checkBoxChnage(e, itm)}
                                />
                              </div>
                            )}
                          </td>
                          <td>{itm?.businessName ? itm?.businessName : "-"}</td>
                          <td>{itm?.ownerName ? itm?.ownerName : "-"}</td>
                          <td>{itm?.ownerMobile ? itm?.ownerMobile : "-"}</td>
                          <td style={{ maxWidth: "20vw" }}>
                            {itm?.finalBusinessAddress
                              ? itm?.finalBusinessAddress
                              : "-"}
                          </td>
                          <td>
                            {itm?.rentedPersonName
                              ? itm?.rentedPersonName
                              : "-"}
                          </td>
                          <td>
                            {itm?.rentedPersonMobile
                              ? itm?.rentedPersonMobile
                              : "-"}
                          </td>
                          {/* <td style={{ maxWidth: "20vw" }}>
                            {itm?.finalBusinessAddress
                              ? itm?.finalBusinessAddress
                              : "-"}
                          </td> */}
                          <td className="d-flex align-items-center">
                            {/* <Icon.Eye
                              className="ms-2 icon"
                              size={24}
                              onClick={() =>
                                navigate("/dashboard/officer-view-notice", {
                                  state: itm,
                                })
                              }
                            /> */}
                            <Button
                              className="primaryBtn ms-2"
                              disabled={
                                checkboxArray.filter(
                                  (row) => row.surveyId === itm.surveyId
                                ).length > 0
                              }
                              onClick={() => {
                                setSingleData(itm);
                                setSingleNoticeMoodal(true);
                              }}
                            >
                              Generate Single Notice
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="noDataFound">No Data Found</p>
                  )}
                </table>
              </div>

              <Row>
                <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                  <h6 className="text-center">
                    <Icon.BarChart className="me-2" />
                    Total - <strong>{data?.count}</strong>
                  </h6>
                </Col>
                <Col md={8} className="d-flex justify-content-end">
                  <Pagination
                    currentPage={pageNo}
                    totalPages={noOfPages}
                    onPageChange={handlePageChange}
                    onDocsPerPage={handleDocsPerPage}
                    docsPerPage={docPerPage}
                  />
                </Col>
              </Row>
            </>
          }
        </div>
      </Container>

      <Modal
        size="lg"
        show={dateNoticeModal}
        backdrop="static"
        keyboard={false}
        centered
        onHide={closeDateModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0"> <Icon.Intersect className="me-2"/>Generate Merge Notice</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex flex-wrap">
            <Col md={4} className="d-flex flex-column">
              <Form.Label>Notice Date</Form.Label>
              <Form.Control
                placeholder="Start Date"
                type="date"
                disabled={mergeArray.length > 0}
                // min={moment(new Date()).format("YYYY-MM-DD")}
                value={noticeDate}
                onChange={(e) =>
                  setNoticeDate(moment(e.target.value).format("YYYY-MM-DD"))
                }
              />
              {mergeError.noticeDate && (
                <p className="errMsg">{mergeError.noticeDate}</p>
              )}
            </Col>
            <Col md={4} className="d-flex flex-column">
              <Form.Label>Notice Number</Form.Label>
              <Form.Select
                disabled={mergeArray.length > 0} 
                onChange={(e)=>handleNoticeChange(e, 'multiple')}>

                <option value="" selected disabled>
                  Select Notice Number
                </option>

                { noticeDropDown.length > 0 ? (
                  noticeDropDown?.map((itm, index) => {
                    return (
                      <option key={index} value={JSON.stringify(itm.value)}>
                        {itm?.label}
                      </option>
                    );
                  })
                ) : (
                  <option value="">No Data Found</option>
                )}
              </Form.Select>
              {mergeError.selectedNotice && (
                <p className="errMsg">{mergeError.selectedNotice}</p>
              )}
            </Col>

            {responses.length > 0 && responses.map((noticeFormats, idx) =>{
                const label = responses.length > 1 ? getLabelForIndex(idx) : defaultLabel;
              return(
                <Col md={4} className="d-flex flex-column mb-3" key={idx}>
                <Form.Label>{label}</Form.Label>
                <Form.Select
                  value={selectedNoticeFormat[idx] || ""}
                  onChange={(e) => {
                    const updatedFormats = [...selectedNoticeFormat];
                    updatedFormats[idx] = e.target.value;
                    setSelectedNoticeFormat(updatedFormats);
                  }}
                >
                  <option value="" selected disabled>
                    Select Notice Type
                  </option>
                  {noticeFormats.map((itm, index) => (
                    <option key={index} value={itm.noticeFormatName}>
                      {itm.noticeFormatName}
                    </option>
                  ))}
                </Form.Select>
                {mergeError.selectedNoticeFormat && (
                <p className="errMsg">{mergeError.selectedNoticeFormat}</p>
              )}
              </Col>
              )
            }  
          )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {mergeLoader ? (
            <Spinner />
          ) : (
            // mergeArray.length > 0 ? (
            //   <Button className="primaryBtn" onClick={() => downloadMergePdf()}>
            //     Download Merge Pdf
            //   </Button>
            // ) :
            <Button className="primaryBtn" onClick={() => bulkInsert()}>
               <Icon.Intersect className="me-2"/>Generate
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={singleNoticeMoodal}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => closeSingleModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Generate Notice</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 style={{ wordWrap: "break-word" }}>
            Survey Id - {singleData?.surveyId}
          </h6>
          <Row className="d-flex flex-wrap">
            <Col md={4} className="d-flex flex-column">
              <Form.Label>Notice Date</Form.Label>
              <Form.Control
                placeholder="Start Date"
                type="date"
                // min={moment(new Date()).format("YYYY-MM-DD")}
                value={singleNoticeDate}
                onChange={(e) =>
                  setSingleNoticeDate(
                    moment(e.target.value).format("YYYY-MM-DD")
                  )
                }
              />
              {singleError.noticeDate && (
                <p className="errMsg">{singleError.noticeDate}</p>
              )}
            </Col>
        
            <Col md={4} className="d-flex flex-column">
             <Form.Label>Notice Number</Form.Label>
              <Form.Select
                onChange={(e)=>handleNoticeChange(e, 'single')}>
                <option value="" selected disabled>
                  Select Notice Number
                </option>
                { noticeDropDown.length > 0 ? (
                  noticeDropDown?.map((itm, index) => {
                    return (
                      <option key={index} value={JSON.stringify(itm.value)}>
                        {itm?.label}
                      </option>
                    );
                  })
                ) : (
                  <option value="">No Data Found</option>
                )}
              </Form.Select>
              {singleError.selectedNotice && (
                <p className="errMsg">{singleError.selectedNotice}</p>
              )}
            </Col>


            {responses.length > 0 && responses.map((noticeFormats, idx) =>{
                const label = responses.length > 1 ? getLabelForIndex(idx) : defaultLabel;
              return(
                <Col md={4} className="d-flex flex-column mb-3" key={idx}>
                <Form.Label>{label}</Form.Label>
                <Form.Select
                  value={selectedNoticeFormat[idx] || ""}
                  onChange={(e) => {
                    const updatedFormats = [...selectedNoticeFormat];
                    updatedFormats[idx] = e.target.value;
                    setSelectedNoticeFormat(updatedFormats);
                  }}
                >
                  <option value="" selected disabled>
                    Select Notice Type
                  </option>
                  {noticeFormats.map((itm, index) => (
                    <option key={index} value={itm.noticeFormatName}>
                      {itm.noticeFormatName}
                    </option>
                  ))}
                </Form.Select>
                {singleError[`selectedNoticeFormat_${idx}`] && (
                    <p className="errMsg">{singleError[`selectedNoticeFormat_${idx}`]}</p>
                )}
              </Col>
              )
            }  
        )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {singleLoader ? (
            <Spinner />
          ) : (
            <Button className="primaryBtn" onClick={() => getSingleNotice()}>
              Generate Single Notice
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GenerateNotice;
