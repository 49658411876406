import React, { useEffect, useState } from 'react'

const useGetZoneName = () => {
    const [zoneNameList, setZoneNameList] = useState([])

    useEffect(() =>{
        try{
            fetch(
                process.env.REACT_APP_BASE_URL + "/taxZone/getTaxZones",
                {
                  method: "GET",
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((res) => res.json())
              .then((response) => {
                setZoneNameList(response?.data)
                })
                .catch((Err) => {
                  console.log("Err while getting propertType", Err);
                })
        }catch(error){
            console.log(error)
        }  

    },[])

  return {zoneNameList}
}

export default useGetZoneName
