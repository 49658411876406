import React, { useEffect, useState } from 'react'
import { Container, Row, Button, Spinner, Col, Card, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Skeleton from '../Common/Skeleton';

const OfficerViewSurvey = () => {

    const userState = useSelector(state => state.surveylogin.userData?.data);
    
    const navigate = useNavigate();
    const location = useLocation();
    const [verifications, setVerifications] = useState('');
    const [verifyLoader, setVertifyLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [archLoader, setArchLoader] = useState(false);
    const [archives, setArchives] = useState('');
    // const [currentArchive, setCurrentArchive] = useState('');
    const [currentSurvey, setCurrentSurvey] = useState('');
    const [activeSurvey, setActiveSurvey] = useState('');
    const [activeId, setActiveId]= useState('');


    useEffect(()=>{
        getSurveyById();
    },[])
    
    const getSurveyById= async ()=>{
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/newSurvey/getSurveyBySurveyId', {
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({ surveyId : location?.state })
        }).then((res)=>res.json()).then((response)=>{
            setCurrentSurvey(response?.data);
            setActiveSurvey(response?.data);
            setLoader(false);
        }).catch((err)=>{
            console.error('Error while view currentSurvey', err);
            setLoader(false);        
        })
    }

    const openImageInNewTab = (imageUrl) => {
        window.open(imageUrl, '_blank');
      };


  return (
    <div className='ViewSurvey outletPadding mb-5'>
        <Container>
            <Button className='primaryBtn' onClick={()=>navigate(-1)}>
                <Icon.ArrowLeft className='me-2'/>Back
            </Button>

            <Row className='mb-2 mt-4'>
                <h4 className='mb-0 text-center'>
                <Icon.Clipboard2Data className='me-1'/> Survey Details</h4>
            </Row>

            {
                loader ? <center><Spinner variant='primary mt-3'/></center> :
                <Row className="outer-wrapper mx-auto mt-4">
                <div className="table-wrapper">
                        <table data-aos="fade-up" data-aos-delay="200">
                            <tbody>
                                <tr>
                                    <th><Icon.Code className='me-2'/>Property Code</th>
                                    <td>{activeSurvey?.propertyCode ? activeSurvey?.propertyCode :'-' }</td>
                                </tr>

                                <tr>
                                    <th> <Icon.Bullseye className='me-2'/>Zone</th>
                                    <td>{activeSurvey?.zonename+' - '+activeSurvey?.zoneno}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Bullseye className='me-2'/>Gat</th>
                                    <td>{activeSurvey?.gatname}</td>
                                </tr>
                                
                                <tr>
                                <th><Icon.Building className='me-2'/>Building Floors</th>
                                    <td>{activeSurvey?.buildingFloors}</td>
                                </tr>
                                
                                <tr>
                                <th><Icon.Clock className='me-2'/>Business Start Date</th>
                                    <td>{activeSurvey?.businessStartDate}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Building className='me-2'/>Property Type</th>
                                    <td>{activeSurvey?.propertyType}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Building className='me-2'/>Sub Property Type</th>
                                    <td>{activeSurvey?.subPropertyType}</td>
                                </tr>

                                { activeSurvey?.subPropertyType === "इतर" &&
                                    <tr>
                                        <th><Icon.Building className='me-2'/>Sub Property Type</th>
                                        <td>{activeSurvey?.subPropertyType}</td>
                                    </tr>
                                }

                                <tr>
                                    <th><Icon.Building className='me-2'/>Property Name</th>
                                    <td>{activeSurvey?.propertyName}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Property Address</th>
                                    <td>{activeSurvey?.propertyAddress}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Business Address</th>
                                    <td >{activeSurvey?.businessAddress}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.GeoAlt className='me-2'/>Final Business Address</th>
                                    <td >{activeSurvey?.finalBusinessAddress}</td>
                                </tr>
                                
                                <tr>
                                    <th > <Icon.Person className='me-2'/>Owner Name</th>
                                    <td>{activeSurvey?.ownerName}</td>
                                </tr>
                                
                                <tr>
                                <th><Icon.Telephone className='me-2'/>Owner Mobile</th>
                                    <td>{activeSurvey?.ownerMobile}</td>
                                </tr>
                                <tr>
                                    <th><Icon.Envelope className='me-2'/>Owner Email</th>
                                    <td>{activeSurvey?.ownerEmail ? activeSurvey?.ownerEmail  :'-' }</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.ArrowRight className='me-2'/>Property Area Foot</th>
                                    <td>{activeSurvey?.propertyAreaFoot ? activeSurvey?.propertyAreaFoot : '-'}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.ArrowRight className='me-2'/>Property Area Meter</th>
                                    <td>{activeSurvey?.propertyAreaMeter ? activeSurvey?.propertyAreaMeter :'-'}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.Building className='me-2'/>Property Floor</th>
                                    <td>{activeSurvey?.propertyFloor ? activeSurvey?.propertyFloor :'-'}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.Fire className='me-2'/>Fire Extinguisher Equipments</th>
                                    <td>{activeSurvey?.fireExtinguisherEquipments ? activeSurvey?.fireExtinguisherEquipments?.map((itm)=>itm).join(', ') :'-'}</td>
                                </tr>
                                
                                <tr>
                                    <th><Icon.Person className='me-2'/>Survey Person Name</th>
                                    <td>{activeSurvey?.surveyByName}</td>
                                </tr>

                                <tr>
                                    <th><Icon.Telephone className='me-2'/>Survey Person Mobile</th>
                                    <td>{activeSurvey?.surveyByMobile}</td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Outside Image</th>
                                    <td>{
                                    activeSurvey?.outsideImage?.length > 0 ? 
                                    activeSurvey?.outsideImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Outside"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Inside Image</th>
                                    <td>{
                                        activeSurvey?.insideImage?.length > 0 ? 
                                        activeSurvey?.insideImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Inside"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Satha Parvana Image</th>
                                    <td>{
                                        activeSurvey?.sathaParvanaImage?.length > 0 ? 
                                        activeSurvey?.sathaParvanaImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="Satha Parvana"
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Satha ShopAct License Image</th>
                                    <td>{
                                        activeSurvey?.sathaShopActLicenseImage?.length > 0 ? 
                                         activeSurvey?.sathaShopActLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Business Parvana Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.businessParvanaImage?.length > 0 ? 
                                         activeSurvey?.businessParvanaImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        <div>
                                         <Icon.CardImage className='me-2'/> Business ShopAct License Image
                                        </div>
                                    </th>
                                    <td colSpan={9}>{
                                        activeSurvey?.businessShopActLicenseImage?.length > 0 ? 
                                         activeSurvey?.businessShopActLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Food License Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.isFoodLicenseImage?.length > 0 ? 
                                         activeSurvey?.isFoodLicenseImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire NOC Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.fireNOCImage?.length > 0 ? 
                                         activeSurvey?.fireNOCImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt="" 
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Annual Image</th>
                                    <td>{
                                        activeSurvey?.fireAnnualImage?.length > 0 ? 
                                         activeSurvey?.fireAnnualImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Safety Certificate Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.fireSafetyCertificateImage?.length > 0 ? 
                                         activeSurvey?.fireSafetyCertificateImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Electric Meter Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.electricMeterImage?.length > 0 ? 
                                         activeSurvey?.electricMeterImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Fire Ext. Equipment Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.fireExtinguisherEquipmentImage?.length > 0 ? 
                                         activeSurvey?.fireExtinguisherEquipmentImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Hazardous Equipment Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.hazardousEquipmentImage?.length > 0 ? 
                                         activeSurvey?.hazardousEquipmentImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Attic Image</th>
                                    <td colSpan={9}>{
                                        activeSurvey?.atticImage?.length > 0 ? 
                                         activeSurvey?.atticImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Basement Image</th>
                                    <td>{
                                        activeSurvey?.basementImage?.length > 0 ? 
                                         activeSurvey?.basementImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                                <tr>
                                    <th><Icon.CardImage className='me-2'/>Residential Image</th>
                                    <td>{
                                        activeSurvey?.residentialImage?.length > 0 ? 
                                         activeSurvey?.residentialImage?.map((itm)=>{
                                            return(
                                             <img src={itm} width={80} height={100} alt=""
                                             onClick={()=>openImageInNewTab(itm)}/>
                                            )
                                         }) :'-'}
                                    </td>
                                </tr>

                               
                            </tbody>
                        </table>
              </div>
                </Row>
            }
           
        </Container>
    </div>
  )

}

export default OfficerViewSurvey