import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
  Table,
  Tab,
  Nav,
  Card,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../../Common/Pagination";
import Skeleton from "../../Common/Skeleton";
import useGetZoneName from "../../Common/ReuseAPI/useGetZoneName";
import useGetBlockNo from "../../Common/ReuseAPI/useGetBlockNo";

const TSOComplianceHistory = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state?.surveylogin?.userData);
  const [data, setdata] = useState();
  const [loader, setLoader] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState();


  const [blockNo, setBlockNo] = useState("");
  const [zoneName, setZoneName] = useState("");

  // filter error
  const [blockErr, setBlockErr] = useState(false);
  const [zoneErr, setZoneErr] = useState(false);

  // Reuse API
  const { blockNoList } = useGetBlockNo();
  const { zoneNameList } = useGetZoneName();

  const [businessUniqueNo, setBusinessUniqueNo] = useState("");
  const [businessUniqueErr, setBusinessUniqueErr] = useState(false);

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getComplianceHistory();
  }, [pageNo, docPerPage]);

  const getComplianceHistory = async () => {
    setLoader(true);

    let payload = {
      complianceById: "",
      surveyZoneKey: userState?.data?.fireZoneKey,
      newBlock: blockNo,
      blockNo: userState?.blockNo,
      businessUniqueNo: businessUniqueNo,
      documentsPerPage: docPerPage,
      page: pageNo,
    };
    await fetch(
      process.env.REACT_APP_BASE_URL + "/subOfficer/getAllCompliances",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setdata(response);
          setNoOfPages(response.noOfPages);
          setLoader(false);
        }
        else {
          setLoader(false)
        }
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };


  // Search by block and zone
  const searchByBlockZoneValidate = () => {
    let isValid = true;
    if (!blockNo) {
      isValid = false;
      setBlockErr(true);
    }
    return isValid;
  };

  const searchByBlockZone = async () => {
    if (searchByBlockZoneValidate()) {
      await getComplianceHistory();
    }
  };

  const searchByBusinessUniqueNo = async () => {
    if (businessUniqueNo) {
      await getComplianceHistory();
    } else {
      setBusinessUniqueErr(true);
    }
  };


  return (
    <div className="outletPadding">
      <Container>
        <Row className="justify-content-center">
          <Col md={4} className="mt-2 mb-4 mb-md-0">
            <Card className="searchCard">
              <Form.Label className="searchLabel">
                Search by Businesss Unique No
              </Form.Label>
              <Form.Control
                placeholder="Enter Businesss Unique No."
                value={businessUniqueNo}
                className="me-3"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setBusinessUniqueNo(e.target.value);
                    setBusinessUniqueErr(false);
                  } else {
                    setBusinessUniqueNo("");
                  }
                }}
              />
              {businessUniqueErr ? (
                <p className="errMsg">Please Enter Business Unique No.</p>
              ) : (
                ""
              )}
              <Button
                className="primaryBtn mx-auto mt-2"
                onClick={searchByBusinessUniqueNo}
              >
                <Icon.Search className="me-2" size={15} />
                Search
              </Button>
            </Card>
          </Col>

          <Col md={4} className="mt-2">
            <Card className="searchCard">
              <Form.Label className="searchLabel">Search by Block</Form.Label>{" "}
              <div className="d-flex align-items-start">
                <Col className="me-3 d-flex flex-column mb-3">
                  <Form.Label>Block</Form.Label>
                  <Form.Select
                    value={blockNo}
                    onChange={(e) => {
                      setBlockNo(e.target.value);
                      e.target.value.length > 0 && setBlockErr(false);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Block
                    </option>
                    {blockNoList !== null &&
                      blockNoList !== undefined &&
                      blockNoList.length > 0 ? (
                      blockNoList?.map((itm, index) => {
                        return (
                          <option key={index} value={itm?.blockNo}>
                            {itm?.blockNo}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Data Found</option>
                    )}
                  </Form.Select>
                  {blockErr ? (
                    <p className="errMsg">Please select block</p>
                  ) : (
                    ""
                  )}
                </Col>

                <Button
                  className="primaryBtn p-2 mt-4"
                  onClick={searchByBlockZone}
                >
                  <Icon.Search size={18} className="me-2" />
                  Search
                </Button>

              </div>
            </Card>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Business Unique No</th>
                <th>Business Name</th>
                <th>Business Address</th>
                <th>Owner Name</th>
                <th>Owner Mobile</th>
                <th>Rented Person Name</th>
                <th>Rented Person Mobile</th>
                <th>Survey By Name</th>
                <th>Survey By Mobile</th>
                <th>Actions</th>
              </thead>
              {loader ? (
                <Skeleton rows={8} cols={9} />
              ) : data !== null &&
                data !== undefined &&
                data?.data.length > 0 ? (
                data?.data.map((itm, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {pageNo !== 1 ? (
                          <div className="d-flex">
                            {index + 1 + docPerPage * (pageNo - 1)}
                          </div>
                        ) : (
                          <div className="d-flex">{index + 1}</div>
                        )}
                      </td>
                      <td>
                        {itm?.businessUniqueNo ? itm?.businessUniqueNo : "-"}
                      </td>
                      <td>
                        {itm?.businessName ? itm?.businessName : "-"}
                      </td>
                      <td style={{ width: "20vw" }}>
                        {itm?.finalBusinessAddress
                          ? itm?.finalBusinessAddress
                          : "-"}
                      </td>
                      <td>{itm?.ownerName ? itm?.ownerName : "-"}</td>
                      <td>{itm?.ownerMobile ? itm?.ownerMobile : "-"}</td>
                      <td>
                        {itm?.rentedPersonName ? itm?.rentedPersonName : "-"}
                      </td>
                      <td>
                        {itm?.rentedPersonMobile
                          ? itm?.rentedPersonMobile
                          : "-"}
                      </td>
                      <td>{itm?.surveyByName ? itm?.surveyByName : "-"}</td>
                      <td>{itm?.surveyByMobile ? itm?.surveyByMobile : "-"}</td>
                      <td>
                        {/* <Button
                          className="primaryBtn ms-2"
                          onClick={() => {
                            navigate("/dashboard/add-compliance", {
                              state: itm.surveyId,
                            })
                          }}
                        >
                          View Survey
                        </Button> */}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <p className="noDataFound">No Data Found</p>
              )}
            </table>
          </div>

          {!loader && (
            <Row>
              <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
                <h6 className="text-start mb-0">
                  <Icon.People className="me-2" />
                  Total - <strong>{data?.count}</strong>
                </h6>
              </Col>
              <Col md={8} className="d-flex justify-content-end">
                <Pagination
                  currentPage={pageNo}
                  totalPages={noOfPages}
                  onPageChange={handlePageChange}
                  onDocsPerPage={handleDocsPerPage}
                  docsPerPage={docPerPage}
                />
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default TSOComplianceHistory;
